import { styled } from '@glitz/react';
import { headingStyled } from './H1';
import { mediaMinMedium, fontFamilies } from 'Shared/Style';
import { media } from '@glitz/core';
export const h3Styled = headingStyled(Object.assign({ fontSize: 24, margin: { y: 5 }, lineHeight: 1.33 }, media(mediaMinMedium, { fontSize: 32, lineHeight: 1.45, margin: { y: 5 } })));
export default h3Styled(styled.H3);
export const h3Cms = headingStyled(styled.H3, {
    color: 'inherit',
    fontFamily: fontFamilies.batonTurbo,
    fontSize: 'inherit',
    margin: { y: 0 },
    lineHeight: 1.89,
});
