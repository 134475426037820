// This file has been generated by the Nitro build. Do not alter
// it manually as your changes will be overwritten. Run:
// nitro.cmd frontend:build-component-registry
// instead.
/* tslint:disable */
// prettier-ignore
import { setComponentRegistry, setThemeContextCreatorRegistry } from '@avensia/nitro5-scope';
// prettier-ignore
export { default as componentNames } from './component-names';
// prettier-ignore
const componentRegistry = {
    'Avensia.Common.Features.AbTesting.AbTestSettingsBlock': (theme) => import('../AbTesting/AbTestSettingsBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.ForgotPassword.ForgotPasswordBlock': (theme) => import('../Account/ForgotPassword/ForgotPasswordBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.Login.LoginBlock': (theme) => import('../Account/Login/LoginBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.Logout.LogoutPageViewModel': (theme) => import('../Account/MyPages/Logout/LogoutPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyDetails.MyDetailsPageViewModel': (theme) => import('../Account/MyPages/MyDetails/MyDetailsPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyOrderDetails.MyOrderDetailsPageViewModel': (theme) => import('../Account/MyPages/MyOrderDetails/MyOrderDetailsPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyOrders.MyOrdersPageViewModel': (theme) => import('../Account/MyPages/MyOrders/MyOrdersPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyOverview.BenefitBlock': (theme) => import('../Account/MyPages/MyOverview/BenefitBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyOverview.BonusCheckBlock': (theme) => import('../Account/MyPages/MyOverview/BonusCheckBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyOverview.MyOverviewPageViewModel': (theme) => import('../Account/MyPages/MyOverview/MyOverviewPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyWishlist.MyWishlistPageViewModel': (theme) => import('../Account/MyPages/MyWishlist/MyWishlistPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.Register.RegisterBlock': (theme) => import('../Account/Register/RegisterBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.AppShell.Partials.AppShellViewModel': (theme) => import('../AppShell/Partials/AppShell').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Blog.BlogArchive.BlogArchivePageViewModel': (theme) => import('../Blog/BlogArchive/BlogArchivePage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Blog.BlogCategory.BlogCategoryPageViewModel': (theme) => import('../Blog/BlogCategory/BlogCategoryPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Blog.BlogPost.BlogPostViewModel': (theme) => import('../Blog/BlogPost/BlogPost').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Blog.LatestBlogPosts.LatestBlogPostsBlockViewModel': (theme) => import('../Blog/LatestBlogPosts/LatestBlogPostsBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Brand.BrandListViewModel': (theme) => import('../Brand/BrandListPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Brand.BrandViewModel': (theme) => import('../Brand/BrandPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Category.CategoryViewModel': (theme) => import('../Category/CategoryPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Checkout.CheckoutPage.CheckoutPageViewModel': (theme) => import('../Checkout/CheckoutPage/CheckoutPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.EpiFoundation.Shipping.CheckoutModels.ShippingMethod': (theme) => import('../Checkout/CheckoutPage/ShippingMethod').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Checkout.CheckoutUpsell.CheckoutUpsellBlockViewModel': (theme) => import('../Checkout/CheckoutUpsell/PromotionBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Payments.KlarnaCheckoutV3.KlarnaCheckoutV3PaymentMethodViewModel': (theme) => import('../Checkout/PaymentMethods/KlarnaCheckoutV3/KlarnaCheckoutV3PaymentMethod').then(m => ({ component: m.default, theme: null })),
    'Avensia.Payments.PayU.PayUPaymentMethodViewModel': (theme) => import('../Checkout/PaymentMethods/PayU/PayUPaymentMethodViewModel').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Content.ContentPageViewModel': (theme) => import('../Content/ContentPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.CustomerReturn.CustomerReturnFormBlock': (theme) => import('../CustomerReturn/CustomerReturnFormBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Error.Pages.PageNotFoundViewModel': (theme) => import('../Error/Pages/PageNotFound').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.GiftCards.GiftCardBalance.GiftCardBalanceBlock': (theme) => import('../GiftCards/GiftCardBalance/GiftCardBalanceBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.GiftCards.GiftCardsBlock.GiftCardsBlockViewModel': (theme) => import('../GiftCards/GiftCardsBlock/GiftCardsBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.OrderConfirmation.OrderConfirmationPageViewModel': (theme) => import('../OrderConfirmation/OrderConfirmationPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Product.ProductViewModel': (theme) => import('../Product/ProductPageSelector').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.ProductListing.ProductListingPageViewModel': (theme) => import('../ProductListing/ProductListingPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.ResetPassword.ResetPasswordPageViewModel': (theme) => import('../ResetPassword/ResetPasswordPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Search.SearchPageViewModel': (theme) => import('../Search/SearchPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Author.AuthorBlock': (theme) => import('./Blocks/Author/AuthorBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Bambuser.BambuserBlockViewModel': (theme) => import('./Blocks/Bambuser/BambuserBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Categories.CategoriesBlockViewModel': (theme) => import('./Blocks/Categories/CategoriesBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Editor.EditorBlockViewModel': (theme) => import('./Blocks/Editor/EditorBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.EsalesProducts.ProductListBlockViewModel': (theme) => import('./Blocks/EsalesProducts/ProductsBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.FlowBox.FlowBoxBlock': (theme) => import('./Blocks/FlowBox/FlowBoxBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Hero.HeroBlockViewModel': (theme) => import('./Blocks/Hero/HeroBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.HeroCampaign.HeroCampaignBlockViewModel': (theme) => import('./Blocks/HeroCampaign').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.IFrame.IFrameBlockViewModel': (theme) => import('./Blocks/IFrame/IframeBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Image.ImageBlockViewModel': (theme) => import('./Blocks/Image/ImageBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.ImageGroup.ImageGroupBlockViewModel': (theme) => import('./Blocks/ImageGroup/ImageGroupBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Inspiration.InspirationBlockViewModel': (theme) => import('./Blocks/Inspiration/InspirationBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Instagram.InstagramBlockViewModel': (theme) => import('./Blocks/Instagram/InstagramBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.JournalProductImage.JournalProductImageListBlockViewModel': (theme) => import('./Blocks/JournalProductImage/JournalProductImageBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Link.LinkBlockViewModel': (theme) => import('./Blocks/Link/LinkBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Account.MyPages.MyOverview.MemberBenefitsBlock': (theme) => import('./Blocks/MemberBenefitsBlock/MemberBenefitsBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.MemberBenefitsRowBlock.MemberBenefitsRowBlockViewModel': (theme) => import('./Blocks/MemberBenefitsRowBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.ProductWithinText.ProductWithinTextBlockViewModel': (theme) => import('./Blocks/ProductWithinText/ProductWithinTextBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.RichTopic.RichTopicBlockViewModel': (theme) => import('./Blocks/RichTopic/RichTopicBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.RichTopicGroup.RichTopicGroupBlock': (theme) => import('./Blocks/RichTopicGroup/RichTopicGroupBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.RichTopicProduct.RichTopicProductBlockViewModel': (theme) => import('./Blocks/RichTopicProduct/RichTopicProductBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.RichTopicSquare.RichTopicSquareBlockViewModel': (theme) => import('./Blocks/RichTopicSquare').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.RichTopicSquareGroup.RichTopicSquareGroupBlock': (theme) => import('./Blocks/RichTopicSquareGroup').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.RowLayout.RowLayoutBlockViewModel': (theme) => import('./Blocks/RowLayout/RowLayoutBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.SeoBlock.SeoBlock': (theme) => import('./Blocks/SeoBlock/SeoBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.SimpleTopic.SimpleTopicBlockViewModel': (theme) => import('./Blocks/SimpleTopic/SimpleTopicBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.SimpleTopicGroup.SimpleTopicGroupBlock': (theme) => import('./Blocks/SimpleTopicGroup/SimpleTopicGroupBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.SquareImageBlock.SquareImageBlockViewModel': (theme) => import('./Blocks/SquareImageBlock/squareImageBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.SquareImageBlock.SquareImageGroupBlock': (theme) => import('./Blocks/SquareImageBlock/squareImageGroupBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.StaticProductListBlock.ProductListBlockViewModel': (theme) => import('./Blocks/StaticProductListBlock/ProductsBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Blocks.Video.VideoBlock': (theme) => import('./Blocks/Video/VideoBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.ContentTypes.Media.ImageDataModel': (theme) => import('./Partials/ImageDataModel').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.Partials.PreviewViewModel': (theme) => import('./Partials/Preview').then(m => ({ component: m.default, theme: null })),
    'EPiServer.Core.XhtmlString': (theme) => import('./Partials/PropertyTypes/Html').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Shared.SiteBanner.SiteBannerBlockViewModel': (theme) => import('./SiteBanner/SiteBannerBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.SiteLayout.CampaignSticker.StickerBlock': (theme) => import('../SiteLayout/CampaignSticker/StickerBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.SiteLayout.Newsletter.NewsletterBlock': (theme) => import('../SiteLayout/Newsletter/NewsletterBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.SiteLayout.Usp.UspBlock': (theme) => import('../SiteLayout/Usp/UspBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.SiteLayout.Usp.UspProductBlock': (theme) => import('../SiteLayout/Usp/UspProductBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Start.StartPageViewModel': (theme) => import('../Start/StartPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Stores.NearestStore.NearestStoreBlockViewModel': (theme) => import('../Stores/NearestStore/NearestStoreBlock').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Stores.StoreListPageViewModel': (theme) => import('../Stores/StoreListPage').then(m => ({ component: m.default, theme: null })),
    'Avensia.Common.Features.Stores.StorePageViewModel': (theme) => import('../Stores/StorePage').then(m => ({ component: m.default, theme: null })),
    'EPiServer.Core.ContentArea': (theme) => import('@avensia/nitro5-scope-episerver/dist/PropertyTypes/ContentArea').then(m => ({ component: m.default, theme: null })),
    'EPiServer.SpecializedProperties.LinkItemCollection': (theme) => import('@avensia/nitro5-scope-episerver/dist/PropertyTypes/LinkItemCollection').then(m => ({ component: m.default, theme: null })),
};
// prettier-ignore
setComponentRegistry(componentRegistry);
// prettier-ignore
export default componentRegistry;
// prettier-ignore
const themeContextCreatorRegistry = {};
// prettier-ignore
setThemeContextCreatorRegistry(themeContextCreatorRegistry);
