import React from 'react';
import { applyClassName, styled } from '@glitz/react';
import { ContentArea } from '@avensia/nitro5-scope-episerver';
import { useSelector } from 'Shared/State';
import currentPageIsCategory from 'Category/current-page-is-category';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
import currentPageIsProduct from 'Product/current-page-is-product';
function SiteBanner(props) {
    const currentPage = useSelector(state => state.currentPage);
    const isProductPage = currentPageIsProduct(currentPage);
    const isCheckoutPage = currentPageIsCheckout(currentPage);
    const isCategoryPage = currentPageIsCategory(currentPage);
    const siteBanner = useSelector(state => state.appShellData.siteBanner);
    const checkoutBanner = useSelector(state => state.appShellData.checkoutBanner);
    const categoryProductBanner = useSelector(state => state.appShellData.categoryProductBanner);
    const checkoutBannerData = isCheckoutPage && checkoutBanner;
    const pdpOrPdlBannerData = (isProductPage || isCategoryPage) && categoryProductBanner;
    const siteBannerData = !isCheckoutPage && !isProductPage && !isCategoryPage && siteBanner;
    const bannerdata = checkoutBannerData || pdpOrPdlBannerData || siteBannerData;
    return bannerdata && React.createElement(ContentArea, Object.assign({}, bannerdata, props));
}
export default React.memo(styled(applyClassName(SiteBanner)));
