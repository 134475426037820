import { styled } from '@glitz/react';
const paragraphStyled = styled({
    margin: { top: 0, bottom: '2.1em' },
});
export default paragraphStyled(styled.P, {
    color: theme => theme.mainTextColor,
});
export const cmsParagraph = paragraphStyled(styled.P, {
    color: 'inherit',
});
