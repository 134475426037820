import React, { useState } from 'react';
import { styled } from '@glitz/react';
import { translate, currentUrl } from '@avensia/nitro5-scope';
import { useDispatch, useSelector } from 'Shared/State';
import { useDynamicData } from 'Shared/use-dynamic-data';
import { getCurrentUser } from 'Shared/dynamic-data';
import { openAccountPanel } from 'Account/action-creators';
import { toggleCountrySelector } from 'SiteLayout/CountrySelector/action-creators';
import * as style from 'Shared/Style';
import { PlainButton } from 'Shared/Button';
import { media } from '@glitz/core';
import Accordion from 'Shared/Accordion';
import Level from './Level';
import Item from './Item';
import { trackMainMenuClick } from 'TrackingInformation';
export default React.memo(function Drawer(props) {
    var _a, _b;
    const appShellData = useSelector(state => state.appShellData);
    const dispatch = useDispatch();
    const currentUser = useDynamicData(getCurrentUser(), true);
    const [selectedTabIndex, setTabIndex] = useState();
    function loginClick() {
        dispatch(openAccountPanel());
    }
    function changeLanguageClick() {
        dispatch(toggleCountrySelector());
    }
    const isLoggedIn = currentUser && currentUser.isLoggedIn;
    const url = currentUrl();
    return (React.createElement(Base, null, (_a = props.mainMenu.mainMenuItems) === null || _a === void 0 ? void 0 :
        _a.map((item, i) => {
            var _a;
            return ((_a = item.items) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (React.createElement(StyledAccordion, { key: i, header: React.createElement(MenuTitle, null, item.name), onToggle: () => () => {
                    setTabIndex(i);
                    trackMainMenuClick(item.url, `${url === null || url === void 0 ? void 0 : url.origin}${item.url}`);
                }, isOpen: selectedTabIndex === i, ariaLabel: item.name },
                React.createElement(Level, { onClose: props.onClose, data: item }))) : (React.createElement(Li, { onClick: props.onClose, key: item.url },
                React.createElement(Item, { item: item })));
        }),
        React.createElement(ExtraLinks, null,
            isLoggedIn ? (React.createElement(NavItem, { onClick: props.onClose, to: appShellData.siteSettings.myOverviewPage }, translate('/MainMenu/MyPages'))) : (React.createElement(NavItem, { onClick: loginClick }, translate('/MainMenu/LoginSignup'))), (_b = appShellData.mobileNavigationLinks) === null || _b === void 0 ? void 0 :
            _b.links.map(link => (React.createElement(NavItem, { onClick: props.onClose, key: link.href, to: link.href }, link.text))),
            React.createElement(NavItem, { onClick: changeLanguageClick }, translate('/MainMenu/ChangeLanguage')))));
});
const Base = styled.div({
    textAlign: 'start',
    overflowY: 'scroll',
});
const ExtraLinks = styled.nav(Object.assign({ margin: { top: style.gigantic, x: 25 }, paddingBottom: 75 }, media(style.mediaMinMicro, {
    margin: {
        top: style.gigantic,
        x: 25,
    },
})));
const NavItem = styled(PlainButton, {
    display: 'block',
    marginBottom: 30,
    fontSize: 11,
    lineHeight: 1.55,
    letterSpacing: 1.1,
    textTransform: 'uppercase',
});
const StyledAccordion = styled(Accordion, {
    margin: { x: 25, y: 25 },
});
const MenuTitle = styled.span({
    font: {
        family: style.fontFamilies.ptSerif,
        size: 26,
        weight: 'normal',
    },
});
const Li = styled.li({
    display: 'flex',
    font: {
        family: style.fontFamilies.ptSerif,
        size: 26,
    },
    margin: { x: 25, top: 20 },
});
