import { __awaiter } from "tslib";
import React, { useRef, useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import { URLX, loadPage, pushState, translate, currentUrl, equalsUrl, replaceState, ESC_KEY, goHistory, } from '@avensia/nitro5-scope';
import connect from 'Shared/connect';
import Suggestions from './Suggestions';
import * as searchHistory from '../search-history';
import { loadQuickSearch, hideQuickSearch, searchQuery, SEARCH_QUERY_NAME, showQuickSearch, updateLastVisitedPage, } from '../action-creators';
import { searchPageUrl } from 'Shared/known-urls';
import currentPageIsSearch from 'Search/current-page-is-search';
import { useSelector } from 'Shared/State';
import { resetInputStyled } from 'Shared/Fields/Text';
import { white, fontFamilies, mediaMinMedium, ZIndex } from 'Shared/Style';
import * as style from 'Shared/Style';
import { WhiteTheme } from 'Shared/Theme';
import HeightTransition from 'Shared/HeightTransition';
import { media } from '@glitz/core';
import { useHeaderHeight, CloseButton } from 'SiteLayout/Header';
import { useDesktop, useCompact } from 'Shared/Viewport';
import currentPageHasHeaderBorder from 'SiteLayout/Header/current-page-has-header-border';
import { useOnClickOutside } from 'Shared/use-click-outside';
const DEBOUNCE_TIME = 500;
function searchUrl(query, isPreview) {
    const url = new URLX(searchPageUrl());
    url.searchParams.set(SEARCH_QUERY_NAME, query);
    if (isPreview) {
        url.hiddenParams.set('preview', String(true));
    }
    return searchQuery(url);
}
const QuickSearch = React.memo((props) => {
    const { quickSearch, hideQuickSearch: dispatchHideQuickSearch } = props;
    const quickSearchIsOpen = quickSearch.open;
    const previewLoadRef = useRef();
    const previewTimeoutRef = useRef();
    const searchTimeoutRef = useRef();
    const inputRef = useRef();
    const prevIsOpen = useRef(quickSearchIsOpen);
    const elementRef = useRef();
    const prevScrollPos = useRef(__BROWSER__ ? window.pageYOffset : 0);
    const prevQuickSearchOpen = useRef(quickSearchIsOpen);
    const [isScrolled, setIsScrolled] = useState(__BROWSER__ ? window.pageYOffset > 0 : false);
    const isCompact = useCompact();
    const isDesktop = useDesktop();
    const headerHeight = useHeaderHeight();
    const currentPage = useSelector(state => state.currentPage);
    const pageHasHeaderBorder = currentPageHasHeaderBorder(currentPage);
    useOnClickOutside(elementRef, () => {
        if (quickSearchIsOpen && !props.isSearchPage) {
            dispatchHideQuickSearch();
        }
    });
    const springConfig = { tension: props.isSearchPage ? 0 : 300, friction: 33, clamp: true };
    const isVisible = quickSearchIsOpen || props.isSearchPage;
    function applySuggestion(phrase) {
        search(phrase);
        props.loadQuickSearch(phrase);
    }
    function clearTimeouts() {
        clearTimeout(previewTimeoutRef.current);
        clearTimeout(searchTimeoutRef.current);
    }
    function debounceSearch(query) {
        clearTimeouts();
        previewTimeoutRef.current = setTimeout(() => {
            const load = (previewLoadRef.current = props.loadPage(searchUrl(query.trim(), true), searchPage => { var _a; return load === previewLoadRef.current && (!currentPageIsSearch(searchPage) || ((_a = searchPage.products) === null || _a === void 0 ? void 0 : _a.length) > 0); }));
        }, DEBOUNCE_TIME);
        searchTimeoutRef.current = setTimeout(() => {
            search(query);
        }, DEBOUNCE_TIME * 4);
    }
    function reset() {
        const browserUrl = currentUrl();
        const stateUrl = new URLX(props.url);
        if (!equalsUrl(browserUrl, stateUrl)) {
            replaceState(searchQuery(browserUrl));
        }
    }
    function hasAnySearchHit(page) {
        var _a;
        const searchPage = currentPageIsSearch(page) && page;
        return !searchPage || ((_a = searchPage.products) === null || _a === void 0 ? void 0 : _a.length) !== 0;
    }
    function search(query) {
        return __awaiter(this, void 0, void 0, function* () {
            clearTimeouts();
            if (query.length > 1) {
                const newUrl = searchUrl(query.trim());
                if (!props.isSearchPage) {
                    props.updateLastVisitedPage(window.history.state.stateIndex);
                }
                pushState(newUrl).then((pages) => {
                    if (pages.some(p => hasAnySearchHit(p))) {
                        const queryLowerCase = query === null || query === void 0 ? void 0 : query.toLowerCase();
                        searchHistory.add(queryLowerCase);
                    }
                });
            }
        });
    }
    function onSubmit(e) {
        var _a;
        e.preventDefault();
        search(quickSearch.searchText);
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    }
    function onChange(e) {
        const value = e.currentTarget.value;
        // IE11 and below fires an `input` on focus even if the user didn't type anything
        if (value === quickSearch.searchText) {
            return;
        }
        props.loadQuickSearch(value);
        if (!quickSearchIsOpen) {
            props.showQuickSearch();
        }
        if (value.length > 1) {
            debounceSearch(value);
        }
        else {
            clearTimeouts();
            reset();
        }
    }
    function onFocus(e) {
        const value = e.currentTarget.value;
        e.currentTarget.selectionStart = value.length;
        props.loadQuickSearch(value);
    }
    function onKeyPress(e) {
        if (e.keyCode === ESC_KEY) {
            if (quickSearch.searchText) {
                props.loadQuickSearch('');
            }
            dispatchHideQuickSearch();
            clearTimeouts();
            reset();
        }
    }
    function closeSearch() {
        (quickSearch.lastVisitedPageIndex !== null
            ? new Promise(() => {
                goHistory(quickSearch.lastVisitedPageIndex - window.history.state.stateIndex);
            })
            : pushState('/')).then(() => {
            dispatchHideQuickSearch();
        });
    }
    useEffect(() => {
        if (quickSearchIsOpen && !prevIsOpen.current) {
            inputRef.current.focus();
        }
        prevIsOpen.current = quickSearchIsOpen;
    }, [quickSearchIsOpen]);
    useEffect(() => {
        const checkScrollDetected = () => {
            const currentPos = window.pageYOffset;
            const scrolledToTop = currentPos === 0;
            if (isScrolled && currentPos !== prevScrollPos.current && prevQuickSearchOpen.current) {
                requestAnimationFrame(() => {
                    quickSearchIsOpen && dispatchHideQuickSearch();
                });
            }
            if (scrolledToTop) {
                isScrolled && setIsScrolled(false);
            }
            else if (!isScrolled && window.pageYOffset > 0) {
                setIsScrolled(true);
                quickSearchIsOpen && dispatchHideQuickSearch();
            }
            prevQuickSearchOpen.current = quickSearchIsOpen;
            prevScrollPos.current = window.pageYOffset;
        };
        window.addEventListener('scroll', checkScrollDetected);
        return () => {
            window.removeEventListener('scroll', checkScrollDetected);
        };
    }, [dispatchHideQuickSearch, isScrolled, quickSearchIsOpen]);
    const suggestionsWithDuplicates = quickSearch.searchText.length > 0 ? quickSearch.related : quickSearch.history.concat(quickSearch.popular);
    const suggestions = [...new Set(suggestionsWithDuplicates.map(x => x.text.trim()))];
    const closeButton = props.isSearchPage ? (React.createElement(CloseButton, { onClick: closeSearch, css: {
            top: window.pageYOffset > 0 ? headerHeight : headerHeight + 30,
            position: isScrolled && quickSearchIsOpen ? 'fixed' : 'absolute',
            zIndex: ZIndex.Header,
        } })) : (React.createElement(CloseButton, { onClick: () => dispatchHideQuickSearch(), css: { top: window.pageYOffset > 0 ? headerHeight - 20 : headerHeight + 30, position: 'fixed' } }));
    return (React.createElement(WhiteTheme, null,
        React.createElement(Base, { action: searchPageUrl(), onSubmit: onSubmit, css: [
                quickSearchIsOpen && Object.assign(Object.assign({}, (isCompact && !props.isSearchPage && { bottom: 0 })), (!pageHasHeaderBorder && {
                    border: {
                        top: {
                            width: 1,
                        },
                    },
                })),
                {
                    top: window.pageYOffset > 0 ? 40 : headerHeight,
                    position: isScrolled && quickSearchIsOpen ? 'fixed' : props.isSearchPage ? 'absolute' : 'fixed',
                },
            ], ref: elementRef },
            isDesktop && (React.createElement(styled.Div, { css: Object.assign(Object.assign({}, (isVisible && !props.isSearchPage && shadowStyle)), { height: '100%', width: '100%' }) })),
            React.createElement(HeightTransition, { springConfig: springConfig, isOpen: isVisible, updateOnChildMutation: true, css: __$hoisted_o0 },
                React.createElement(VisibleContainer, null,
                    React.createElement(Input, { type: "search", name: "q", autoComplete: "off", value: quickSearch.searchText, placeholder: translate('/QuickSearch/Placeholder'), onChange: onChange, onFocus: onFocus, onKeyDown: onKeyPress, ref: inputRef }),
                    React.createElement(PositionedSuggestions, { open: isVisible, items: suggestions, applySuggestion: applySuggestion })))),
        isVisible && closeButton));
});
export default styled(connect((state) => {
    const searchPage = currentPageIsSearch(state.currentPage) && state.currentPage;
    return {
        hasProducts: searchPage ? searchPage.totalProductCount > 0 : false,
        quickSearch: state.quickSearch,
        url: state.currentPage.url,
        isSearchPage: Boolean(searchPage),
    };
}, (dispatch) => ({
    loadPage(url, shouldUseResponse) {
        return dispatch(loadPage({ url, options: { hideSpinner: true }, shouldUseResponse }));
    },
    loadQuickSearch(query) {
        return dispatch(loadQuickSearch(query));
    },
    hideQuickSearch() {
        dispatch(hideQuickSearch());
    },
    showQuickSearch() {
        dispatch(showQuickSearch());
    },
    updateLastVisitedPage(stateIndex) {
        dispatch(updateLastVisitedPage(stateIndex));
    },
}))(QuickSearch));
const Base = styled.form(Object.assign({ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', width: '100%', backgroundColor: white, border: {
        top: {
            color: style.textOffWhite,
            width: 0,
            style: 'solid',
        },
    }, zIndex: style.ZIndex.Header }, style.transition({ property: 'border-color', duration: 500 })));
const PositionedSuggestions = styled(Suggestions, {
    width: '100%',
});
const shadowStyle = {
    ':after': {
        content: '""',
        bottom: 0,
        left: 0,
        right: 0,
        height: 50,
        position: 'absolute',
        boxShadow: '0 50px 50px rgba(0, 0, 0, 0.05)',
    },
};
const VisibleContainer = styled.div(Object.assign({ maxWidth: '100vw', height: 'auto', padding: {
        top: 20,
        bottom: 20,
        x: 30,
    } }, media(mediaMinMedium, {
    padding: {
        top: 20,
        bottom: 30,
        x: 30,
    },
})));
const Input = resetInputStyled(styled.Input, Object.assign({ width: 'calc(100% - 20px)', font: {
        family: fontFamilies.ptSerif,
        size: 26,
    }, lineHeight: 1.33, border: {
        xy: {
            style: 'none',
        },
    }, '::placeholder': {
        color: style.textGray,
    } }, media(mediaMinMedium, {
    fontSize: 30,
    ':hover': {
        cursor: 'text',
    },
})));
const __$hoisted_o0 = { width: '100%' };
