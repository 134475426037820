import React from 'react';
import { styled } from '@glitz/react';
import Image, { Ratio } from 'Shared/Image';
import Link from 'Shared/Link';
import { resetLinkDecorator } from 'Shared/Generic/A';
import { RoundButton } from 'Shared/Button';
import { Plus } from 'Shared/Icon/indiska';
import Price from 'Pricing/Price';
import * as style from 'Shared/Style';
import { truncate } from 'Shared/Style';
import { listNames } from 'TrackingInformation';
import { useSnackbar } from 'SiteLayout/Tray/Snackbar';
import { CheckoutConsumer } from 'Checkout/Context';
import { errorHandleCartUpdate } from 'Shared/cart-update-handling';
import { Behavior, createFeedbackStandaloneButton } from 'Shared/Button/Feedback';
import { testIdProps } from '../../test-automation';
const UpsellItem = (props) => {
    const { product, onClose } = props;
    const pushSnackbar = useSnackbar();
    return (React.createElement(CheckoutConsumer, null, ({ actions }) => {
        return (React.createElement(Base, null,
            React.createElement(Media, Object.assign({ to: product.url, onClick: onClose, linkIdentifier: product.linkIdentifier }, testIdProps.ProductLink),
                React.createElement(Image, { alt: product.displayName, src: product.images[0], ratio: Ratio.ProductImage, sizes: "81px" })),
            React.createElement(Body, null,
                React.createElement(Title, Object.assign({ to: product.url, onClick: onClose, linkIdentifier: product.linkIdentifier }, testIdProps.ProductLink), product.displayName),
                React.createElement(Description, null, product.shortDescription),
                product.price && (React.createElement(StyledPrice, Object.assign({}, product.price, { hasRedPrice: product.hasRedPrice, showOriginal: true, horizontal: true })))),
            product.namesOfVariations.length > 1 ? (React.createElement(GoToProductButton, Object.assign({ to: product.url, onClick: onClose, linkIdentifier: product.linkIdentifier }, testIdProps.ProductLink),
                React.createElement(Plus, { css: __$hoisted_o0 }))) : (React.createElement(AddToCartButton, Object.assign({ behavior: Behavior.KeepEnabled, onClick: () => actions
                    .addToCart(product.variationCode, 1, product.linkIdentifier, listNames.recommendations)
                    .then(() => {
                    Promise.resolve();
                })
                    .catch(e => errorHandleCartUpdate(e, pushSnackbar)) }, testIdProps.AddToCartButton),
                React.createElement(Plus, { css: __$hoisted_o1 })))));
    }));
};
const Base = styled.li({
    position: 'relative',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    padding: { bottom: ({ isCompact }) => (isCompact ? 20 : 30) },
    ':after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        border: {
            bottom: {
                color: theme => theme.lineColor,
                style: 'solid',
                width: 1,
            },
        },
    },
    ':last-child': {
        ':after': {
            border: { bottom: { style: 'none' } },
        },
    },
});
const Media = styled(Link, {
    flex: {
        grow: 0,
        shrink: 0,
        basis: '24%',
    },
    maxWidth: 81,
    marginInlineEnd: ({ isCompact }) => (isCompact ? 20 : 30),
});
const Body = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    minWidth: 0,
});
const Description = styled.div(Object.assign({ marginInlineEnd: ({ isCompact }) => (isCompact ? 20 : 30), textAlign: 'start', fontSize: style.zeta, color: style.textGray }, truncate()));
const StyledPrice = styled(Price, {
    fontSize: ({ isCompact }) => (isCompact ? style.epsilon : style.delta),
});
const Title = resetLinkDecorator(Link, {
    textAlign: 'start',
    fontSize: ({ isCompact }) => (isCompact ? style.epsilon : style.delta),
    display: 'block',
    margin: { bottom: 5 },
    lineHeight: 1.5,
});
const ButtonStyle = {
    alignItems: 'center',
    height: 60,
    width: 60,
    minWidth: 60,
};
const GoToProductButton = styled(RoundButton, Object.assign({}, ButtonStyle));
const AddToCartButton = createFeedbackStandaloneButton(styled(RoundButton, Object.assign({}, ButtonStyle)));
export default UpsellItem;
const __$hoisted_o0 = { height: 24, width: 24 };
const __$hoisted_o1 = { height: 24, width: 24 };
