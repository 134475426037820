import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import { useCompact, getScrollbarWidth } from 'Shared/Viewport';
import { mediaMinMedium, transition } from 'Shared/Style';
import { SquareButton, Theme } from 'Shared/Button';
import { Cross } from 'Shared/Icon/indiska';
import { H3 } from 'Shared/Generic';
export const Header = styled((_a) => {
    var { children, onClose, isScrolled, title, hideBackButton } = _a, restProps = __rest(_a, ["children", "onClose", "isScrolled", "title", "hideBackButton"]);
    const isCompact = useCompact();
    const scrollbarWidth = getScrollbarWidth();
    const headingFontSize = isCompact ? 26 : 30;
    const scrolledFontSize = isCompact ? 18 : 22;
    const titleScale = isScrolled ? scrolledFontSize / headingFontSize : 1;
    const titleTranslateY = isScrolled ? (isCompact ? 10 : 10) : 0;
    const headerTranslateY = isScrolled ? (isCompact ? -38 : -38) : 0;
    const styles = isScrolled
        ? {
            transform: `translateY(${headerTranslateY}px)`,
            borderBottomColor: theme => theme.secondaryBackgroundColor,
        }
        : {};
    return (React.createElement(React.Fragment, null,
        React.createElement(Base, Object.assign({}, restProps, { css: Object.assign({ width: `calc(100% - ${scrollbarWidth}px)` }, styles) }),
            React.createElement(Title, { css: {
                    fontSize: headingFontSize,
                    transform: `scale(${titleScale}) translateY(${titleTranslateY}px)`,
                } }, title),
            children),
        React.createElement(CloseButton, { as: Theme.None, onClick: onClose, css: {
                left: 'auto',
                right: `${MARGIN_OFFSET + scrollbarWidth}px`,
            } },
            React.createElement(Cross, null))));
});
const Base = styled.header(Object.assign({ position: 'sticky', top: 0, left: 0, width: '100%', padding: { top: 40, bottom: 15 }, zIndex: 1, textAlign: 'center', backgroundColor: theme => theme.mainBackgroundColor, border: {
        bottom: {
            color: 'rgba(0, 0, 0, 0)',
            style: 'solid',
            width: 1,
        },
    } }, transition({ property: 'transform', duration: 200 })));
const Title = styled(H3, Object.assign(Object.assign({ margin: { xy: 0 } }, media(mediaMinMedium, { fontSize: 30, lineHeight: 1.33, margin: { xy: 0 } })), transition({ property: 'transform', duration: 200 })));
export const MARGIN_OFFSET = 4;
export const CloseButton = styled(SquareButton, Object.assign({ position: 'absolute', zIndex: 1, top: 3, left: 'auto', right: 10, color: theme => theme.closeButtonColor, cursor: 'pointer', ':hover': {
        color: theme => theme.buttonTextHoverColor,
    } }, transition({ property: ['transform', 'color'] })));
export const BackButton = styled(SquareButton, Object.assign({ position: 'absolute', zIndex: 1, top: 3, left: 10, right: 'auto', color: theme => theme.closeButtonColor, cursor: 'pointer', ':hover': {
        color: theme => theme.buttonTextHoverColor,
    } }, transition({ property: ['transform', 'color'] })));
