import { __awaiter } from "tslib";
import { addToCartThrottler, calculateCartTotal, calculateDiscount, mapToNewGAProduct, } from './cart-throttler';
import { resolveData, getLocalStorageItem, setLocalStorageItem, URLX } from '@avensia/nitro5-scope';
import currentPageIsProduct from 'Product/current-page-is-product';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
import { fetchProducts, cleanBackendProducts, fetchTransaction } from './Api';
import { getCart } from 'Shared/dynamic-data';
import currentPageIsOrderConfirmation from 'OrderConfirmation/current-page-is-order-confirmation';
const localStorageOrdersKey = 'gtmOrdersTracked';
let gtmEnabled = false;
let pageLoadPromise;
let store;
export function setPageLoadPromise(promise) {
    pageLoadPromise = promise;
}
export function addToDataLayer(action, trackAfterPageLoad = true) {
    return __awaiter(this, void 0, void 0, function* () {
        if (gtmEnabled) {
            if (trackAfterPageLoad) {
                yield pageLoadPromise;
            }
            setTimeout(() => { var _a; return (_a = window === null || window === void 0 ? void 0 : window.dataLayer) === null || _a === void 0 ? void 0 : _a.push(action); });
        }
    });
}
export function initTagManager(s) {
    store = s;
    if (!('dataLayer' in window)) {
        window.dataLayer = [];
    }
    gtmEnabled = true;
}
export function createRemoveFromCartTracking(products, currency, cartTotal) {
    return {
        event: 'remove_from_cart',
        ecommerce: {
            currency: currency,
            value: cartTotal,
            items: products,
        },
    };
}
export function createAddToCartTracking(products, currency, cartTotal) {
    return {
        event: 'add_to_cart',
        ecommerce: {
            currency: currency,
            value: cartTotal,
            items: products,
        },
    };
}
export function addToCart(products, listName) {
    if (!gtmEnabled || products.length === 0) {
        return;
    }
    const cartPayload = {
        products,
        listName,
        isAdd: true,
        currency: store.getState().appShellData.currency,
    };
    addToCartThrottler(cartPayload);
}
export function removeFromCart(products, listName) {
    if (!gtmEnabled || products.length === 0) {
        return;
    }
    const cartPayload = {
        products,
        listName,
        isAdd: false,
        currency: store.getState().appShellData.currency,
    };
    addToCartThrottler(cartPayload);
}
export function TrackViewsOnVideoPDP(productCode) {
    if (!gtmEnabled) {
        return;
    }
    const views = {
        event: 'GAEvent',
        eventCategory: 'Product Description Page',
        eventAction: 'Product Video Viewed',
        eventLabel: productCode,
    };
    addToDataLayer(views);
}
export function generateLead(formName) {
    const userEvent = {
        event: 'generate_lead',
        form_name: formName,
    };
    addToDataLayer(userEvent);
}
export function trackUserPdpEvent(action, productCode) {
    const userEvent = {
        event: 'product_description_page',
        action: action,
        item_id: productCode,
    };
    addToDataLayer(userEvent);
}
export function trackMainMenuClick(text, uri) {
    const mainMenuClick = {
        event: 'main_menu_click',
        click_text: text,
        click_url: uri,
    };
    addToDataLayer(mainMenuClick);
}
export function TrackNewsletterSignupClicks(signupSuccess) {
    if (!gtmEnabled) {
        return;
    }
    const eventLabel = signupSuccess ? 'Successfully subscribed to newsletter' : 'Failed to subscribe to newsletter';
    const clicks = {
        event: 'GAEvent',
        eventCategory: 'Member Form',
        eventAction: 'Newsletter Signup',
        eventLabel: eventLabel,
    };
    addToDataLayer(clicks);
}
export function TrackGoToPayment() {
    return __awaiter(this, void 0, void 0, function* () {
        if (!gtmEnabled) {
            return;
        }
        const cart = yield resolveData(getCart());
        const items = cart.items;
        if (items.length > 0) {
            const productsWithQuantityAndCorrectPrice = yield fetchProductsWithQuantityAndPrice(items);
            const currency = store.getState().appShellData.currency;
            const newGaProducts = productsWithQuantityAndCorrectPrice.map(s => mapToNewGAProduct(s, currency));
            const cartTotal = calculateCartTotal(newGaProducts);
            TrackGoToPaymentEvent(newGaProducts, currency, cartTotal);
        }
    });
}
function TrackGoToPaymentEvent(products, _currency, cartTotal) {
    const GotoPaymentClick = {
        event: 'add_payment_info',
        ecommerce: {
            currency: _currency,
            value: cartTotal,
            coupon: products === null || products === void 0 ? void 0 : products[0].coupon,
            payment_type: 'klarna',
            items: products,
        },
    };
    addToDataLayer(GotoPaymentClick);
}
export function TrustedShopInfoOnOrderConfirmationPage(_email, _paymentMethodType) {
    if (!gtmEnabled) {
        return;
    }
    const trustedShopInformation = {
        event: 'TrustedShopInfo',
        email: _email,
        paymentMethodType: _paymentMethodType,
    };
    addToDataLayer(trustedShopInformation);
}
export function TrackAvailabilityInStoreClicks(_eventLabel) {
    if (!gtmEnabled) {
        return;
    }
    const clicks = {
        event: 'GAEvent',
        eventCategory: 'Product Desciption Page',
        eventAction: 'Store Availability',
        eventLabel: _eventLabel,
    };
    addToDataLayer(clicks);
}
export function TrackSizeGuideClicks(_eventLabel) {
    if (!gtmEnabled) {
        return;
    }
    const clicks = {
        event: 'GAEvent',
        eventCategory: 'Product Desciption Page',
        eventAction: 'Size Guide',
        eventLabel: _eventLabel,
    };
    addToDataLayer(clicks);
}
export function pageLoad(page, isDynamicPageLoad, state) {
    pageLoadPromise = new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
        yield pageLoadAsync(page, isDynamicPageLoad, state);
        resolve();
    }));
}
/**
 * This function will be called on all page loads, regardless if they're scope page
 * navigations or hard browser refreshes. Add things to the datalayer that should be tracked
 * in the page load. Note that you can push more than one action to the datalayer.
 * @param page The current page that has been loaded
 * @param isDynamicPageLoad Determines whether it has been a dynamic scope page load or hard browser page load
 * @param state The Redux state, if there is something needed from Redux to track
 */
function pageLoadAsync(page, isDynamicPageLoad, state) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        if (!gtmEnabled) {
            return;
        }
        if (!page.trackingPageType) {
            return;
        }
        let action = {};
        let productsWithQuantityAndCorrectPrice;
        if (isDynamicPageLoad) {
            action = {
                title: (page.meta && page.meta.title) || '',
                url: page.url,
                pageType: page.trackingPageType,
                event: 'virtualPageLoad',
                type: 'dynamic',
            };
        }
        else {
            const cart = yield resolveData(getCart());
            const items = cart.items;
            if ((items === null || items === void 0 ? void 0 : items.length) > 0) {
                productsWithQuantityAndCorrectPrice = yield fetchProductsWithQuantityAndPrice(items);
            }
            action = {
                title: (page.meta && page.meta.title) || '',
                url: page.url,
                pageType: page.trackingPageType,
                event: 'virtualPageLoad',
                type: 'hard',
                market: store.getState().appShellData.currentCountry.alpha3,
                currency: store.getState().appShellData.currency,
                language: store.getState().appShellData.culture.toString(),
                cartItems: productsWithQuantityAndCorrectPrice,
                time: cart.additional.time,
                contactId: cart.additional.contactId,
            };
        }
        if (page.__goExperiments) {
            const cart = yield resolveData(getCart());
            (_a = page.__goExperiments) === null || _a === void 0 ? void 0 : _a.map(experiment => addToDataLayer({
                event: 'experiment_impression',
                experimentId: experiment.experimentId,
                selectedVariationId: experiment.selectedVariationId,
                contactId: cart.additional.contactId,
            }));
        }
        if (currentPageIsProduct(page)) {
            const currency = store.getState().appShellData.currency;
            const product = [mapToNewGAProduct(page.trackingProduct, currency)];
            const productTotal = calculateCartTotal(product);
            addToDataLayer(pageDetailActionEvent(product, currency, productTotal));
            const pageDetailAction = {
                event: 'productDetailView',
                ecommerce: {
                    detail: {
                        products: cleanBackendProducts([Object.assign({}, page.trackingProduct)]),
                    },
                },
            };
            addToDataLayer(pageDetailAction);
        }
        if (currentPageIsCheckout(page)) {
            const cart = yield resolveData(getCart());
            const items = cart.items;
            if (items.length > 0) {
                const productsWithQuantityAndCorrectPrice = yield fetchProductsWithQuantityAndPrice(items);
                const currency = store.getState().appShellData.currency;
                const newGaProducts = productsWithQuantityAndCorrectPrice.map(s => mapToNewGAProduct(s, currency));
                const cartTotal = calculateCartTotal(newGaProducts);
                addToDataLayer(checkoutActionTracking(newGaProducts, currency, cartTotal));
            }
        }
        if (currentPageIsOrderConfirmation(page)) {
            const orderNumber = page.orderDetails.orderNumber;
            let orderAlreadyTracked = Boolean(getLocalStorageItem(localStorageOrdersKey, []).find(s => s === orderNumber));
            if (!orderAlreadyTracked) {
                const id = new URLX(document.location.href).searchParams.get('id');
                fetchTransaction(id).then(transaction => {
                    // Need to check that we didn't send it here again, because it is async
                    const ordersTracked = getLocalStorageItem(localStorageOrdersKey, []);
                    orderAlreadyTracked = Boolean(ordersTracked.find(s => s === orderNumber));
                    if (orderAlreadyTracked) {
                        return;
                    }
                    const newGaProducts = transaction.products.map(p => mapToNewGAProduct(p, transaction.currencyCode));
                    addToDataLayer(createPurchaseActionTracking(transaction, transaction.paymentMethodName, newGaProducts));
                    setLocalStorageItem(localStorageOrdersKey, [...ordersTracked, orderNumber]);
                });
            }
            voyadoUserTracking(page.orderDetails.contactId);
        }
        if (document.cookie.indexOf('_ga_source=') < 0) {
            let urlReferrer = document.referrer;
            urlReferrer = urlReferrer.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
            document.cookie = `_ga_source=${urlReferrer}`;
        }
        addToDataLayer(action);
    });
}
function fetchProductsWithQuantityAndPrice(items) {
    return __awaiter(this, void 0, void 0, function* () {
        let productsWithQuantityAndCorrectPrice;
        yield fetchProducts(items.map(s => s.code)).then(products => {
            productsWithQuantityAndCorrectPrice = products.map(p => {
                const lineItem = items.find(item => item.code === p.id);
                const currentPricePerItem = lineItem.totalPrice.inclVat / lineItem.quantity;
                return Object.assign(Object.assign({}, p), { quantity: lineItem.quantity, price: formatPriceForGtm(currentPricePerItem) });
            });
        });
        return productsWithQuantityAndCorrectPrice;
    });
}
function pageDetailActionEvent(products, currency, productTotal) {
    return {
        event: 'view_item',
        ecommerce: {
            currency: currency,
            value: productTotal,
            items: products,
        },
    };
}
function checkoutActionTracking(products, currency, cartTotal) {
    return {
        event: 'begin_checkout',
        ecommerce: {
            currency: currency,
            value: cartTotal,
            items: products,
        },
    };
}
function createPurchaseActionTracking(transaction, paymentMethod, products) {
    return {
        event: 'purchase',
        ecommerce: {
            transaction_id: transaction.transactionId,
            currency: transaction.currencyCode,
            value: calculateDiscount(transaction.revenue, '0'),
            tax: calculateDiscount(transaction.tax, '0'),
            shipping: calculateDiscount(transaction.shipping, '0'),
            coupon: transaction.coupon,
            items: products,
        },
        ecommerceNotForGA: {
            currencyCode: transaction.currencyCode,
            customerType: transaction.customer.isNewCustomer ? 'New customer' : 'Old customer',
            purchase: {
                actionField: {
                    coupon: transaction.coupon,
                    id: transaction.transactionId,
                    revenue: transaction.revenue,
                    shipping: transaction.shipping,
                    tax: transaction.tax,
                    subTotalExclVat: transaction.subTotalExclVat,
                },
                products: transaction.products,
            },
        },
        campaignCodes: transaction.coupon,
        paymentMethod: paymentMethod,
    };
}
function formatPriceForGtm(price) {
    let decimals = Math.round((price % 1) * 100)
        .toString()
        .substring(0, 2);
    while (decimals.length < 2) {
        decimals = '0' + decimals;
    }
    const nonDecimals = Math.floor(price);
    return `${nonDecimals}.${decimals}`;
}
export function voyadoUserTracking(contactId) {
    return __awaiter(this, void 0, void 0, function* () {
        if (window.voyado && contactId)
            yield window.voyado('setContactId', contactId);
    });
}
/**
 * Names of product listings, for tracking purposes
 */
export const listNames = {
    checkout: 'checkout',
    recommendations: 'recommendations',
    productPage: 'product-page',
    unknown: 'unknown',
    cart: 'cart',
};
export function RegisterTracking(result) {
    if (!gtmEnabled) {
        return;
    }
    const statusMessage = result.isValid ? 'Register successful' : result.message;
    const clicks = {
        event: 'GAEvent',
        eventCategory: 'Member Form',
        eventAction: 'New Register',
        eventLabel: statusMessage,
    };
    addToDataLayer(clicks);
}
export function ForgotPasswordTracking(result) {
    if (!gtmEnabled) {
        return;
    }
    const statusMessage = result.success ? 'Forgot password email sent successfuly' : result.message;
    const clicks = {
        event: 'GAEvent',
        eventCategory: 'Member Form',
        eventAction: 'Forgot Password',
        eventLabel: statusMessage,
    };
    addToDataLayer(clicks);
}
export function LoginTracking(result) {
    if (!gtmEnabled) {
        return;
    }
    const statusMessage = result.success ? 'Login successful' : result.message;
    const clicks = {
        event: 'GAEvent',
        eventCategory: 'Member Form',
        eventAction: 'Login',
        eventLabel: statusMessage,
    };
    addToDataLayer(clicks);
}
export function logout() {
    // TODO Implement project specific logout event
}
export function checkoutLoadTracking(data) {
    addToDataLayer({
        event: 'KCO_load',
        eventCategory: 'KCO_load',
        eventAction: data.customer.type,
        eventLabel: data.shipping_address.country,
        eventValue: data.shipping_address.postal_code,
    });
}
export function checkoutUserInteractedTracking(data) {
    addToDataLayer({
        event: 'KCO_user_interacted',
        eventCategory: 'KCO_user_interacted',
        eventAction: data.type,
        eventLabel: '',
        eventValue: '0',
    });
}
export function checkoutBillingAddressChangeTracking(data) {
    addToDataLayer({
        event: 'KCO_billing_address_change',
        eventCategory: 'KCO_billing_address_change',
        eventAction: data.postal_code,
        eventLabel: data.country,
        eventValue: '',
    });
}
export function checkoutShippingAddressChangeTracking(data) {
    addToDataLayer({
        event: 'KCO_shipping_address_change',
        eventCategory: 'KCO_shipping_address_change',
        eventAction: data.postal_code,
        eventLabel: data.country,
        eventValue: '',
    });
}
export function checkoutRedirectInitiatedTracking(data) {
    addToDataLayer({
        event: 'KCO_redirect_intiated',
        eventCategory: 'KCO_redirect_intiated',
        eventAction: 'true',
        eventLabel: '',
        eventValue: '0',
    });
}
