import React, { useCallback } from 'react';
import { ContentArea } from '@avensia/nitro5-scope-episerver';
import { AccountPanelMode, closeAccountPanel } from './action-creators';
import Flyout, { Position } from 'Shared/Flyout';
import { BeigeTheme } from 'Shared/Theme';
import { CloseButton, MARGIN_OFFSET } from 'Shared/Flyout/header';
import { Cross } from 'Shared/Icon/indiska';
import { styled } from '@glitz/react';
import { Theme } from 'Shared/Button';
import { mediaMinMedium } from 'Shared/Style';
import { media } from '@glitz/core';
import { useDispatch, useSelector } from 'Shared/State';
import { useDirectionRtl, useIsCompact } from 'Shared/use-viewport';
function Account(props) {
    const appShellData = useSelector(state => state.appShellData);
    const userAccount = useSelector(state => state.userAccount);
    const isCompact = useIsCompact();
    const isRtl = useDirectionRtl();
    const dispatch = useDispatch();
    const content = {
        [AccountPanelMode.Login]: React.createElement(ContentArea, Object.assign({}, appShellData.loginBlock)),
        [AccountPanelMode.Registration]: React.createElement(ContentArea, Object.assign({}, appShellData.registerBlock)),
        [AccountPanelMode.ForgotPassword]: React.createElement(ContentArea, Object.assign({}, appShellData.forgotPasswordBlock)),
    }[userAccount.accountPanelMode];
    const onClose = useCallback(() => {
        dispatch(closeAccountPanel());
    }, [dispatch]);
    return (React.createElement(BeigeTheme, null,
        React.createElement(Flyout, { position: Position.Right, isFullscreen: isCompact, isOpen: userAccount.accountPanelIsOpen, onClose: onClose },
            React.createElement(Base, null, content),
            React.createElement(CloseButton, { as: Theme.None, onClick: onClose, css: Object.assign({ left: isRtl ? `${MARGIN_OFFSET}px` : 'auto', right: isRtl ? 'auto' : `${MARGIN_OFFSET}px` }, media(mediaMinMedium, {
                    /*
                      Let's talk about what's going on here and why we see tha arbitrary value of 21.
                      I tried to add the MARGIN_OFFSET variable plus scrollbarWidth. No matter what I did
                      it would never work. It just didnt print out the value. I tried making variables, useMemo, inside a function...
                      But they are 21 combined, and when its hard coded here, everything just works as intended.
                      If you are a brave brave soul and want to attempt to fix this without an arbitrary value, please, do it.
                      Good luck.
                    */
                    left: isRtl ? 21 : 'auto',
                    right: isRtl ? 'auto' : 21,
                })) },
                React.createElement(Cross, null)))));
}
export default React.memo(Account);
const Base = styled.div({
    height: '100%',
    overflowY: 'scroll',
});
