import { __rest } from "tslib";
import React, { forwardRef, useMemo } from 'react';
import { styled } from '@glitz/react';
export var Ratio;
(function (Ratio) {
    Ratio[Ratio["OneToOne"] = 1] = "OneToOne";
    Ratio[Ratio["TwoToThree"] = 1.5] = "TwoToThree";
    Ratio[Ratio["ThreeToTwo"] = 0.6666666666666666] = "ThreeToTwo";
    Ratio[Ratio["SixteenToNine"] = 0.5625] = "SixteenToNine";
    Ratio[Ratio["ProductImage"] = 1.4] = "ProductImage";
    Ratio[Ratio["BonusCard"] = 0.6153846153846154] = "BonusCard";
})(Ratio || (Ratio = {}));
function calculatePaddingRatio(ratio) {
    let paddingTop;
    switch (ratio) {
        case Ratio.OneToOne:
            paddingTop = '100%';
            break;
        case Ratio.SixteenToNine:
        case Ratio.TwoToThree:
        case Ratio.ThreeToTwo:
        case Ratio.SixteenToNine:
        case Ratio.ProductImage:
        case Ratio.BonusCard:
            paddingTop = `${ratio * 100}%`;
            break;
        default:
            paddingTop = `${ratio * 100}%`;
    }
    return paddingTop;
}
const AspectRatio = styled(forwardRef((_a, ref) => {
    var { ratio } = _a, restProps = __rest(_a, ["ratio"]);
    const paddingTop = useMemo(() => calculatePaddingRatio(ratio), [ratio]);
    const composedStyles = useMemo(() => ({
        ':before': {
            content: '""',
            float: 'left',
            paddingTop,
        },
    }), [paddingTop]);
    return React.createElement(AspectRatioElement, Object.assign({}, restProps, { ref: ref, css: composedStyles }));
}));
const AspectRatioElement = styled.div({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
});
export default AspectRatio;
