import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@glitz/react';
import { Header } from './header';
import { getScrollbarWidth } from 'Shared/Viewport';
import * as style from 'Shared/Style';
import Button from 'Shared/Button';
import { media } from '@glitz/core';
export const ScrollWithStickyHeader = React.memo(({ onClose, children, headerText, bottomButtonContent, hideBackButton }) => {
    // Scroll effect
    const elementRef = useRef();
    const [isScrolled, setScrolled] = useState(false);
    useEffect(function scrollEffect() {
        const baseElement = elementRef.current;
        function handleScroll() {
            const isScrolledOnThisFrame = baseElement.scrollTop > 0;
            if (isScrolledOnThisFrame !== isScrolled) {
                setScrolled(isScrolledOnThisFrame);
            }
        }
        if (baseElement) {
            baseElement.addEventListener('scroll', handleScroll);
        }
        return function cleanup() {
            if (baseElement) {
                baseElement.removeEventListener('scroll', handleScroll);
            }
        };
    });
    return (React.createElement(ScrollContainer, { ref: elementRef },
        headerText && (React.createElement(Header, { onClose: onClose, isScrolled: isScrolled, title: headerText, hideBackButton: hideBackButton })),
        children,
        bottomButtonContent && (React.createElement(BottomArea, null,
            React.createElement(Button, { onClick: onClose }, bottomButtonContent)))));
});
const ScrollContainer = styled.div({
    height: '100%',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
});
export const BottomArea = styled.div(Object.assign(Object.assign({ position: 'sticky', left: ({ isCompact }) => (isCompact ? style.padding.mobile : style.padding.desktop), width: ({ isCompact }) => `calc(100% - ${(isCompact ? style.padding.mobile : style.padding.desktop) * 2}px - ${getScrollbarWidth()}px)`, bottom: style.padding.mobile }, media(style.mediaMinMedium, {
    bottom: style.padding.desktop,
})), { display: 'flex', flexDirection: 'column' }));
