import React from 'react';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import * as style from 'Shared/Style';
import Image, { Ratio } from 'Shared/Image';
import Link from 'Shared/Link';
import { resetLinkDecorator } from 'Shared/Generic/A';
export function LinkItem(props) {
    const { name, url, imageUrl } = props.item;
    return (React.createElement(Base, null,
        React.createElement(Item, { to: url },
            React.createElement(ImageContainer, null,
                React.createElement(HoverZoomImage, { src: imageUrl, alt: name, ratio: Ratio.ProductImage })),
            React.createElement(Name, null, name))));
}
const Base = styled.li({
    gridColumn: {
        start: 'span 3',
    },
    breakInside: 'avoid',
});
const Item = styled(Link, resetLinkDecorator, Object.assign({ display: 'block', marginRight: 20 }, media(style.mediaMinLarge, {
    marginRight: 50,
})));
const ImageContainer = styled.div({
    margin: { bottom: 20 },
    overflow: 'hidden',
});
const HoverZoomImage = styled(Image, Object.assign({ ':hover': {
        transform: 'scale(1.1)',
    } }, style.transition({ property: 'transform' })));
const Name = styled.div({
    font: {
        family: style.fontFamilies.ptSerif,
        size: 22,
    },
    lineHeight: 1.45,
    textTransform: 'none',
    textAlign: 'start',
});
