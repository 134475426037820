// Have properties order and preferred `z-index` order accordingly
// and make sure it always starts with 1
export var ZIndex;
(function (ZIndex) {
    ZIndex[ZIndex["Tray"] = 1] = "Tray";
    ZIndex[ZIndex["Facet"] = 2] = "Facet";
    ZIndex[ZIndex["Dropdown"] = 3] = "Dropdown";
    ZIndex[ZIndex["Header"] = 4] = "Header";
    ZIndex[ZIndex["QuickMenu"] = 5] = "QuickMenu";
    ZIndex[ZIndex["Overlay"] = 6] = "Overlay";
    ZIndex[ZIndex["Spinner"] = 7] = "Spinner";
})(ZIndex || (ZIndex = {}));
