import { __rest } from "tslib";
import React, { useMemo, isValidElement } from 'react';
import { styled } from '@glitz/react';
import { widthFromBreakpoint, Breakpoint } from '@avensia/nitro5-scope';
import useAppearance from 'Shared/use-appearance';
import * as style from 'Shared/Style';
import { margin, fullViewportWidthBlock, GRID_SPACE } from 'Shared/Style';
import { isCompact, isTablet, useCompact } from 'Shared/Viewport';
import { useCurrentBreakpoint } from 'Shared/use-viewport';
import { PageLayoutContext, PageLayoutPartFactionContext, usePageLayoutContext } from './PageLayoutContext';
export var Appearance;
(function (Appearance) {
    /** Content is stretched to the edges if viewport with is less than max page width */
    Appearance[Appearance["Narrow"] = 0] = "Narrow";
    Appearance[Appearance["Normal"] = 1] = "Normal";
    Appearance[Appearance["Wide"] = 2] = "Wide";
    Appearance[Appearance["Gap"] = 3] = "Gap";
    Appearance[Appearance["Part"] = 4] = "Part";
    Appearance[Appearance["Full"] = 5] = "Full";
    Appearance[Appearance["BottomMargin"] = 6] = "BottomMargin";
    Appearance[Appearance["NoBottomMargin"] = 7] = "NoBottomMargin";
    Appearance[Appearance["RichTopicSquareGroupBlock"] = 8] = "RichTopicSquareGroupBlock";
})(Appearance || (Appearance = {}));
export var Layout;
(function (Layout) {
    Layout[Layout["OneToOne"] = 0] = "OneToOne";
    Layout[Layout["OneToTwo"] = 1] = "OneToTwo";
    Layout[Layout["TwoToThree"] = 2] = "TwoToThree";
    Layout[Layout["TwoToOne"] = 3] = "TwoToOne";
    Layout[Layout["ThreeToTwo"] = 4] = "ThreeToTwo";
    Layout[Layout["OneToFour"] = 5] = "OneToFour";
    Layout[Layout["FourToOne"] = 6] = "FourToOne";
})(Layout || (Layout = {}));
function entity(fractions) {
    return {
        fractions,
    };
}
function getGapCountFromFraction(fraction) {
    return fraction - 1;
}
function getFractionShares(appear, breakpoint, entitie, gap) {
    const total = entitie.fractions.reduce((sum, fraction) => sum + fraction, 0);
    if (total === 0) {
        console.error('Invalid pagelayout');
        return entitie.fractions.map(() => 0);
    }
    const gapCount = getGapCountFromFraction(total);
    const totalGap = gapCount * gap;
    const layoutWidth = getLayoutWidth(appear, breakpoint);
    const gridWidth = (layoutWidth - totalGap) / total;
    return entitie.fractions.map(fraction => {
        if (layoutWidth === null) {
            // This is full viewport width and therefore no known width. We will have to work with 100vw
        }
        if (isCompact(breakpoint)) {
            return 1;
        }
        const fractionWidth = gridWidth * fraction + getGapCountFromFraction(fraction) * gap;
        return fractionWidth / layoutWidth;
    });
}
function getLayoutWidth(appear, breakpoint) {
    if (appear(Appearance.Narrow)) {
        return isTablet(breakpoint) ? 445 : 580;
    }
    else if (appear(Appearance.Normal)) {
        return widthFromBreakpoint(style.pageNormalBreakpoint);
    }
    else if (appear(Appearance.Wide)) {
        return widthFromBreakpoint(style.pageWideBreakpoint);
    }
    else if (appear(Appearance.RichTopicSquareGroupBlock)) {
        return 1200;
    }
    if (appear(Appearance.Full)) {
        return null;
    }
    return widthFromBreakpoint(style.pageNormalBreakpoint);
}
function getCssLayoutWidth(appear, breakpoint) {
    const numberWidth = getLayoutWidth(appear, breakpoint);
    return numberWidth === null ? fullViewportWidthBlock : { width: numberWidth };
}
const entities = {
    [Layout.OneToOne]: entity([1, 1]),
    [Layout.OneToTwo]: entity([1, 2]),
    [Layout.TwoToOne]: entity([2, 1]),
    [Layout.TwoToThree]: entity([2, 3]),
    [Layout.ThreeToTwo]: entity([3, 2]),
    [Layout.OneToFour]: entity([1, 4]),
    [Layout.FourToOne]: entity([4, 1]),
};
export const MOBILE_GAP = 50;
export const TABLET_GAP = 80;
export const SMALL_DESKTOP_GAP = 120;
export const BIG_DESKTOP_GAP = 200;
export function factory(Component, defaults = []) {
    return styled(function PageLayout(props) {
        const { appearance, layout, elementRef, children, reverseColumns } = props, restProps = __rest(props, ["appearance", "layout", "elementRef", "children", "reverseColumns"]);
        const { hasColumnGap, hasAnyColumnGap, hasRowGap } = usePageLayoutContext();
        const appear = useAppearance(defaults.concat(appearance));
        const applyColumnGap = appear(Appearance.Gap) && !hasColumnGap;
        const applyRowGap = appear(Appearance.Part) && !hasRowGap;
        const hasBottomMargin = appear(Appearance.BottomMargin) && !appear(Appearance.NoBottomMargin);
        const marginBottom = usePageBottomMargin();
        const currentBreakpoint = useCurrentBreakpoint();
        const nextContext = useMemo(() => {
            return {
                hasColumnGap: applyColumnGap,
                hasAnyColumnGap: hasAnyColumnGap || applyColumnGap,
                hasRowGap: applyRowGap,
                width: getLayoutWidth(appear, currentBreakpoint),
            };
        }, [applyColumnGap, hasAnyColumnGap, applyRowGap, appear, currentBreakpoint]);
        let passChildren = children;
        if (typeof props.layout === 'number') {
            passChildren = React.Children.toArray(props.children)
                .filter(child => isValidElement(child))
                .map((child, i) => {
                const order = reverseColumns ? React.Children.count(props.children) - 1 - i : i;
                const fractionShares = getFractionShares(appear, currentBreakpoint, entities[layout], GRID_SPACE);
                // TODO We need to take order into account
                return (React.createElement(PageLayoutPartFactionContext.Provider, { key: i, value: fractionShares[order] },
                    React.createElement(styled.Div, { css: {
                            gridArea: `column${order}`,
                            order: ({ isCompact }) => (isCompact ? undefined : order),
                        } }, child)));
            });
        }
        const columnGap = getColumnGap(currentBreakpoint);
        return (React.createElement(PageLayoutContext.Provider, { value: nextContext },
            React.createElement(Component, Object.assign({}, restProps, { ref: elementRef, css: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ margin: { x: 'auto' }, maxWidth: '100%', width: '100%' }, (hasBottomMargin && {
                    marginBottom: marginBottom,
                })), getCssLayoutWidth(appear, currentBreakpoint)), (applyColumnGap && {
                    maxWidth: `calc(100vw - ${columnGap}px)`,
                })), (applyRowGap && {
                    marginBottom: margin.huge,
                })), (typeof layout === 'number' && Object.assign(Object.assign({ 
                    // Layouts when `isCompact === true` are stacked below each other
                    display: ({ isCompact }) => (isCompact ? undefined : 'grid') }, (appear(Appearance.Gap) && { gridColumnGap: theme => (theme.isCompact ? undefined : GRID_SPACE) })), { gridTemplateColumns: entities[layout].fractions.map(fraction => `repeat(${fraction}, 1fr)`).join(' '), gridTemplateAreas: '"' +
                        entities[layout].fractions
                            .map((fraction, index) => mapXtimes(fraction, `column${index}`).join(' '))
                            .join(' ') +
                        '"' }))) }), passChildren)));
    });
}
export function usePageBottomMargin() {
    return useCompact() ? 80 : 120;
}
const mapXtimes = (repetitions, string) => {
    return Array.from(Array(repetitions)).map(i => string);
};
export const Basic = factory(styled.Div);
export const Part = factory(styled.Div, [Appearance.Part]);
export const Section = factory(styled.Section);
export const Aside = factory(styled.Aside);
export const Article = factory(styled.Article);
export const Form = factory(styled.Form);
export const Main = factory(styled.Main);
export const getColumnGap = (currentBreakpoint) => {
    const gapMap = {
        0: MOBILE_GAP,
        [Breakpoint.Micro]: MOBILE_GAP,
        [Breakpoint.Tiny]: MOBILE_GAP,
        [Breakpoint.Small]: TABLET_GAP,
        [Breakpoint.Medium]: SMALL_DESKTOP_GAP,
        [Breakpoint.Large]: BIG_DESKTOP_GAP,
        [Breakpoint.Huge]: BIG_DESKTOP_GAP,
        [Breakpoint.Gigantic]: BIG_DESKTOP_GAP,
    };
    const gap = gapMap[currentBreakpoint];
    if (!gap) {
        throw `No gap mapping for breakpoint: ${currentBreakpoint}`;
    }
    return gapMap[currentBreakpoint];
};
