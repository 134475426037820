import React from 'react';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import { useCompact } from 'Shared/Viewport';
import Full from './Full';
import Compact from './Compact';
import * as style from 'Shared/Style';
import { Klarna, Airmee, Budbee, Amex, MasterCard, Visa, PostNord } from 'Shared/Icon/indiska';
import { GrayTheme } from 'Shared/Theme';
// Backend should provide a list of logos to use for each market
export const shippingLogos = [Airmee, Klarna, Budbee, Amex, MasterCard, Visa, PostNord];
function MainFooter({ footer, order }) {
    const isCompact = useCompact();
    return (React.createElement(GrayTheme, null,
        React.createElement(Footer, { css: { order } }, isCompact ? React.createElement(Compact, { footer: footer }) : React.createElement(Full, { footer: footer }))));
}
const Footer = styled.footer({
    backgroundColor: theme => theme.mainBackgroundColor,
    padding: {
        top: ({ isCompact }) => (isCompact ? 40 : 60),
        bottom: style.medium,
    },
});
export default connect((state) => ({
    footer: state.footer,
}))(MainFooter);
