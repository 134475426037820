import currency from 'currency.js';
const defaultFormatter = (price) => currency(price).format();
export const currenyFormatMap = {
    eur: (price, directionRtl) => ({
        separator: ' ',
        decimal: ',',
        symbol: '€',
        pattern: directionRtl ? '! #' : '# !',
        negativePattern: directionRtl ? '! -#' : '-# !',
        precision: Math.round(price) === price ? 0 : 2,
    }),
    sek: (price, directionRtl) => ({
        separator: ' ',
        decimal: ',',
        symbol: 'kr',
        pattern: directionRtl ? '! #' : '# !',
        negativePattern: directionRtl ? '! -#' : '-# !',
        precision: Math.round(price) === price ? 0 : 2,
    }),
    nok: (price, directionRtl) => ({
        separator: ' ',
        decimal: ',',
        symbol: 'kr',
        pattern: directionRtl ? '! #' : '# !',
        negativePattern: directionRtl ? '! -#' : '-# !',
        precision: Math.round(price) === price ? 0 : 2,
    }),
    usd: (price) => ({
        separator: ',',
        decimal: '.',
        symbol: '$',
        pattern: '!#',
        negativePattern: '-!#',
        precision: Math.round(price) === price ? 0 : 2,
    }),
    inr: (price, directionRtl) => ({
        separator: ' ',
        decimal: ',',
        symbol: 'Rs.',
        pattern: directionRtl ? '! #' : '# !',
        negativePattern: directionRtl ? '! -#' : '-# !',
        precision: Math.round(price) === price ? 0 : 2,
    }),
};
export function getFormatForCurrency(currencyString, price, directionRtl) {
    return currenyFormatMap[currencyString.toLowerCase()](price, directionRtl);
}
const formatters = {
    eur: (price, directionRtl) => currency(price, getFormatForCurrency('eur', price, directionRtl)).format(),
    sek: (price, directionRtl) => currency(price, getFormatForCurrency('sek', price, directionRtl)).format(),
    nok: (price, directionRtl) => currency(price, getFormatForCurrency('nok', price, directionRtl)).format(),
    inr: (price, directionRtl) => currency(price, getFormatForCurrency('inr', price, true)).format(),
    usd: (price) => currency(price, getFormatForCurrency('usd', price)).format(),
};
export function formatPrice(price, currencyString, directionRtl) {
    var _a;
    const formatter = (_a = formatters[currencyString.toLowerCase()]) !== null && _a !== void 0 ? _a : defaultFormatter;
    return formatter(price, directionRtl);
}
