import { useRef, useState, useEffect, useCallback } from 'react';
import { on } from '@avensia/nitro5-scope';
export default function useMeasure(updateOnMutations) {
    const [bounds, setBounds] = useState({ left: 0, top: 0, width: 0, height: 0, bottom: 0, right: 0 });
    const elementRef = useRef(null);
    const observer = useRef();
    const hasMeasured = useRef(false);
    function updateBounds(node) {
        if (node) {
            const rect = node.getBoundingClientRect();
            setBounds(rect);
            hasMeasured.current = true;
        }
    }
    const measureElementRef = useCallback((node) => {
        function observeMutations() {
            if (node) {
                observer.current = new MutationObserver(() => {
                    updateBounds(node);
                });
                observer.current.observe(node, { characterData: true, childList: true, subtree: true });
            }
        }
        if (node) {
            elementRef.current = node;
            updateBounds(node);
            if (updateOnMutations) {
                observeMutations();
            }
            else if (observer.current) {
                observer.current.disconnect();
            }
        }
    }, [updateOnMutations]);
    useEffect(() => {
        const unsubscribeResize = on('resize', () => updateBounds(elementRef.current));
        return () => {
            observer.current && observer.current.disconnect();
            unsubscribeResize();
        };
    }, []);
    return { measureElementRef, bounds, hasMeasured: hasMeasured.current };
}
