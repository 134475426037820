import React, { createContext, useContext, useMemo } from 'react';
import { getFraction } from '.';
export const BoxLayoutContext = createContext(null);
export function useBoxLayout() {
    return useContext(BoxLayoutContext);
}
export function useBoxLayoutFraction() {
    const boxLayout = useBoxLayout();
    return getFraction(boxLayout === null || boxLayout === void 0 ? void 0 : boxLayout.layout);
}
export const BoxLayoutProvider = (props) => {
    const value = useMemo(() => {
        return { layout: props.layout, spacing: props.spacing };
    }, [props.layout, props.spacing]);
    return React.createElement(BoxLayoutContext.Provider, { value: value }, props.children);
};
