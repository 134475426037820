import React, { memo } from 'react';
import { styled } from '@glitz/react';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
import * as style from 'Shared/Style';
import { batonTurboCaps, greyNeutral } from 'Shared/Style';
import A from 'Shared/Generic/A';
import { Elephant, Swish } from 'Shared/Icon/indiska';
import { Klarna, Budbee, Amex, MasterCard, Visa, PostNord } from 'Shared/Icon/indiska';
import { format } from '@avensia/nitro5-scope';
import Newsletter from './Newsletter';
import { media } from '@glitz/core';
export default memo(({ footer }) => {
    return (React.createElement(Basic, { appearance: [PageAppearance.Normal, PageAppearance.Gap] },
        React.createElement(NewsletterGroup, null,
            React.createElement(TitleIcon, null,
                React.createElement(Elephant, null)),
            React.createElement(StyledGroup, null,
                React.createElement(Newsletter, { subscriptionTitle: footer.newsletterSubscriptionTitle, subscriptionText: footer.newsletterSubscriptionText, placeholder: footer.newsletterSubscriptionPlaceholder, buttonText: footer.newsletterSubscriptionButtonText }))),
        React.createElement(Groups, null, footer.linkGroups.map((item, i) => (React.createElement(LinkGroups, { key: i },
            React.createElement(GroupHeader, null, item.header),
            item.links.map((linkItem, index) => (React.createElement(GroupItem, { key: index, to: linkItem.url }, linkItem.title))))))),
        React.createElement(BottomRow, { css: __$hoisted_o0 },
            React.createElement(ShippingGroup, null,
                React.createElement(PostNord, { css: LogoStyling.postnord }),
                React.createElement(Budbee, { css: LogoStyling.small }),
                React.createElement(Klarna, { css: LogoStyling.large }),
                React.createElement(Visa, { css: LogoStyling.large }),
                React.createElement(MasterCard, { css: LogoStyling.mastercard }),
                React.createElement(Amex, { css: LogoStyling.amex }),
                React.createElement(Swish, { css: LogoStyling.amex }))),
        React.createElement(BottomRow, null,
            React.createElement(Groups, { css: __$hoisted_o1 },
                React.createElement(Group, { css: __$hoisted_o2 }, footer.bottomLinks.map((item, i) => (React.createElement(BottomRowLink, { key: i, to: item.url }, item.title)))),
                React.createElement(Group, { css: __$hoisted_o3 },
                    React.createElement("span", null,
                        "\u00A9 ",
                        format(footer.copyrightText, { year: new Date().getFullYear() })))))));
});
const FOOTER_FONT_SIZE = 13;
const TitleIcon = styled.div({
    margin: {
        bottom: 20,
    },
    textAlign: 'center',
});
const StyledGroup = styled.div({
    padding: { bottom: '45px' },
});
const Groups = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    padding: { bottom: style.tiny },
});
const NewsletterGroup = styled.div({
    backgroundColor: greyNeutral,
    width: '800px',
    borderRadius: 20,
    padding: {
        top: 60,
        bottom: 15,
    },
    margin: {
        x: 'auto',
        top: 40,
        bottom: 47,
    },
});
const LinkGroups = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingInlineEnd: 20,
    flex: {
        basis: '25%',
        shrink: 0,
        grow: 0,
    },
});
const Group = styled.div({
    flexShrink: 1,
});
const ShippingGroup = styled.div(Object.assign(Object.assign({}, media(style.mediaMinMedium, {
    flexShrink: 1,
    textAlign: 'center',
})), media(style.mediaMinLarge, {
    flexShrink: 0,
})));
const GroupHeader = styled.span(Object.assign({ margin: { y: 10 }, color: style.black }, batonTurboCaps(FOOTER_FONT_SIZE)));
const GroupItem = styled(A, Object.assign({ padding: { x: 0, y: 5 }, color: style.textDarkGray, ':first-child': {
        paddingTop: 6,
    }, ':last-child': {
        paddingBottom: 18,
    } }, batonTurboCaps(FOOTER_FONT_SIZE)));
const BottomRowLink = styled(A, {
    marginInlineEnd: style.large,
    padding: { y: style.tiny },
    ':last-child': {
        marginInlineEnd: style.medium,
    },
});
const BottomRow = styled.div(Object.assign(Object.assign({ color: style.textGray }, batonTurboCaps(FOOTER_FONT_SIZE)), { margin: { bottom: 40 }, maxWidth: '100%' }));
const LogoStyling = {
    small: {
        width: 60,
        height: 15,
        marginInlineEnd: 30,
    },
    large: {
        width: 70,
        height: 20,
        marginInlineEnd: 10,
        marginInlineStart: 10,
    },
    amex: {
        width: 60,
        height: 30,
        marginInlineEnd: 15,
        paddingBottom: 3,
    },
    postnord: {
        width: 70,
        height: 20,
        marginInlineEnd: 30,
        paddingTop: 5,
    },
    mastercard: {
        width: 120,
        height: 55,
        marginInlineEnd: 20,
    },
};
const __$hoisted_o0 = { margin: { bottom: 0, left: 40 } };
const __$hoisted_o1 = { alignItems: 'flex-end' };
const __$hoisted_o2 = { flexBasis: '33.33%', textAlign: 'start' };
const __$hoisted_o3 = { flexBasis: '33.33%', textAlign: 'end' };
