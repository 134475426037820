// This file has been generated by the Scope build. Do not alter
// it manually as your changes will be overwritten. Run:
// nitro.cmd frontend:build-component-registry
// instead.
/* tslint:disable */
// prettier-ignore
export default {
    Avensia_Common_Features_AbTesting_AbTestSettingsBlock: 'Avensia.Common.Features.AbTesting.AbTestSettingsBlock',
    Avensia_Common_Features_Account_ForgotPassword_ForgotPasswordBlock: 'Avensia.Common.Features.Account.ForgotPassword.ForgotPasswordBlock',
    Avensia_Common_Features_Account_Login_LoginBlock: 'Avensia.Common.Features.Account.Login.LoginBlock',
    Avensia_Common_Features_Account_MyPages_Logout_LogoutPageViewModel: 'Avensia.Common.Features.Account.MyPages.Logout.LogoutPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyDetails_MyDetailsPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyDetails.MyDetailsPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyOrderDetails_MyOrderDetailsPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyOrderDetails.MyOrderDetailsPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyOrders_MyOrdersPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyOrders.MyOrdersPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyOverview_BenefitBlock: 'Avensia.Common.Features.Account.MyPages.MyOverview.BenefitBlock',
    Avensia_Common_Features_Account_MyPages_MyOverview_BonusCheckBlock: 'Avensia.Common.Features.Account.MyPages.MyOverview.BonusCheckBlock',
    Avensia_Common_Features_Account_MyPages_MyOverview_MyOverviewPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyOverview.MyOverviewPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyWishlist_MyWishlistPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyWishlist.MyWishlistPageViewModel',
    Avensia_Common_Features_Account_Register_RegisterBlock: 'Avensia.Common.Features.Account.Register.RegisterBlock',
    Avensia_Common_Features_AppShell_Partials_AppShellViewModel: 'Avensia.Common.Features.AppShell.Partials.AppShellViewModel',
    Avensia_Common_Features_Blog_BlogArchive_BlogArchivePageViewModel: 'Avensia.Common.Features.Blog.BlogArchive.BlogArchivePageViewModel',
    Avensia_Common_Features_Blog_BlogCategory_BlogCategoryPageViewModel: 'Avensia.Common.Features.Blog.BlogCategory.BlogCategoryPageViewModel',
    Avensia_Common_Features_Blog_BlogPost_BlogPostViewModel: 'Avensia.Common.Features.Blog.BlogPost.BlogPostViewModel',
    Avensia_Common_Features_Blog_LatestBlogPosts_LatestBlogPostsBlockViewModel: 'Avensia.Common.Features.Blog.LatestBlogPosts.LatestBlogPostsBlockViewModel',
    Avensia_Common_Features_Brand_BrandListViewModel: 'Avensia.Common.Features.Brand.BrandListViewModel',
    Avensia_Common_Features_Brand_BrandViewModel: 'Avensia.Common.Features.Brand.BrandViewModel',
    Avensia_Common_Features_Category_CategoryViewModel: 'Avensia.Common.Features.Category.CategoryViewModel',
    Avensia_Common_Features_Checkout_CheckoutPage_CheckoutPageViewModel: 'Avensia.Common.Features.Checkout.CheckoutPage.CheckoutPageViewModel',
    Avensia_EpiFoundation_Shipping_CheckoutModels_ShippingMethod: 'Avensia.EpiFoundation.Shipping.CheckoutModels.ShippingMethod',
    Avensia_Common_Features_Checkout_CheckoutUpsell_CheckoutUpsellBlockViewModel: 'Avensia.Common.Features.Checkout.CheckoutUpsell.CheckoutUpsellBlockViewModel',
    Avensia_Common_Payments_KlarnaCheckoutV3_KlarnaCheckoutV3PaymentMethodViewModel: 'Avensia.Common.Payments.KlarnaCheckoutV3.KlarnaCheckoutV3PaymentMethodViewModel',
    Avensia_Payments_PayU_PayUPaymentMethodViewModel: 'Avensia.Payments.PayU.PayUPaymentMethodViewModel',
    Avensia_Common_Features_Content_ContentPageViewModel: 'Avensia.Common.Features.Content.ContentPageViewModel',
    Avensia_Common_Features_CustomerReturn_CustomerReturnFormBlock: 'Avensia.Common.Features.CustomerReturn.CustomerReturnFormBlock',
    Avensia_Common_Features_Error_Pages_PageNotFoundViewModel: 'Avensia.Common.Features.Error.Pages.PageNotFoundViewModel',
    Avensia_Common_Features_GiftCards_GiftCardBalance_GiftCardBalanceBlock: 'Avensia.Common.Features.GiftCards.GiftCardBalance.GiftCardBalanceBlock',
    Avensia_Common_Features_GiftCards_GiftCardsBlock_GiftCardsBlockViewModel: 'Avensia.Common.Features.GiftCards.GiftCardsBlock.GiftCardsBlockViewModel',
    Avensia_Common_Features_OrderConfirmation_OrderConfirmationPageViewModel: 'Avensia.Common.Features.OrderConfirmation.OrderConfirmationPageViewModel',
    Avensia_Common_Features_Product_ProductViewModel: 'Avensia.Common.Features.Product.ProductViewModel',
    Avensia_Common_Features_ProductListing_ProductListingPageViewModel: 'Avensia.Common.Features.ProductListing.ProductListingPageViewModel',
    Avensia_Common_Features_ResetPassword_ResetPasswordPageViewModel: 'Avensia.Common.Features.ResetPassword.ResetPasswordPageViewModel',
    Avensia_Common_Features_Search_SearchPageViewModel: 'Avensia.Common.Features.Search.SearchPageViewModel',
    Avensia_Common_Features_Shared_Blocks_Author_AuthorBlock: 'Avensia.Common.Features.Shared.Blocks.Author.AuthorBlock',
    Avensia_Common_Features_Shared_Blocks_Bambuser_BambuserBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Bambuser.BambuserBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Categories_CategoriesBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Categories.CategoriesBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Editor_EditorBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Editor.EditorBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_EsalesProducts_ProductListBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.EsalesProducts.ProductListBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_FlowBox_FlowBoxBlock: 'Avensia.Common.Features.Shared.Blocks.FlowBox.FlowBoxBlock',
    Avensia_Common_Features_Shared_Blocks_Hero_HeroBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Hero.HeroBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_HeroCampaign_HeroCampaignBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.HeroCampaign.HeroCampaignBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_IFrame_IFrameBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.IFrame.IFrameBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Image_ImageBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Image.ImageBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_ImageGroup_ImageGroupBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.ImageGroup.ImageGroupBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Inspiration_InspirationBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Inspiration.InspirationBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Instagram_InstagramBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Instagram.InstagramBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_JournalProductImage_JournalProductImageListBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.JournalProductImage.JournalProductImageListBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Link_LinkBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Link.LinkBlockViewModel',
    Avensia_Common_Features_Account_MyPages_MyOverview_MemberBenefitsBlock: 'Avensia.Common.Features.Account.MyPages.MyOverview.MemberBenefitsBlock',
    Avensia_Common_Features_Shared_Blocks_MemberBenefitsRowBlock_MemberBenefitsRowBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.MemberBenefitsRowBlock.MemberBenefitsRowBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_ProductWithinText_ProductWithinTextBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.ProductWithinText.ProductWithinTextBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_RichTopic_RichTopicBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.RichTopic.RichTopicBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_RichTopicGroup_RichTopicGroupBlock: 'Avensia.Common.Features.Shared.Blocks.RichTopicGroup.RichTopicGroupBlock',
    Avensia_Common_Features_Shared_Blocks_RichTopicProduct_RichTopicProductBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.RichTopicProduct.RichTopicProductBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_RichTopicSquare_RichTopicSquareBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.RichTopicSquare.RichTopicSquareBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_RichTopicSquareGroup_RichTopicSquareGroupBlock: 'Avensia.Common.Features.Shared.Blocks.RichTopicSquareGroup.RichTopicSquareGroupBlock',
    Avensia_Common_Features_Shared_Blocks_RowLayout_RowLayoutBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.RowLayout.RowLayoutBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_SeoBlock_SeoBlock: 'Avensia.Common.Features.Shared.Blocks.SeoBlock.SeoBlock',
    Avensia_Common_Features_Shared_Blocks_SimpleTopic_SimpleTopicBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.SimpleTopic.SimpleTopicBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_SimpleTopicGroup_SimpleTopicGroupBlock: 'Avensia.Common.Features.Shared.Blocks.SimpleTopicGroup.SimpleTopicGroupBlock',
    Avensia_Common_Features_Shared_Blocks_SquareImageBlock_SquareImageBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.SquareImageBlock.SquareImageBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_SquareImageBlock_SquareImageGroupBlock: 'Avensia.Common.Features.Shared.Blocks.SquareImageBlock.SquareImageGroupBlock',
    Avensia_Common_Features_Shared_Blocks_StaticProductListBlock_ProductListBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.StaticProductListBlock.ProductListBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Video_VideoBlock: 'Avensia.Common.Features.Shared.Blocks.Video.VideoBlock',
    Avensia_Common_ContentTypes_Media_ImageDataModel: 'Avensia.Common.ContentTypes.Media.ImageDataModel',
    Avensia_Common_Features_Shared_Partials_PreviewViewModel: 'Avensia.Common.Features.Shared.Partials.PreviewViewModel',
    EPiServer_Core_XhtmlString: 'EPiServer.Core.XhtmlString',
    Avensia_Common_Features_Shared_SiteBanner_SiteBannerBlockViewModel: 'Avensia.Common.Features.Shared.SiteBanner.SiteBannerBlockViewModel',
    Avensia_Common_Features_SiteLayout_CampaignSticker_StickerBlock: 'Avensia.Common.Features.SiteLayout.CampaignSticker.StickerBlock',
    Avensia_Common_Features_SiteLayout_Newsletter_NewsletterBlock: 'Avensia.Common.Features.SiteLayout.Newsletter.NewsletterBlock',
    Avensia_Common_Features_SiteLayout_Usp_UspBlock: 'Avensia.Common.Features.SiteLayout.Usp.UspBlock',
    Avensia_Common_Features_SiteLayout_Usp_UspProductBlock: 'Avensia.Common.Features.SiteLayout.Usp.UspProductBlock',
    Avensia_Common_Features_Start_StartPageViewModel: 'Avensia.Common.Features.Start.StartPageViewModel',
    Avensia_Common_Features_Stores_NearestStore_NearestStoreBlockViewModel: 'Avensia.Common.Features.Stores.NearestStore.NearestStoreBlockViewModel',
    Avensia_Common_Features_Stores_StoreListPageViewModel: 'Avensia.Common.Features.Stores.StoreListPageViewModel',
    Avensia_Common_Features_Stores_StorePageViewModel: 'Avensia.Common.Features.Stores.StorePageViewModel',
    EPiServer_Core_ContentArea: 'EPiServer.Core.ContentArea',
    EPiServer_SpecializedProperties_LinkItemCollection: 'EPiServer.SpecializedProperties.LinkItemCollection',
};
