/**
 * @ComponentFor SiteBannerBlockViewModel
 */
import React, { useMemo } from 'react';
import { EpiProperty, epiPropertyValue } from '@avensia/nitro5-scope-episerver';
import SiteBanner from './';
import { transition } from 'Shared/Style';
import { RenderContext, Rendering } from 'Shared/RenderContext';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import useBambuser from 'Shared/use-bambuser';
export default function SiteBannerBlock(props) {
    const backgroundColor = epiPropertyValue(props.block.backgroundColor);
    const textColor = epiPropertyValue(props.block.textColor);
    const bambuserEventId = epiPropertyValue(props.block.bambuserEventId);
    const scriptUrl = props.bambuserScriptUrl;
    const linkUrl = epiPropertyValue(props.block.linkUrl);
    const linkText = epiPropertyValue(props.block.linkText);
    const hasLinkValues = linkText && linkUrl;
    const { loadBambuser } = useBambuser(bambuserEventId, scriptUrl);
    const siteBannerStyle = useMemo(() => {
        return ((backgroundColor || textColor) && Object.assign(Object.assign(Object.assign({}, transition({ property: ['background-color', 'color'], duration: 500 })), (backgroundColor && { backgroundColor: backgroundColor })), (textColor && { color: textColor })));
    }, [backgroundColor, textColor]);
    return (React.createElement(RenderContext.Provider, { value: Rendering.Sitebanner }, bambuserEventId ? (React.createElement(Button, { onClick: () => loadBambuser() },
        React.createElement(SiteBanner, { css: siteBannerStyle },
            React.createElement(EpiProperty, { for: props.block.text })))) : hasLinkValues ? (React.createElement(PageLink, { to: linkUrl },
        React.createElement(SiteBanner, { css: siteBannerStyle },
            React.createElement(EpiProperty, { for: props.block.text }),
            React.createElement(StyledLinkText, null, linkText)))) : (React.createElement(SiteBanner, { css: siteBannerStyle },
        React.createElement(EpiProperty, { for: props.block.text })))));
}
const Button = styled.div({
    cursor: 'pointer',
});
export const SiteBannerLink = styled(Link, {
    color: 'inherit',
    textDecoration: 'underline',
    ':hover': {
        color: 'inherit',
    },
});
const PageLink = styled(Link, {
    color: 'inherit',
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'none',
        color: 'inherit',
    },
});
const StyledLinkText = styled.span({
    paddingLeft: 3,
    ':hover': {
        textDecoration: 'underline',
        color: 'inherit',
    },
});
