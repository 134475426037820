/** Neutrals */
export const white = '#ffffff';
export const black = '#000000';
export const greyNeutral = '#e9dfd5';
export const offWhite = '#f8f8f8';
/** Colors */
export const green = '#1c5733';
export const turquoise = '#497980';
export const yellow = '#db9620';
export const paleYellow = '#e8bd73';
export const lightBrown = '#db9620';
export const darkBrown = '#5c2a1f';
export const brownRed = '#c3451d';
export const red = '#e5231b';
export const orange = '#F7931E';
/** UI & Text */
export const textOffWhite = '#ededed';
export const textGray = '#9f9f9f';
export const textDarkGray = '#727272';
export const textBrown = '#88745f';
export const textMainMenuItem = '#6c6c6c';
export const steelBlue = '#3B98A0';
export const selectShade = '#dfd4c9';
export const linkDefaultColor = textGray;
export const secondary = '#92669E';
export const secondaryDark = '#633B6F';
export const secondaryText = white;
export const positive = green;
export const negative = red;
//////////////////
export const monochromeLight = '#f9f9f9';
export const monochrome = '#f0f0f0';
export const monochromeDark = '#e7e7e7';
export const monochromeText = black;
//////////////////
/** To be replaced */
export const placeholderGrey = greyNeutral;
export const outlineGrey = '#D1D1D1';
export const hoverGrey = '#E4E4E4';
export const darkWhite = '#F3F3F3';
function lightenDarkenColor(color, amount) {
    let usePound = false;
    if (color[0] === '#') {
        color = color.slice(1);
        usePound = true;
    }
    if (color.length === 3) {
        color = Array.from(color)
            .map(char => char + char)
            .join('');
    }
    const num = parseInt(color, 16);
    let r = (num >> 16) + amount;
    if (r > 255)
        r = 255;
    else if (r < 0)
        r = 0;
    let b = ((num >> 8) & 0x00ff) + amount;
    if (b > 255)
        b = 255;
    else if (b < 0)
        b = 0;
    let g = (num & 0x0000ff) + amount;
    if (g > 255)
        g = 255;
    else if (g < 0)
        g = 0;
    let string = (g | (b << 8) | (r << 16)).toString(16);
    if (string === '0')
        string = '000000';
    if (string.length === 5)
        string = '0' + string;
    return (usePound ? '#' : '') + string;
}
export function lightenColor(color, amount) {
    return lightenDarkenColor(color, amount);
}
export function darkenColor(color, amount) {
    return lightenDarkenColor(color, -amount);
}
