import React from 'react';
import { styled } from '@glitz/react';
import * as style from 'Shared/Style';
import { ChevronDown } from 'Shared/Icon/indiska';
export const AnimatedChevron = styled(({ isUp }) => {
    return (React.createElement(Base, { css: { transform: `scaleY(${isUp ? -1 : 1})` } },
        React.createElement(StyledChevronDown, null)));
});
const Base = styled.div(Object.assign({}, style.transition({
    property: 'transform',
    duration: 300,
    willChange: true,
})));
const StyledChevronDown = styled(ChevronDown, { color: style.textDarkGray });
