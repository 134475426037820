import { __rest } from "tslib";
import React from 'react';
import { TransitionMotion, spring } from 'react-motion';
import { styled } from '@glitz/react';
import { ESC_KEY } from '@avensia/nitro5-scope';
import freezeScroll from 'Shared/freeze-scroll';
import * as style from '../Style';
const Cover = styled.div({
    backgroundColor: 'black',
    position: 'fixed',
    zIndex: style.ZIndex.Overlay,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
    willChange: 'opacity',
});
export let currentOpenOverlay = null;
export function closeOpenOverlay() {
    if (currentOpenOverlay) {
        currentOpenOverlay.close();
    }
}
class Overlay extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            coverIsPresent: false,
        };
        this.close = () => {
            if (this.props.enabled) {
                if (this.unfreezeScroll) {
                    this.unfreezeScroll();
                }
                this.unfreezeScroll = null;
                this.props.onClose();
            }
        };
        this.getStyles = () => this.props.enabled
            ? [
                {
                    key: 'cover',
                    style: { opacity: spring(0.5) },
                },
            ]
            : [];
        this.willEnter = () => {
            this.setState({ coverIsPresent: true });
            return { opacity: 0 };
        };
        this.willLeave = () => ({ opacity: spring(0) });
        this.didLeave = () => {
            this.setState({ coverIsPresent: false });
        };
    }
    componentWillUnmount() {
        this.disable();
    }
    componentDidMount() {
        if (this.props.enabled) {
            this.enable();
        }
    }
    enable() {
        this.setState({ coverIsPresent: true });
        // The key down event must be attached to window and not on `<Root />` for the callback to be
        // called even if active element is `<body />`
        window.addEventListener('keydown', this.keyDown);
        if (currentOpenOverlay !== this) {
            closeOpenOverlay();
        }
        currentOpenOverlay = this;
        // Don't hide overflow if there's a scroll bar. Note
        // that mobile browsers doesn't have scroll bar, so this
        // only applies to mobile browsers.
        if (this.props.disableScrollFreeze !== true) {
            this.unfreezeScroll = freezeScroll();
        }
    }
    disable() {
        window.removeEventListener('keydown', this.keyDown);
        if (this.unfreezeScroll) {
            this.unfreezeScroll();
        }
        this.unfreezeScroll = null;
        if (currentOpenOverlay === this) {
            currentOpenOverlay = null;
        }
    }
    keyDown(e) {
        if (e.keyCode === ESC_KEY && currentOpenOverlay) {
            currentOpenOverlay.close();
            currentOpenOverlay = null;
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.enabled !== nextProps.enabled) {
            if (nextProps.enabled) {
                this.enable();
            }
            else {
                this.disable();
            }
        }
        if (this.props.disableScrollFreeze !== nextProps.disableScrollFreeze) {
            if (nextProps.disableScrollFreeze) {
                if (this.unfreezeScroll) {
                    this.unfreezeScroll();
                }
                this.unfreezeScroll = null;
            }
            else {
                freezeScroll();
            }
        }
    }
    render() {
        const _a = this.props, { enabled, onClose, disableScrollFreeze } = _a, restProps = __rest(_a, ["enabled", "onClose", "disableScrollFreeze"]);
        return (React.createElement(styled.Div, Object.assign({}, restProps),
            React.createElement(TransitionMotion, { styles: this.getStyles, willEnter: this.willEnter, willLeave: this.willLeave, didLeave: this.didLeave }, (interpolatedStyles) => {
                if (interpolatedStyles[0]) {
                    const entry = interpolatedStyles[0];
                    return (React.createElement(Cover, { onClick: this.close, style: Object.assign({ opacity: entry.style.opacity }, (!enabled && {
                            // Don't prevent user from click on something behind
                            // the overlay while fading away
                            pointerEvents: 'none',
                        })) }));
                }
                return null;
            }),
            React.createElement(styled.Div, { css: this.state.coverIsPresent && {
                    position: 'relative',
                    zIndex: style.ZIndex.Overlay,
                    height: 'inherit',
                } }, this.props.children)));
    }
}
Overlay.defaultProps = {
    enabled: true,
    disableScrollFreeze: false,
};
export default styled(Overlay, {
    position: 'relative',
});
