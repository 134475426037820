import Snackbar from 'SiteLayout/Tray/Snackbar';
import { ErrorTheme } from './Theme';
import React from 'react';
import { translate } from '@avensia/nitro5-scope';
import { SmallButton, Theme as ButtonTheme } from './Button';
import { Close } from './Icon';
export const errorHandleCartUpdate = (error, pushSnackbar) => {
    if ((error === null || error === void 0 ? void 0 : error.status) === 400) {
        const remove = pushSnackbar(dismiss => (React.createElement(ErrorTheme, null,
            React.createElement(Snackbar, null,
                translate('/Stock/NotEnoughInStock'),
                React.createElement(SmallButton, { as: ButtonTheme.TransparentWhite, css: __$hoisted_o0, onClick: () => dismiss() },
                    React.createElement(Close, { css: __$hoisted_o1 }))))));
        setTimeout(remove, 4000);
    }
    return Promise.reject(null);
};
const __$hoisted_o0 = { marginInlineStart: 20 };
const __$hoisted_o1 = { alignSelf: 'center' };
