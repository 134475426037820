import React from 'react';
import Flyout, { Position } from 'Shared/Flyout';
import { BeigeTheme } from 'Shared/Theme';
import { useCompact } from 'Shared/Viewport';
import Button, { SquareButton, Theme } from 'Shared/Button';
import { DynamicData, translate } from '@avensia/nitro5-scope';
import { checkoutPageUrl } from 'Shared/known-urls';
import { styled } from '@glitz/react';
import { CartMessage } from 'Shared/FeedbackMessage';
import * as style from 'Shared/Style';
import { Ul } from 'Shared/Generic';
import UpsellItem from './UpsellItem';
import { mediaMaxMedium, mediaMaxSmall, mediaMinMedium, transition, white } from 'Shared/Style';
import { Cross } from 'Shared/Icon/indiska';
import { media } from '@glitz/core';
import { useDynamicData } from 'Shared/use-dynamic-data';
import { getCart } from 'Shared/dynamic-data';
import { formatPrice } from 'Shared/format-price';
import { useSelector } from 'Shared/State';
const FlyoutNotification = ({ displayName, size, color, scent, flavor, isOpen, recommendations, onClose, }) => {
    const appShellData = useSelector(state => state.appShellData);
    const currency = appShellData.currentCountry.currency;
    const cart = useDynamicData(getCart());
    const amountLeftToFreeShipping = cart && cart.viewData.amountLeftToFreeShipping;
    const freeShipping = cart && cart.viewData.amountLeftToFreeShipping === null;
    const isCompact = useCompact();
    const titleProductInfo = `${displayName}
    ${!scent && !flavor && color ? ' · ' + color : ''}
    ${scent ? ' · ' + scent : ''}
    ${flavor ? ' · ' + flavor : ''}
    ${size ? ' · ' + size : ''}`;
    return (React.createElement(BeigeTheme, null,
        React.createElement(Flyout, { position: Position.Right, isFullscreen: isCompact, onClose: onClose, isOpen: isOpen, hideBackButton: true },
            React.createElement(ScrollContainer, null,
                React.createElement(Base, null,
                    React.createElement(Header, null,
                        React.createElement(Title, null, isCompact
                            ? translate('/AddToCartNotification/MobileTitle', titleProductInfo)
                            : translate('/AddToCartNotification/Title', titleProductInfo)),
                        React.createElement(StyledCartMessage, { success: freeShipping }, freeShipping
                            ? translate('/AddToCartNotification/FreeShippingMet')
                            : translate('/AddToCartNotification/FreeShippingNotMet', `${formatPrice(amountLeftToFreeShipping, currency)}`)),
                        React.createElement(ButtonContainer, null,
                            React.createElement(CheckoutButton, { as: Theme.Primary, id: "go-to-checkout", to: checkoutPageUrl(), onClick: onClose }, translate('/AddToCartNotification/GoToCheckout')),
                            React.createElement(KeepShoppingButton, { as: Theme.TransparentBlack, onClick: onClose }, translate('/AddToCartNotification/KeepShopping'))),
                        React.createElement(CloseButton, { as: Theme.None, onClick: onClose },
                            React.createElement(Cross, { css: __$hoisted_o0 }))),
                    React.createElement(List, { column: true },
                        React.createElement("li", null,
                            React.createElement(Title, null, translate('/AddToCartNotification/YouMightAlsoLike'))),
                        recommendations && (React.createElement(DynamicData, { data: recommendations }, data => data.recommendedProductsAfterAddToCart.map(item => (React.createElement(StyledItem, { key: item.code, product: item, onClose: onClose, css: {
                                ':after': {
                                    left: `-${isCompact ? style.padding.mobile : style.padding.desktop}px`,
                                    width: `calc(100% + ${isCompact ? style.padding.mobile : style.padding.desktop}px * 2)`,
                                },
                            } })))))))))));
};
const ScrollContainer = styled.div({
    height: '100%',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
});
const Base = styled.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: {
        grow: 1,
        shrink: 0,
        basis: 0,
    },
});
const Header = styled.div({
    padding: {
        x: ({ isCompact }) => (isCompact ? style.padding.mobile : style.padding.desktop),
        y: style.padding.desktop,
    },
});
const Title = styled.div(Object.assign({ margin: { x: 0, top: 0, bottom: 30 }, font: {
        family: style.fontFamilies.ptSerif,
        size: 18,
        weight: 'normal',
    }, lineHeight: 1.33, textAlign: 'start' }, media(mediaMinMedium, {
    lineHeight: 1.45,
})));
const StyledCartMessage = styled(CartMessage, Object.assign({ margin: {
        top: 15,
        bottom: 20,
    } }, media(mediaMinMedium, {
    margin: {
        y: 20,
    },
})));
const ButtonContainer = styled.div({
    display: 'flex',
});
const ButtonStyle = Object.assign({}, media(mediaMaxMedium, {
    alignItems: 'center',
    flex: {
        grow: 1,
        shrink: 1,
        basis: 0,
    },
    textAlign: 'center',
    lineHeight: 'inherit',
}));
const CheckoutButton = styled(Button, Object.assign(Object.assign({}, ButtonStyle), { marginInlineEnd: ({ isCompact }) => (isCompact ? 15 : 20) }));
const KeepShoppingButton = styled(Button, Object.assign(Object.assign({}, ButtonStyle), media(mediaMaxSmall, {
    fontSize: 10,
})));
const CloseButton = styled(SquareButton, Object.assign({ position: 'absolute', zIndex: 1, top: 4, left: 'auto', right: 8, color: theme => theme.closeButtonColor, cursor: 'pointer', ':hover': {
        color: theme => theme.buttonTextHoverColor,
    } }, transition({ property: ['transform', 'color'] })));
const List = styled(Ul, {
    flex: {
        grow: 1,
        shrink: 0,
        basis: 'auto',
    },
    padding: {
        x: ({ isCompact }) => (isCompact ? style.padding.mobile : style.padding.desktop),
        y: 30,
    },
    backgroundColor: white,
});
const StyledItem = styled(UpsellItem, {
    marginBottom: ({ isCompact }) => (isCompact ? 20 : 30),
});
export default FlyoutNotification;
const __$hoisted_o0 = { height: 16, width: 16 };
