import { appShellReducer, currentPageReducer, themeReducer, breakpointReducer, spinnerReducer, PAGE_LOAD, } from '@avensia/nitro5-scope';
import miniCartReducer from 'SiteLayout/MiniCart/reducer';
import countrySelectorReducer from 'SiteLayout/CountrySelector/reducer';
import mainMenuReducer from 'SiteLayout/MainMenu/reducer';
import quickSearchReducer from 'Search/reducer';
import searchPageReducer from 'Search/page-reducer';
import footerReducer from 'SiteLayout/Footer/reducer';
import userAccountReducer from 'Account/reducer';
import systemTextReducer from 'Shared/SystemText/reducer';
import addToCartNotificationReducer from 'SiteLayout/AddToCartNotification/reducer';
import newsletterPopupReducer from 'SiteLayout/Newsletter/reducer';
import sizeGuideReducer from 'SiteLayout/SizeGuide/reducer';
import storeStockReducer from 'SiteLayout/StoreStock/reducer';
import deepFreeze from './deep-freeze';
export default (state, action) => {
    const appShellData = appShellReducer(state.appShellData, action);
    // We deep freeze the state to make sure we don't accidentally mutate it
    // Note that freezing objects is quite expensive, so we only do it in
    // dev builds
    if (action.type === PAGE_LOAD) {
        window.IS_SERVER_SIDE_RENDERED = false;
    }
    const currentPageState = searchPageReducer(currentPageReducer(state.currentPage, action, appShellData), action);
    return deepFreeze({
        appShellData,
        currentBreakpoint: breakpointReducer(state.currentBreakpoint, action, appShellData),
        currentPage: currentPageState,
        miniCart: miniCartReducer(state.miniCart, action),
        countrySelector: countrySelectorReducer(state.countrySelector, action),
        mainMenu: mainMenuReducer(state.mainMenu, action, appShellData),
        quickSearch: quickSearchReducer(state.quickSearch, action, currentPageState),
        footer: footerReducer(state.footer, action, appShellData),
        userAccount: userAccountReducer(state.userAccount, action),
        currentTheme: themeReducer(state.currentTheme, state.appShellData, action),
        spinner: spinnerReducer(state.spinner, action),
        systemText: systemTextReducer(state.systemText, action),
        addToCartNotification: addToCartNotificationReducer(state.addToCartNotification, action),
        newsletterPopup: newsletterPopupReducer(state.newsletterPopup, action),
        sizeGuide: sizeGuideReducer(state.sizeGuide, action),
        storeStock: storeStockReducer(state.storeStock, action),
    });
};
