import { __awaiter } from "tslib";
import { addUserLog, resolveData } from '@avensia/nitro5-scope';
import { getCurrentUser } from 'Shared/dynamic-data';
export const TOGGLE_NEWSLETTER_POPUP = 'TOGGLE_NEWSLETTER_POPUP';
export function toggleNewsletterPopup() {
    addUserLog('Toggling newsletter popup');
    return {
        type: TOGGLE_NEWSLETTER_POPUP,
    };
}
export function showNewsletterPopup(countrySelectorIsEnabled) {
    return __awaiter(this, void 0, void 0, function* () {
        const user = yield resolveData(getCurrentUser());
        if (user.isLoggedIn) {
            return false;
        }
        else {
            const countrySelectorSeen = (localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('countrySelectorPopupSeen')) === 'true';
            const newsletterPopupSeen = (localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('newsletterPopupSeen')) === 'true';
            if (!newsletterPopupSeen && (countrySelectorSeen || !countrySelectorIsEnabled)) {
                return true;
            }
            else {
                return false;
            }
        }
    });
}
