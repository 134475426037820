import { UPDATE_ADD_TO_CART_NOTIFICATION } from './action-creators';
export default function (state = {
    displayName: '',
    imageUrl: null,
    price: null,
    size: '',
    color: '',
    scent: '',
    flavor: '',
    hasRedPrice: false,
    recommendations: null,
}, action) {
    switch (action.type) {
        case UPDATE_ADD_TO_CART_NOTIFICATION: {
            const notificationAction = action;
            return Object.assign(Object.assign({}, state), { displayName: notificationAction.displayName, imageUrl: notificationAction.imageUrl, price: notificationAction.price, size: notificationAction.size, color: notificationAction.color, scent: notificationAction.scent, flavor: notificationAction.flavor, hasRedPrice: notificationAction.hasRedPrice, recommendations: notificationAction.recommendations });
        }
        default:
            return state;
    }
}
