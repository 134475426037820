import React, { memo, useState } from 'react';
import { styled } from '@glitz/react';
import { selector } from '@glitz/core';
import Accordion from 'Shared/Accordion';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
import * as style from 'Shared/Style';
import { margin, batonTurboCaps, greyNeutral } from 'Shared/Style';
import A from 'Shared/Generic/A';
import { Elephant, Swish } from 'Shared/Icon/indiska';
import { Klarna, Budbee, Amex, MasterCard, Visa, PostNord } from 'Shared/Icon/indiska';
import { format } from '@avensia/nitro5-scope';
import Newsletter from './Newsletter';
import { media } from '@glitz/core';
const FooterLinks = (props) => {
    const [openGroup, setOpenGroup] = useState(null);
    function setOpenGroupWrapper(groupIndex) {
        setOpenGroup(currentValue => (groupIndex === currentValue ? null : groupIndex));
    }
    return (React.createElement(React.Fragment, null, props.linkGroups.map((item, i) => (React.createElement(LinkGroup, { key: i, header: React.createElement(GroupHeader, null, item.header), onToggle: () => setOpenGroupWrapper(i), isOpen: i === openGroup }, item.links.map((linkItem, index) => (React.createElement(GroupItem, { key: index, to: linkItem.url }, linkItem.title))))))));
};
export default memo(function CompactFooter(props) {
    const { footer } = props;
    return (React.createElement(Basic, { appearance: [PageAppearance.Normal, PageAppearance.Gap] },
        React.createElement(NewsletterGroup, null,
            React.createElement(TitleIcon, null,
                React.createElement(ElephantIcon, null)),
            React.createElement(Newsletter, { subscriptionTitle: footer.newsletterSubscriptionTitle, subscriptionText: footer.newsletterSubscriptionText, placeholder: footer.newsletterSubscriptionPlaceholder, buttonText: footer.newsletterSubscriptionButtonText })),
        React.createElement(FooterLinks, { linkGroups: footer.linkGroups }),
        React.createElement(ShippingLogos, null,
            React.createElement(PostNord, { css: LogoStyling.postnord }),
            React.createElement(Budbee, { css: LogoStyling.small }),
            React.createElement(Klarna, { css: LogoStyling.klarna }),
            React.createElement(Visa, { css: LogoStyling.extraHeight }),
            React.createElement(MasterCard, { css: LogoStyling.mastercard }),
            React.createElement(Amex, { css: LogoStyling.extraHeight }),
            React.createElement(Swish, { css: LogoStyling.postnord })),
        React.createElement(BottomSection, null, footer.bottomLinks.map((item, i) => (React.createElement(BottomRowLink, { key: i, to: item.url }, item.title)))),
        React.createElement(BottomSection, null,
            "\u00A9 ",
            format(footer.copyrightText, { year: new Date().getFullYear() }))));
});
const FOOTER_FONT_SIZE = 11;
const ElephantIcon = styled(Elephant, {
    width: 50,
    height: 27,
});
const TitleIcon = styled.div({
    textAlign: 'center',
});
const NewsletterGroup = styled.div({
    backgroundColor: greyNeutral,
    width: '100%',
    borderRadius: 20,
    padding: {
        top: 40,
        bottom: 8,
        x: 30,
    },
    margin: {
        x: 'auto',
        bottom: 20,
    },
    maxWidth: 570,
});
const LinkGroup = styled(Accordion, {});
const GroupHeader = styled.span(Object.assign({ display: 'block', margin: { x: 0, y: margin.large }, color: style.black }, batonTurboCaps(FOOTER_FONT_SIZE)));
const GroupItem = styled(A, Object.assign({ display: 'block', padding: { x: 0, y: 12 }, color: style.textDarkGray, ':first-child': {
        paddingTop: 6,
    }, ':last-child': {
        paddingBottom: 18,
    } }, batonTurboCaps(FOOTER_FONT_SIZE)));
const BottomSection = styled.div(Object.assign(Object.assign(Object.assign(Object.assign({}, selector(':last-child', {
    marginBottom: theme => (theme.isTablet ? 25 : 15),
    float: theme => (theme.isTablet ? 'right' : 'none'),
})), { marginBottom: margin.medium, textAlign: 'center', color: style.textGray }), batonTurboCaps(FOOTER_FONT_SIZE)), { float: theme => (theme.isTablet ? 'left' : 'none') }));
const BottomRowLink = styled(A, Object.assign({ padding: { y: style.tiny } }, selector(':not(:last-child)', {
    marginInlineEnd: style.huge,
})));
const LogoStyling = {
    klarna: {
        width: 65,
        height: 15,
        marginInlineEnd: 11.3,
        marginInlineStart: 11.3,
    },
    small: {
        width: 50,
        height: 12,
        marginInlineEnd: 11.3,
        marginInlineStart: 11.3,
    },
    extraHeight: Object.assign({ width: 50, height: 30, marginInlineEnd: 11.3, marginInlineStart: 11.3 }, media(style.mediaMinMedium, {
        marginTop: 12,
    })),
    postnord: {
        width: 70,
        height: 35,
        marginInlineEnd: 11.3,
        marginInlineStart: 11.3,
        marginTop: 3,
    },
    mastercard: Object.assign({ width: 120, height: 50, marginInlineEnd: 6.3, marginInlineStart: 6.3 }, media(style.mediaMinTiny, {
        marginTop: 0,
    })),
};
const ShippingLogos = styled.div({
    margin: { top: 44, bottom: theme => (theme.isTablet ? 60 : 40) },
    textAlign: 'center',
    color: style.textGray,
});
