import { PAGE_LOAD, UPDATE_APP_SHELL_DATA } from '@avensia/nitro5-scope';
import { OPEN_MAIN_MENU, CLOSE_MAIN_MENU } from './action-creators';
export default function (state = null, action, appShellData) {
    if (state === null) {
        state = Object.assign({ mainMenuOpen: false, openMenuItem: null }, appShellData.mainMenu);
    }
    switch (action.type) {
        case OPEN_MAIN_MENU: {
            const openMainMenuAction = action;
            return Object.assign(Object.assign({}, state), { openMenuItem: openMainMenuAction.menuItem, mainMenuOpen: true });
        }
        case CLOSE_MAIN_MENU: {
            return Object.assign(Object.assign({}, state), { openMenuItem: null, mainMenuOpen: false });
        }
        case UPDATE_APP_SHELL_DATA: {
            const updateAppShellDataAction = action;
            return Object.assign(Object.assign({}, state), updateAppShellDataAction.appShellData.mainMenu);
        }
        case PAGE_LOAD: {
            if (state.mainMenuOpen) {
                return Object.assign(Object.assign({}, state), { mainMenuOpen: false, openMenuItem: null });
            }
            else {
                return state;
            }
        }
        default:
            return state;
    }
}
