import React, { useState } from 'react';
import { postJson, translate } from '@avensia/nitro5-scope';
import { Fieldset, MinHalfField } from 'Shared/Fields/Text';
import { useFeedback, createFeedbackSlaveButton } from 'Shared/Button/Feedback';
import { styled } from '@glitz/react';
import { PlainButton, fullButtonStyled } from 'Shared/Button';
import { mediaMinMicro, mediaMinMedium, mediaMinSmall } from 'Shared/Style';
import { media } from '@glitz/core';
import * as style from 'Shared/Style';
import { generateLead, voyadoUserTracking } from 'TrackingInformation/index';
import { TrackNewsletterSignupClicks } from '../../TrackingInformation/index';
import { termsAndConditionsNewletterPageUrl } from 'Shared/known-urls';
import Link from 'Shared/Link';
import { h1Styled, H2 } from 'Shared/Generic';
export default function Newsletter(props) {
    const [submitState, pushSubmit] = useFeedback();
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    function handleInputChange(e) {
        const value = e.currentTarget.value;
        setEmail(value);
        setSuccessMessage(null);
        setErrorMessage(null);
    }
    function handleSubmit(e) {
        e.preventDefault();
        if (email !== '') {
            const query = {
                email: email.trim(),
            };
            pushSubmit(postJson('/newsletter/subscribe', query)
                .then((result) => {
                TrackNewsletterSignupClicks(result.success);
                if (result.success) {
                    setSuccessMessage(result.message);
                    voyadoUserTracking(result.contactId);
                    generateLead('newsletter');
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 10000);
                    return Promise.resolve();
                }
                else {
                    setErrorMessage(result.message);
                    return Promise.reject();
                }
            })
                .then(() => setEmail('')));
        }
    }
    function handleInvalid(e) {
        TrackNewsletterSignupClicks(false);
        e.preventDefault();
        setErrorMessage(translate('/Newsletter/SubscriptionFailure'));
        setEmail('');
    }
    const { subscriptionTitle, subscriptionText, placeholder, buttonText } = props;
    return (React.createElement(Container, null,
        React.createElement(Form, { id: "signupfornewsletter", onSubmit: handleSubmit },
            React.createElement(Header, null,
                " ",
                subscriptionTitle),
            React.createElement(Description, null, subscriptionText),
            React.createElement(Field, { css: { borderBottomColor: successMessage ? style.green : errorMessage ? style.brownRed : style.black } },
                React.createElement(InputField, { type: "email", value: email, onChange: handleInputChange, onInvalid: handleInvalid },
                    React.createElement(Placeholder, null,
                        placeholder,
                        " ")),
                React.createElement(SignUpButton, { state: submitState, type: "submit" }, buttonText)),
            successMessage && React.createElement(TextMessage, { css: { color: style.green } }, successMessage),
            errorMessage && React.createElement(TextMessage, { css: { color: style.brownRed } }, errorMessage),
            React.createElement(Terms, null,
                translate('/Footer/Terms'),
                React.createElement(InfoLink, { to: termsAndConditionsNewletterPageUrl() }, translate('/Footer/TermsLinkText'))))));
}
const Form = styled.form({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme => (theme.isCompact ? (theme.isTablet ? 20 : 40) : 0),
});
const Container = styled.div({
    padding: {
        x: theme => (theme.isCompact ? 0 : 50),
        y: 20,
    },
});
const Header = h1Styled(H2, Object.assign({ textAlign: 'center', margin: { bottom: 30 } }, media(mediaMinMedium, { fontSize: 40, margin: { bottom: 30, top: 0 } })));
const Description = styled.div({
    fontSize: theme => (theme.isCompact ? (theme.isTablet ? 16 : 14) : 18),
    fontWeight: 'normal',
    textAlign: 'center',
    marginTop: theme => (theme.isCompact ? (theme.isTablet ? -25 : 0) : -15),
});
const Field = styled(Fieldset, {
    alignSelf: 'center',
    margin: {
        top: theme => (theme.isCompact ? -3 : 0),
        bottom: theme => (theme.isCompact ? (theme.isTablet ? 10 : -8) : 20),
        x: 0,
    },
    border: {
        x: { style: 'none' },
        top: { style: 'none' },
        bottom: {
            color: 'black',
            style: 'solid',
            width: 1,
        },
    },
    color: 'black',
});
const InputField = styled(MinHalfField, {
    border: {
        xy: {
            width: 0,
        },
    },
    padding: { x: 0 },
    margin: { x: 0 },
});
const Placeholder = styled.div({
    color: 'black',
});
const SignUpButton = fullButtonStyled(createFeedbackSlaveButton(PlainButton), {
    textTransform: 'uppercase',
    paddingLeft: '3px',
    paddingBottom: '15px',
    marginLeft: '0px',
    width: 'auto',
    lineHeight: `20px`,
    letterSpacing: theme => (theme.isCompact ? '1.1px' : '1.3px'),
    fontSize: theme => (theme.isCompact ? 11 : 13),
});
const TextMessage = styled.div(Object.assign(Object.assign(Object.assign({ textAlign: 'center', fontSize: 12, fontFamily: style.fontFamilies.batonTurbo, marginTop: theme => (theme.isCompact ? (theme.isTablet ? 0 : 20) : -10), alignSelf: 'center', letterSpacing: '0.12px', lineHeight: '20px' }, media(mediaMinMicro, { width: '100%' })), media(mediaMinSmall, { width: '85%' })), media(mediaMinMedium, { width: '70%' })));
const Terms = styled.div({
    fontSize: theme => (theme.isCompact ? (theme.isTablet ? 12 : 10) : 14),
    fontWeight: 'normal',
    textAlign: 'center',
    marginTop: theme => (theme.isCompact ? 15 : 0),
});
const InfoLink = styled(Link, {
    margin: { x: 2.5 },
    color: style.textBrown,
    ':hover': {
        color: style.black,
    },
});
