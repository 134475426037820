import React, { useState } from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import { pushState, translate, backHistory } from '@avensia/nitro5-scope';
import * as style from 'Shared/Style';
import CartButton from './CartButton';
import { Menu, Search } from 'Shared/Icon/indiska';
import Logo from 'Shared/Logotype';
import UserButton from './UserButton';
import { checkoutPageUrl } from 'Shared/known-urls';
import { HeaderItem } from './HeaderItem';
import { batonTurboCaps } from 'Shared/Style';
import { HeaderButton } from './HeaderItem';
import MainMenuFlyoutModal from 'Shared/Modals/mainMenuModal';
import Drawer from 'SiteLayout/Drawer';
import { useSelector } from 'Shared/State';
import { Position } from 'Shared/Flyout';
function CompactHeader(props) {
    const { culture, availableLanguages } = useSelector(state => state.appShellData);
    const [mainMenuOpen, setMainMenuOpen] = useState(false);
    function navigateToCheckout() {
        pushState(checkoutPageUrl());
    }
    function goBack() {
        backHistory();
    }
    function onClose() {
        setMainMenuOpen(false);
    }
    // It's known that this causes a "Warning: Prop `href` did not match" using Dev Server.
    // Is it an actual problem that I should investigate and ask questions about? No.
    // Okey, so I should just ignore and move on? Yes.
    // https://www.youtube.com/watch?v=0dTQlSdiXrE
    const homeUrl = availableLanguages.find(lang => lang.culture === culture).url;
    return (React.createElement(Base, { css: Object.assign({ order: props.order }, (props.pageWithHeaderBorder && { borderBottomColor: style.textOffWhite })) },
        React.createElement(Placeholder, { css: { marginLeft: -10 } }, props.hideMenuItems ? (React.createElement(NavigationItem, { onClick: goBack, css: { color: style.textDarkGray } }, translate('/Checkout/GoBack'))) : (React.createElement(React.Fragment, null,
            React.createElement(HeaderButton, { onClick: () => setMainMenuOpen(true) },
                React.createElement(Menu, null)),
            mainMenuOpen && (React.createElement(MainMenuFlyoutModal, { position: Position.Left, isOpen: mainMenuOpen, onClose: onClose },
                React.createElement(Drawer, { onClose: onClose, mainMenu: props.mainMenu }))),
            React.createElement(HeaderButton, { onClick: props.toggleQuickSearch },
                React.createElement(Search, null))))),
        React.createElement(HomeLink, { to: homeUrl, css: props.hideMenuItems && { padding: { y: 5 } } },
            React.createElement(StyledLogo, null)),
        React.createElement(Placeholder, { css: { justifyContent: 'flex-end', marginRight: -10 } },
            React.createElement(UserButton, null),
            !props.hideMenuItems && (React.createElement(CartButton, { toggleMiniCart: navigateToCheckout, miniCartIsDisabled: props.miniCartIsDisabled })))));
}
export default React.memo(CompactHeader);
const Base = styled.div({
    display: 'flex',
    alignItems: 'center',
    height: 50,
    padding: { x: 20 },
    border: {
        bottom: {
            style: 'solid',
            color: 'transparent',
            width: 1,
        },
    },
});
// Used for centering logotype in the middle when content
// to the right and left haft different width
const Placeholder = styled.div({
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    alignItems: 'flex-start',
});
const NavigationItem = styled(HeaderItem, Object.assign(Object.assign({ padding: { x: 15, y: style.large } }, batonTurboCaps(12)), { whiteSpace: 'nowrap' }));
const HomeLink = styled(Link, {
    margin: { x: 'auto' },
});
const StyledLogo = styled(Logo, {
    width: 90,
});
