import { __awaiter } from "tslib";
import { addUserLog, resolveData } from '@avensia/nitro5-scope';
import { getCurrentUser } from 'Shared/dynamic-data';
export const TOGGLE_COUNTRY_SELECTOR = 'TOGGLE_COUNTRY_SELECTOR';
export function toggleCountrySelector() {
    addUserLog('Toggling country selector');
    return {
        type: TOGGLE_COUNTRY_SELECTOR,
    };
}
export function showCountrySelector() {
    return __awaiter(this, void 0, void 0, function* () {
        const user = yield resolveData(getCurrentUser());
        if (user.isLoggedIn) {
            return false;
        }
        else {
            const countrySelectorSeen = (localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('countrySelectorPopupSeen')) === 'true';
            if (countrySelectorSeen) {
                return false;
            }
            else {
                return true;
            }
        }
    });
}
