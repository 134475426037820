import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
import { GrayThemeBlock } from 'Shared/Theme';
export default createSiteBanner();
export function createSiteBanner(ThemeBlock = GrayThemeBlock) {
    const Base = styled(ThemeBlock, {
        overflow: 'hidden',
    });
    const Banner = styled(Basic, {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'center',
        padding: { y: 10 },
        fontSize: 12,
        lineHeight: 1.67,
    });
    return styled((_a) => {
        var { elementRef, children } = _a, restProps = __rest(_a, ["elementRef", "children"]);
        return (React.createElement(Base, Object.assign({}, restProps),
            React.createElement(Banner, { appearance: [PageAppearance.Normal, PageAppearance.Gap], elementRef: elementRef }, children)));
    });
}
