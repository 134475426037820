import { addUserLog, loadScript } from '@avensia/nitro5-scope';
import currentPageIsProduct from 'Product/current-page-is-product';
export function loadLipscore(store) {
    const { lipscoreScriptUrl, lipscoreApiKey } = store.getState().appShellData.siteSettings;
    if (lipscoreScriptUrl && lipscoreApiKey) {
        window.lipscoreInit = function () {
            window.lipscore.init({
                apiKey: lipscoreApiKey,
            });
        };
        if (currentPageIsProduct(store.getState().currentPage)) {
            /**
             * On product page we want to load it as soon as possible
             * since it's visible there upfront
             * */
            loadScript(lipscoreScriptUrl);
            addUserLog(`lipscore loaded`);
        }
        else {
            loadScriptAfterTTI(lipscoreScriptUrl, 'lipscore');
        }
    }
    else {
        addUserLog('No lipscore script url, not loading lipscore');
    }
}
export function loadScriptAfterTTI(url, name) {
    let eventListenerAdded = false;
    if (!__BROWSER__) {
        return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
        const load = () => setTimeout(() => {
            loadScript(url)
                .then(() => {
                addUserLog(`${name} loaded`);
                resolve();
                if (eventListenerAdded) {
                    window.removeEventListener('load', load);
                }
            })
                .catch((err) => {
                addUserLog(`could not load ${name}`);
                reject(err);
            });
        }, 4000);
        if (document.readyState === 'complete') {
            load();
        }
        else {
            window.addEventListener('load', load);
            eventListenerAdded = true;
        }
    });
}
