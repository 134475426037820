import { __awaiter } from "tslib";
import React, { useState } from 'react';
import { styled } from '@glitz/react';
import { useSelector } from 'Shared/State';
import { loadScript, translate } from '@avensia/nitro5-scope';
import { PlainButton } from 'Shared/Button';
import { textDarkGray, textOffWhite, margin } from 'Shared/Style';
import { SpeechBubble } from 'Shared/Icon/indiska';
import { Spinner } from 'Shared/Icon';
export default function ZendeskChat() {
    const zendeskSettings = useSelector(state => state.appShellData.siteSettings.zendeskSettings);
    const zendeskApiKey = useSelector(state => state.appShellData.siteSettings.zendeskApiKey);
    const zendeskLanguage = useSelector(state => state.appShellData.culture).substring(0, 2);
    const [showZendeskButton, setShowZendeskButton] = useState(true);
    const [zendeskIsLoading, setZendeskIsLoading] = useState(false);
    const openChat = () => __awaiter(this, void 0, void 0, function* () {
        setZendeskIsLoading(true);
        yield loadScript(`https://static.zdassets.com/ekr/snippet.js?key=${zendeskApiKey}`, scriptElement => {
            scriptElement.async = true;
            scriptElement.id = 'ze-snippet';
            return scriptElement;
        });
        const settings = JSON.parse(zendeskSettings);
        settings.webWidget.position = { horizontal: 'right' };
        window.zESettings = settings;
        window.zE('webWidget', 'setLocale', zendeskLanguage);
        setTimeout(() => {
            window.zE('webWidget', 'open');
            const launcher = document.getElementById('launcher');
            if (launcher) {
                launcher.style.zIndex = '3';
            }
        }, 1500);
        setTimeout(() => {
            setShowZendeskButton(false);
        }, 6000); //Safety so the loading button doesn't disappear too quickly, sometimes it takes a while for the chat to open up. Once it does, loading button will be hidden behind
    });
    return (React.createElement(React.Fragment, null, showZendeskButton && zendeskApiKey && (React.createElement(Button, { onClick: openChat }, zendeskIsLoading ? (React.createElement(StyledSpinner, null)) : (React.createElement(React.Fragment, null,
        React.createElement(StyledSpeechBubble, null),
        translate('/Shared/ZendeskChatLabel')))))));
}
const Button = styled(PlainButton, {
    backgroundColor: textOffWhite,
    color: textDarkGray,
    padding: {
        x: '1.57143rem',
    },
    borderRadius: '999rem',
    letterSpacing: '0.6',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.07143rem',
    fontWeight: 700,
    position: 'fixed',
    textTransform: 'none',
    right: 20,
    left: 'auto',
    bottom: 16,
    height: 45,
    minWidth: 112,
    zIndex: 2,
});
const StyledSpeechBubble = styled(SpeechBubble, {
    marginInlineEnd: margin.small,
    fill: textDarkGray,
});
const StyledSpinner = styled(Spinner, {
    position: 'absolute',
    color: textDarkGray,
    top: 'calc(50% - 0.5em)',
    left: 'calc(50% - 0.5em)',
    fontSize: '1em',
});
