let siteSettings = null;
export function setKnownUrls(settings) {
    siteSettings = settings;
}
export function searchPageUrl() {
    return siteSettings.searchPage.url;
}
export function checkoutPageUrl() {
    return siteSettings.checkoutPage.url;
}
export function termsAndConditionsPageUrl() {
    var _a;
    return (_a = siteSettings.termsAndConditionsPage) === null || _a === void 0 ? void 0 : _a.url;
}
export function myOverviewPageUrl() {
    return siteSettings.myOverviewPage.url;
}
export function storeListPageUrl() {
    return siteSettings.storeListPage;
}
export function loginPageUrl() {
    return siteSettings.loginPage;
}
export function logoutPageUrl() {
    return siteSettings.logoutPage;
}
export function activateAccountPageUrl() {
    return siteSettings.activateAccountPage;
}
export function abandonedCartPageUrl() {
    return siteSettings.abandonedCartPage;
}
export function termsAndConditionsNewletterPageUrl() {
    var _a;
    return (_a = siteSettings.termsAndConditionsNewsletterPage) === null || _a === void 0 ? void 0 : _a.url;
}
