import React, { useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import { ChevronDown } from 'Shared/Icon/indiska';
import { fullButtonStyled, PlainButton } from 'Shared/Button';
import HeightTransition from 'Shared/HeightTransition';
import { AnimatedChevron } from 'Shared/Animation/AnimatedChevron';
const Accordion = props => {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);
    function onClick() {
        setIsOpen(!isOpen);
        if (props.onToggle) {
            props.onToggle();
        }
    }
    return (React.createElement(styled.Div, null,
        React.createElement(FullButton, { onClick: onClick, "aria-expanded": isOpen, "aria-label": props.ariaLabel || null, title: props.ariaLabel || null },
            React.createElement(HeaderLayout, null,
                props.header,
                React.createElement(AnimatedChevron, { isUp: isOpen },
                    React.createElement(ChevronDown, null)))),
        React.createElement(HeightTransition, { isDescription: props.isDescription, isOpen: isOpen },
            React.createElement(Body, null, props.children))));
};
const HeaderLayout = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: {
        x: 'auto',
    },
});
const Body = styled.div({
    position: 'relative',
    background: {
        color: 'inherit',
    },
    overflow: 'hidden',
    margin: {
        x: 'auto',
    },
    textAlign: 'start',
});
const FullButton = fullButtonStyled(PlainButton);
export default styled(Accordion);
