import { addToDataLayer, createRemoveFromCartTracking, createAddToCartTracking } from '.';
import { postJson } from '@avensia/nitro5-scope';
let timeoutId = 0;
let cartActionsQueue = [];
let trackingInformationProductsInQueue = [];
export function addToCartThrottler(payload) {
    cartActionsQueue.push(payload);
    payload.products.forEach(p => trackingInformationProductsInQueue.push(p));
    if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = 0;
    }
    timeoutId = setTimeout(() => {
        excecuteCartThrottlerQueue(payload.currency);
    }, 2000);
}
function excecuteCartThrottlerQueue(currency) {
    if (!cartActionsQueue.length) {
        return;
    }
    timeoutId = 0;
    const { addedCodeQuantities, removedCodeQuantities } = mergeCartAddActions();
    trackingInformationProductsInQueue = [];
    cartActionsQueue = [];
    const allCodesDistinct = [...addedCodeQuantities, ...removedCodeQuantities]
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(s => s.code);
    if (allCodesDistinct === null || allCodesDistinct === void 0 ? void 0 : allCodesDistinct.length) {
        postJson('/Tracking/GetTrackingProducts', { variantCodes: allCodesDistinct }).then((products) => {
            const addedProducts = addedCodeQuantities.map(s => mapCodeQuantityToTrackingProduct(s, products));
            const removedProducts = removedCodeQuantities.map(s => mapCodeQuantityToTrackingProduct(s, products));
            if (addedProducts.length) {
                const newAddedGAProducts = addedProducts.map(s => mapToNewGAProduct(s, currency));
                const addedAmount = calculateCartTotal(newAddedGAProducts);
                addToDataLayer(createAddToCartTracking(newAddedGAProducts, currency, addedAmount));
            }
            if (removedProducts.length) {
                const newRemovedGAProducts = removedProducts.map(s => mapToNewGAProduct(s, currency));
                const removedAmount = calculateCartTotal(newRemovedGAProducts);
                addToDataLayer(createRemoveFromCartTracking(newRemovedGAProducts, currency, removedAmount));
            }
        });
    }
}
export function mapToNewGAProduct(product, currency) {
    var _a;
    if (!product)
        return;
    const quantity = product.quantity === 0 ? 1 : product.quantity;
    const breadCrumbs = (_a = product.category) === null || _a === void 0 ? void 0 : _a.split('/');
    const newGaProduct = {
        item_id: product.dimension18,
        item_name: product.name,
        coupon: product.couponCodes,
        currency: currency,
        discount: calculateDiscount(product.dimension17, product.price),
        item_category: breadCrumbs === null || breadCrumbs === void 0 ? void 0 : breadCrumbs[0],
        item_category2: (breadCrumbs === null || breadCrumbs === void 0 ? void 0 : breadCrumbs.length) >= 1 ? breadCrumbs === null || breadCrumbs === void 0 ? void 0 : breadCrumbs[1] : '',
        item_category3: breadCrumbs === null || breadCrumbs === void 0 ? void 0 : breadCrumbs[(breadCrumbs === null || breadCrumbs === void 0 ? void 0 : breadCrumbs.length) - 1],
        item_twar: product.dimension14,
        item_label: product.dimension13,
        item_sku: product.id,
        item_collection: product.dimension1,
        item_org_price: calculateDiscount(product.dimension17, '0'),
        price: calculateDiscount(product.price, '0'),
        quantity: quantity,
    };
    return newGaProduct;
}
export function calculateCartTotal(products) {
    if ((products === null || products === void 0 ? void 0 : products.length) > 0)
        return products.reduce((totalAmount, p) => totalAmount + p.price * p.quantity, 0);
    else
        return 0;
}
export function calculateDiscount(originalPrice, discountedPrice) {
    const parsedOriginalPrice = parseFloat(originalPrice);
    const parsedDiscountedPrice = parseFloat(discountedPrice);
    const price = (parsedOriginalPrice - parsedDiscountedPrice).toString();
    return Number(parseFloat(price).toFixed(2));
}
function mapCodeQuantityToTrackingProduct(codeQuantity, products) {
    const trackingProduct = products.find(c => c.id === codeQuantity.code);
    if (trackingProduct !== undefined) {
        trackingProduct.quantity = codeQuantity.quantity;
    }
    return trackingProduct;
}
function mergeCartAddActions() {
    const intermediate = {};
    // If the user has done very quick modifications to the cart from different places
    // we will track as if it did all the modifications in the last place
    let lastActionListName = '';
    cartActionsQueue.forEach(payload => {
        payload.products.forEach(product => {
            if (!(product.code in intermediate)) {
                intermediate[product.code] = payload.isAdd ? product.quantity : -product.quantity;
            }
            else {
                intermediate[product.code] = payload.isAdd
                    ? intermediate[product.code] + product.quantity
                    : intermediate[product.code] - product.quantity;
            }
            lastActionListName = payload.listName;
        });
    });
    const addedCodeQuantities = [];
    const removedCodeQuantities = [];
    for (const code in intermediate) {
        const trackingProduct = trackingInformationProductsInQueue.find(s => s.code === code);
        if (intermediate[code] > 0) {
            trackingProduct.quantity = intermediate[code];
            addedCodeQuantities.push(trackingProduct);
        }
        else if (intermediate[code] < 0) {
            trackingProduct.quantity = Math.abs(intermediate[code]);
            removedCodeQuantities.push(trackingProduct);
        }
    }
    return { addedCodeQuantities, removedCodeQuantities, listName: lastActionListName };
}
