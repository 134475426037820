import React, { useState, useEffect } from 'react';
import { translate } from '@avensia/nitro5-scope';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import * as style from 'Shared/Style';
import { transition } from 'Shared/Style';
import { useCompact } from 'Shared/Viewport';
import Link from 'Shared/Link';
import Button, { UnderLineButtonBlackWithHover } from 'Shared/Button';
import { termsAndConditionsPageUrl } from 'Shared/known-urls';
function CookieMessage() {
    const [acceptCookies, setAcceptCookies] = useState(null);
    const isCompact = useCompact();
    useEffect(() => setAcceptCookies((localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('acceptedCookies')) || 'false'), []);
    function setCookieAcceptance() {
        localStorage.setItem('acceptedCookies', 'true');
        setAcceptCookies('true');
    }
    return (React.createElement(Base, { css: {
            opacity: acceptCookies === 'false' ? 1 : 0,
            pointerEvents: acceptCookies === 'true' ? 'none' : 'auto',
            bottom: /facebook|instagram/i.test(navigator.userAgent) ? 50 : 25,
        } },
        !isCompact && React.createElement(Title, null, translate('/Cookies/Title')),
        React.createElement(Message, null,
            translate('/Cookies/Message'),
            ' ',
            React.createElement(LearnMore, { to: termsAndConditionsPageUrl() }, translate('/Cookies/LearnMore'))),
        isCompact ? (React.createElement(CompactAccept, { onClick: setCookieAcceptance }, translate('/Cookies/Accept'))) : (React.createElement(FullAccept, { onClick: setCookieAcceptance }, translate('/Cookies/Accept')))));
}
export default React.memo(CookieMessage);
const Base = styled.div(Object.assign(Object.assign(Object.assign({ position: 'fixed', right: 25, bottom: 25, left: 25, padding: { xy: 20 }, backgroundColor: style.greyNeutral, boxShadow: '10px 10px 40px 0 rgba(0, 0, 0, 0.1)' }, media(style.mediaMinMedium, {
    left: 'auto',
    right: 30,
    bottom: 30,
    width: 300,
    padding: { x: 20, y: 40 },
})), transition({
    property: 'opacity',
    duration: 500,
})), { zIndex: style.ZIndex.Header }));
const Title = styled.div({
    margin: { bottom: 15 },
    font: {
        family: style.fontFamilies.ptSerif,
        size: 18,
        weight: 'normal',
    },
    lineHeight: 1.44,
    textAlign: 'center',
});
const Message = styled.div(Object.assign({ margin: { bottom: 20 }, fontSize: 14 }, media(style.mediaMinMedium, {
    fontSize: 12,
    lineHeight: 1.67,
    letterSpacing: '0.01em',
    textAlign: 'center',
})));
const LearnMore = styled(Link, {
    color: style.textBrown,
});
const CompactAccept = styled(Button, {
    width: '100%',
});
const FullAccept = styled(UnderLineButtonBlackWithHover, {
    margin: { x: 'auto' },
    display: 'block',
    fontSize: 11,
});
