import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
export default styled((_a) => {
    var { bare, row, column } = _a, restProps = __rest(_a, ["bare", "row", "column"]);
    return (React.createElement(styled.Ul, Object.assign({}, restProps, { css: Object.assign(Object.assign({ margin: { xy: 0 }, padding: { xy: 0 }, listStyle: 'inside', lineHeight: ({ isCompact }) => (isCompact ? 1.86 : 1.75) }, ((row || column || bare) && {
            display: 'flex',
            listStyle: 'none',
        })), (column && {
            flexDirection: 'column',
        })) })));
});
