import { __awaiter } from "tslib";
import { postJson } from '@avensia/nitro5-scope';
const productsEndpoint = '/Tracking/GetTrackingProducts';
const transactionEndpoint = '/Tracking/GetTransaction';
export function fetchProducts(variantCodes) {
    return __awaiter(this, void 0, void 0, function* () {
        let trackingProducts;
        if ((variantCodes === null || variantCodes === void 0 ? void 0 : variantCodes.length) > 0) {
            trackingProducts = yield postJson(productsEndpoint, {
                variantCodes,
            });
            trackingProducts = cleanBackendProducts(trackingProducts);
        }
        return trackingProducts;
    });
}
export function fetchTransaction(id) {
    return __awaiter(this, void 0, void 0, function* () {
        const trackingTransaction = yield postJson(transactionEndpoint, { id });
        trackingTransaction.products = cleanBackendProducts(trackingTransaction.products);
        return trackingTransaction;
    });
}
export function cleanBackendProducts(products) {
    return products.map(trackingProduct => {
        let key;
        for (key in trackingProduct) {
            if (trackingProduct[key] === null) {
                delete trackingProduct[key];
            }
        }
        return trackingProduct;
    });
}
