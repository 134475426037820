import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/nitro5-scope';
import { SimpleItem } from './Item';
import { itemKey } from '../MainMenu/item-helpers';
import { Ul } from 'Shared/Generic';
import * as style from 'Shared/Style';
import { Theme } from 'Shared/Button';
export default function Level(props) {
    const { data } = props;
    const items = isRoot(data) ? data : data.items || [];
    return (React.createElement(StyledUl, { column: true }, !isRoot(data) &&
        items.map((item, i) => {
            if (isLinkItem(item)) {
                return (React.createElement(SubCategoryItem, { onClick: props.onClose, key: itemKey(item), to: item.url }, item.name));
            }
            else {
                return (React.createElement(SubCategory, { key: itemKey(item) },
                    i !== 0 && (React.createElement(SubCategoryTitle, { onClick: props.onClose, to: item.url }, item.name)),
                    React.createElement(Ul, { column: true }, item.items.map(child => (React.createElement("li", { key: itemKey(child) },
                        React.createElement(SubCategoryItem, { onClick: props.onClose, to: child.url }, child.name))))),
                    React.createElement(ShowAll, { onClick: props.onClose, as: Theme.None, to: i === 0 ? data.url : item.url }, translate('/MainMenu/ShowAll', i === 0 ? data.name : item.name))));
            }
        })));
}
export function isRoot(data) {
    return Array.isArray(data);
}
export function isLinkItem(item) {
    return !item.showAllLink && !item.items.length;
}
const StyledUl = styled(Ul, {
    margin: { top: 20 },
});
const SubCategory = styled.li({
    marginBottom: 60,
});
const SubCategoryTitle = styled(SimpleItem, {
    display: 'block',
    marginBottom: 30,
    fontSize: 11,
    lineHeight: 1.55,
    letterSpacing: 1.1,
    textTransform: 'uppercase',
    color: style.textBrown,
});
const SubCategoryItem = styled(SimpleItem, {
    display: 'block',
    marginBottom: 30,
    fontSize: 16,
    lineHeight: 1.5,
});
const ShowAll = styled(SimpleItem, {
    fontSize: 16,
    lineHeight: 1.5,
    color: style.textBrown,
});
