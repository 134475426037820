import React from 'react';
import { styled } from '@glitz/react';
import { toggleCountrySelector } from '../CountrySelector/action-creators';
import * as style from 'Shared/Style';
import { PlainButton, Casing } from 'Shared/Button';
import { translate } from '@avensia/nitro5-scope';
import { useDispatch, useSelector } from 'Shared/State';
export var Type;
(function (Type) {
    Type[Type["Language"] = 0] = "Language";
    Type[Type["Country"] = 1] = "Country";
    Type[Type["Currency"] = 2] = "Currency";
})(Type || (Type = {}));
export default function CountrySelectorButton(props) {
    const appShellData = useSelector(state => state.appShellData);
    const dispatch = useDispatch();
    function clickCountrySelector(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        dispatch(toggleCountrySelector());
    }
    return (React.createElement(StyledButton, { casing: Casing.Uppercase, onClick: clickCountrySelector },
        props.mode === Type.Language && (React.createElement(Label, null,
            translate('/Shared/Language'),
            ":",
            ' ',
            React.createElement(Value, null, appShellData.availableLanguages.find(language => language.culture === appShellData.culture).name))),
        props.mode === Type.Country && (React.createElement(Label, null,
            translate('/Shared/Country'),
            ": ",
            React.createElement(Value, null, appShellData.currentCountry.name))),
        props.mode === Type.Currency && (React.createElement(Label, null,
            "Shipping to: ",
            React.createElement(Value, null, appShellData.currentCountry.currency)))));
}
const StyledButton = styled(PlainButton, {
    letterSpacing: 'inherit',
});
const Label = styled.span({
    color: style.textDarkGray,
});
const Value = styled.span({
    color: style.textGray,
});
