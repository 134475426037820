const DEFAULT_DURATION = 200;
const DEFAULT_TIMING_FUNCTION = 'ease-out';
export function transition(props) {
    const { property, duration = DEFAULT_DURATION, timingFunction = DEFAULT_TIMING_FUNCTION, delay, willChange } = props;
    const properties = Array.isArray(property) ? property.join(', ') : property;
    const durations = Array.isArray(duration) ? duration.map(x => (x > 10 ? `${x}ms` : `${x}s`)).join(', ') : duration;
    return Object.assign({ transition: {
            property: properties,
            duration: durations,
            timingFunction,
            delay,
        } }, (willChange && {
        willChange: properties,
    }));
}
export function animation(props) {
    const { name, duration = DEFAULT_DURATION, timingFunction = DEFAULT_TIMING_FUNCTION, delay, iterationCount, direction, fillMode, playState, } = props;
    return {
        animation: {
            name,
            duration,
            timingFunction,
            delay,
            iterationCount,
            direction,
            fillMode,
            playState,
        },
    };
}
