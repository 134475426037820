export const UPDATE_ADD_TO_CART_NOTIFICATION = 'UPDATE_ADD_TO_CART_NOTIFICATION';
export function updateAddToCartNotification(resetNotification, addToCartNotification) {
    return (dispatch) => {
        const updateCartNotification = {
            type: UPDATE_ADD_TO_CART_NOTIFICATION,
            displayName: !resetNotification && addToCartNotification.displayName,
            imageUrl: !resetNotification && addToCartNotification.imageUrl,
            price: !resetNotification && addToCartNotification.price,
            size: !resetNotification && addToCartNotification.size,
            color: !resetNotification && addToCartNotification.color,
            scent: !resetNotification && addToCartNotification.scent,
            flavor: !resetNotification && addToCartNotification.flavor,
            hasRedPrice: !resetNotification && addToCartNotification.hasRedPrice,
            recommendations: !resetNotification && addToCartNotification.recommendations,
        };
        dispatch(updateCartNotification);
    };
}
