import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@glitz/react';
import { PlainButton } from 'Shared/Button';
import * as style from 'Shared/Style';
import ErrorBoundary from 'Shared/ErrorBoundary';
import Overlay from 'Shared/Overlay';
import { animated, useSpring } from 'react-spring';
import { greyNeutral, mediaMinMedium, white } from 'Shared/Style';
import { Close } from 'Shared/Icon';
import { Position } from 'Shared/Flyout';
import { fadeInAnimation } from 'Shared/fadeInAnimation';
import { media } from '@glitz/core';
const TRANSITION_DURATION = 300;
export const FLYOUT_TRANSITION_DONE_DURATION = TRANSITION_DURATION + 100;
export default function MainMenuFlyoutModal(props) {
    const [rootElement, setRootElement] = useState();
    const position = props.position;
    const Base = position === Position.Left
        ? props.isFullscreen
            ? FullscreenLeft
            : Left
        : props.isFullscreen
            ? FullscreenRight
            : Right;
    const getClosedTransform = useCallback(() => {
        return position === Position.Left ? -100 : 100;
    }, [position]);
    const [translateXState, setTranslateXState] = useState(getClosedTransform());
    useEffect(() => {
        const translateX = props.isOpen ? 0 : getClosedTransform();
        setTranslateXState(translateX);
    }, [getClosedTransform, props.isOpen, position]);
    const horizontalAnimation = useSpring({
        to: { translateX: translateXState + '%' },
        config: { duration: TRANSITION_DURATION },
    });
    function onClose() {
        setTranslateXState(100);
        props.onClose();
    }
    useEffect(() => {
        const element = document.getElementById('content-container');
        if (element) {
            setRootElement(element);
        }
    }, []);
    return rootElement
        ? ReactDOM.createPortal(React.createElement(Overlay, { enabled: props.isOpen, onClose: onClose },
            React.createElement(Base, { style: horizontalAnimation },
                React.createElement(CloseButton, { onClick: props.onClose },
                    React.createElement(StyledClose, null)),
                React.createElement(InnerContainer, null,
                    React.createElement(ErrorBoundary, { reloadCurrentPage: true }, props.children)))), rootElement)
        : null;
}
const styledFlyout = styled(Object.assign({ position: 'fixed', top: 0, width: 570, maxWidth: `calc(100% - ${60}px)`, height: '100%', backgroundColor: greyNeutral, willChange: 'transform' }, style.depth()));
const Left = animated(styledFlyout(styled.Div, { left: 0 }));
const Right = animated(styledFlyout(styled.Div, { right: 0 }));
const FullscreenLeft = animated(styledFlyout(styled.Div, { left: 0, width: '100%', maxWidth: 'initial' }));
const FullscreenRight = animated(styledFlyout(styled.Div, { right: 0, width: '100%', maxWidth: 'initial' }));
const StyledClose = styled(Close, {
    color: white,
    width: 25,
    height: 25,
});
const InnerContainer = styled.div({
    position: 'relative',
    height: '100%',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
});
const CloseButton = styled(PlainButton, Object.assign(Object.assign({ position: 'absolute', color: style.white, top: 20, right: -42 }, fadeInAnimation), media(mediaMinMedium, {
    right: 0,
    left: -42,
})));
