import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
export default styled(function Item(_a) {
    var restProps = __rest(_a, []);
    return (React.createElement(LinkStyled, { to: restProps.url },
        React.createElement(Label, null, isItem(restProps) ? restProps.name : restProps.children)));
});
function isItem(props) {
    return 'name' in props;
}
const LinkStyled = styled(Link, {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    ':hover': {
        color: 'inherit',
        textDecoration: 'inherit',
    },
});
const Label = styled.span({
    display: 'block',
});
