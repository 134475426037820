import { URLX, currentUrl } from '@avensia/nitro5-scope';
function isCurrentCategory(item) {
    const current = currentUrl();
    const itemUrl = new URLX(item.url);
    return current.pathname.startsWith(itemUrl.pathname);
}
export function isCategoryActive(item, mainMenu) {
    return ((isCurrentCategory(item) && !mainMenu.mainMenuOpen) ||
        (mainMenu.mainMenuOpen && mainMenu.openMenuItem && item.url === mainMenu.openMenuItem.url));
}
