import { __awaiter } from "tslib";
import React, { lazy, Suspense, useEffect } from 'react';
import { styled, applyClassName } from '@glitz/react';
import { Root, CurrentPage, isBrowser } from '@avensia/nitro5-scope';
import connect from 'Shared/connect';
import MainHeader, { useHeaderHeight } from './Header';
import MainFooter from './Footer';
import Loader from './Loader';
import NetworkError from './NetworkError';
import Tray from './Tray';
import { SnackbarProvider, SnackbarContainer } from './Tray/Snackbar';
import { ActionbarProvider, ActionbarContainer } from './Tray/Actionbar';
import { openMainMenu, closeMainMenu } from './MainMenu/action-creators';
import { toggleMiniCart } from './MiniCart/action-creators';
import { showCountrySelector, toggleCountrySelector } from './CountrySelector/action-creators';
import { useSelector } from 'Shared/State';
import miniCartIsDisabled from './MiniCart/is-disabled-selector';
import { useCompact, getScrollbarWidth, useMobile } from 'Shared/Viewport';
import SystemText from 'Shared/SystemText';
import ErrorBoundary from 'Shared/ErrorBoundary';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
import CookieMessage from './CookieMessage';
import AccountPanel from 'Account';
import { toggleQuickSearch, hideQuickSearch } from 'Search/action-creators';
import currentPageIsSearch from 'Search/current-page-is-search';
import SiteBanner from './Sitebanner';
import CartNotification from './AddToCartNotification/AddToCartNotification';
import UnsupportedBrowserNotification from './UnsupportedBrowserNotification';
import { isUnsupportedBrowser } from 'Shared/supports';
import { useBrowser } from 'Shared/Browser';
import ZendeskChat from './Zendesk';
import { showNewsletterPopup } from './Newsletter/action-creators';
import { toggleNewsletterPopup } from './Newsletter/action-creators';
import currentPageIsProduct from 'Product/current-page-is-product';
const MiniCart = lazy(() => import('./MiniCart'));
const Newsletter = lazy(() => import('./Newsletter'));
const CountrySelector = lazy(() => import('./CountrySelector'));
var Order;
(function (Order) {
    Order[Order["Header"] = 0] = "Header";
    Order[Order["Banner"] = 1] = "Banner";
    Order[Order["Content"] = 2] = "Content";
    Order[Order["Footer"] = 3] = "Footer";
})(Order || (Order = {}));
function SiteLayout(props) {
    renderMeta(props.currentPage);
    getScrollbarWidth();
    const isCompact = useCompact();
    const isCheckoutPage = currentPageIsCheckout(props.currentPage);
    const isSearchPage = currentPageIsSearch(props.currentPage);
    const headerHeight = useHeaderHeight();
    const browser = useBrowser();
    const isMobile = useMobile();
    const textRightToLeft = props.currentLanguage === 'ar-AE';
    const isProductPage = currentPageIsProduct(props.currentPage);
    const showChatButton = isMobile && (isProductPage || isCheckoutPage) ? false : true;
    const isRegisterNormalLink = window.location.href.includes('?login=register');
    const isRegisterStoreLink = window.location.href.includes('?login=store_register');
    const isRegisterLink = isRegisterNormalLink || isRegisterStoreLink;
    const { flyoutDelayTime } = useSelector(state => state.appShellData);
    useEffect(() => {
        function checkCountrySelector() {
            return __awaiter(this, void 0, void 0, function* () {
                if (props.enableCountrySelectorPopup && !isRegisterLink) {
                    const showFlyout = yield showCountrySelector();
                    if (showFlyout) {
                        props.toggleCountrySelector();
                    }
                }
            });
        }
        function checkNewsletterPopup() {
            return __awaiter(this, void 0, void 0, function* () {
                if (props.enableNewsletterPopup && !isRegisterLink) {
                    const showFlyout = yield showNewsletterPopup(props.enableCountrySelectorPopup);
                    if (showFlyout) {
                        setTimeout(() => {
                            props.toggleNewsletterPopup();
                        }, flyoutDelayTime * 1000);
                    }
                }
            });
        }
        checkCountrySelector();
        checkNewsletterPopup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(ErrorBoundary, null,
        React.createElement(ActionbarProvider, null,
            React.createElement(SnackbarProvider, null,
                React.createElement(Base, { id: "siteLayout-container", css: textRightToLeft && { direction: 'rtl' } },
                    isUnsupportedBrowser(browser) && React.createElement(UnsupportedBrowserNotification, null),
                    React.createElement(Content, { id: "content-container", css: {
                            order: Order.Content,
                            minHeight: `calc(100vh - ${headerHeight}px)`,
                        } },
                        React.createElement(CurrentPage, null)),
                    React.createElement(MainHeader, { order: Order.Header, mainMenu: props.mainMenu, openMainMenu: props.openMainMenu, closeMainMenu: props.closeMainMenu, toggleMiniCart: props.toggleMiniCart, miniCartIsDisabled: props.miniCartIsDisabled, toggleCountrySelector: props.toggleCountrySelector, hideMenuItems: isCheckoutPage, toggleQuickSearch: props.toggleQuickSearch, hideQuickSearch: props.hideQuickSearch, quickSearch: props.quickSearch, isSearchPage: isSearchPage }),
                    (!isCheckoutPage || !isCompact) && React.createElement(MainFooter, { order: Order.Footer }),
                    React.createElement(SiteBanner, { css: { order: Order.Banner } }),
                    React.createElement(SystemText, null, props.systemText.text),
                    React.createElement(CartNotification, { displayName: props.addToCartNotification.displayName, imageUrl: props.addToCartNotification.imageUrl, price: props.addToCartNotification.price, size: props.addToCartNotification.size, color: props.addToCartNotification.color, scent: props.addToCartNotification.scent, flavor: props.addToCartNotification.flavor, hasRedPrice: props.addToCartNotification.hasRedPrice, recommendations: props.addToCartNotification.recommendations }),
                    props.miniCartIsOpen && (React.createElement(Suspense, { fallback: null },
                        React.createElement(MiniCart, { toggleMiniCart: props.toggleMiniCart, disabled: props.miniCartIsDisabled, isOpen: !props.miniCartIsDisabled && props.miniCartIsOpen }))),
                    props.countrySelectorIsOpen && (React.createElement(Suspense, { fallback: null },
                        React.createElement(CountrySelector, null))),
                    props.newsletter && props.newsletterPopupIsOpen && (React.createElement(Suspense, { fallback: null },
                        React.createElement(Newsletter, null))),
                    React.createElement(Loader, null),
                    React.createElement(NetworkError, { reload: props.currentPage.reload, loadFailure: props.currentPage.loadFailure }),
                    React.createElement(Tray, null,
                        React.createElement(SnackbarContainer, null),
                        React.createElement(ActionbarContainer, null)),
                    props.currentPage.jsonLd &&
                        !!props.currentPage.jsonLd.length &&
                        props.currentPage.jsonLd.map((jsonLd, i) => (React.createElement("script", { key: i, dangerouslySetInnerHTML: {
                                __html: JSON.stringify(jsonLd),
                            }, type: "application/ld+json" }))),
                    React.createElement(CookieMessage, null),
                    showChatButton && React.createElement(ZendeskChat, null),
                    React.createElement(AccountPanel, null))))));
}
export default connect((state) => {
    return {
        currentPage: state.currentPage,
        mainMenu: state.mainMenu,
        miniCartIsOpen: state.miniCart.isOpen,
        miniCartIsDisabled: miniCartIsDisabled(state),
        systemText: state.systemText,
        currentLanguage: state.appShellData.culture,
        countrySelectorIsOpen: state.countrySelector.isOpen,
        enableCountrySelectorPopup: state.appShellData.enableCountrySelectorPopup,
        quickSearch: state.quickSearch,
        addToCartNotification: state.addToCartNotification,
        enableNewsletterPopup: state.appShellData.enableNewsletterPopup,
        newsletter: state.appShellData.newsletterBlock,
        newsletterPopupIsOpen: state.newsletterPopup.isOpen,
    };
}, (dispatch) => {
    return {
        openMainMenu: (e, menuItem) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            dispatch(openMainMenu(menuItem));
        },
        closeMainMenu: (e) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            dispatch(closeMainMenu());
        },
        toggleMiniCart: (e) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            dispatch(toggleMiniCart());
        },
        toggleCountrySelector: (e) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            dispatch(toggleCountrySelector());
        },
        toggleNewsletterPopup() {
            return dispatch(toggleNewsletterPopup());
        },
        toggleQuickSearch: (e) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            dispatch(toggleQuickSearch());
        },
        hideQuickSearch: (e) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            dispatch(hideQuickSearch());
        },
    };
})(React.memo(SiteLayout));
const Base = styled(applyClassName(Root), {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
});
const Content = styled.div({
    display: 'flex',
    flexGrow: 1,
});
function renderMeta(currentPage) {
    if (isBrowser()) {
        const canonicalResource = currentPage;
        let canonical = document.getElementById('link-canonical');
        if (isCanonicalResource(canonicalResource)) {
            if (!canonical) {
                canonical = document.createElement('link');
                canonical.rel = 'canonical';
                canonical.id = 'link-canonical';
                document.head.appendChild(canonical);
            }
            console.debug('Updating canonical to', canonicalResource.canonicalUrl);
            canonical.href = canonicalResource.canonicalUrl;
        }
        else if (canonical) {
            console.debug('Removing canonical');
            document.head.removeChild(canonical);
        }
        // This title formatting also exists in _Layout.cshtml
        const newTitle = ((currentPage.meta && currentPage.meta.title) || '') + ' | ' + formatHost(location.host);
        if (document.title === newTitle) {
            return;
        }
        document.title = newTitle;
        Array.from(document.querySelectorAll('meta[data-dynamic]')).forEach(node => {
            node.parentElement.removeChild(node);
        });
        Object.keys((currentPage.meta && currentPage.meta.elements) || {}).forEach(name => {
            const metaElement = document.createElement('meta');
            metaElement.setAttribute('data-dynamic', '1');
            metaElement.setAttribute(currentPage.meta.elements[name].type, name);
            metaElement.setAttribute('content', currentPage.meta.elements[name].value);
            document.head.appendChild(metaElement);
        });
    }
}
function formatHost(host) {
    const parts = host.split('.');
    if (parts.length !== 3) {
        return host;
    }
    return parts[1].substring(0, 1).toUpperCase() + parts[1].substring(1) + '.' + parts[2];
}
function isCanonicalResource(page) {
    return page && 'canonicalUrl' in page;
}
