import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { truncate } from 'Shared/Style';
import { PlainButton } from 'Shared/Button';
import { ChevronRight } from 'Shared/Icon/indiska';
export default styled(function MenuItem(props) {
    function onClick(e) {
        if (isParent(props)) {
            e.preventDefault();
            props.pushLevel(props.item);
        }
    }
    const { url, name } = props.item;
    return isParent(props) ? (React.createElement(Parent, { to: url, onClick: onClick },
        React.createElement(Label, null, name),
        React.createElement(ChevronRight, null))) : (React.createElement(Base, { to: url, onClick: onClick },
        React.createElement(Label, null, name)));
});
function isParent(props) {
    var _a, _b;
    return props.pushLevel && ((_b = (_a = props.item) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.length) > 0;
}
export const SimpleItem = styled((_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    return (React.createElement(Base, Object.assign({}, restProps),
        React.createElement(Label, null, children)));
});
const Base = styled(PlainButton, { justifyContent: undefined, width: '100%' });
const Parent = styled(Base, {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
});
const Label = styled.span(Object.assign({ display: 'block' }, truncate()));
