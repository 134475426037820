import React from 'react';
import { styled } from '@glitz/react';
import { Theme, SmallButton } from 'Shared/Button';
import { transition } from 'Shared/Style';
const Suggestions = styled((props) => {
    const display = props.open && props.items.length > 0;
    return (React.createElement(SuggestionContainer, { css: { opacity: display ? 1 : 0, pointerEvents: display ? 'auto' : 'none' } }, props.items.map(item => {
        return (React.createElement(Item, { as: Theme.LightGray, key: item, onClick: () => props.applySuggestion(item) }, item));
    })));
}, transition({ property: 'opacity' }));
export default Suggestions;
const SuggestionContainer = styled.div({
    display: 'flex',
    marginTop: 20,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
});
const Item = styled(SmallButton, {
    marginRight: 10,
    fontSize: 14,
    borderRadius: 100,
    letterSpacing: 'normal',
    textTransform: 'lowercase',
    ':first-letter': {
        textTransform: 'capitalize',
    },
    whiteSpace: 'nowrap',
});
