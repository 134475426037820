import { animation } from './Style';
const DEFAULT_DURATION = 500;
const DEFAULT_TIMING_FUNCTION = 'ease-in-out';
export const fadeInAnimation = Object.assign({}, animation({
    name: {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    duration: DEFAULT_DURATION,
    timingFunction: DEFAULT_TIMING_FUNCTION,
    iterationCount: 1,
}));
export function createFadeInAnimation({ duration, timingFunction }) {
    return Object.assign({}, animation({
        name: {
            '0%': {
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        },
        duration: duration ? duration : DEFAULT_DURATION,
        timingFunction: timingFunction ? timingFunction : DEFAULT_TIMING_FUNCTION,
        iterationCount: 1,
    }));
}
