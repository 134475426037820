import React from 'react';
import { styled } from '@glitz/react';
import Item from './Item';
import { LinkItem } from './LinkItem';
import { itemKey } from './item-helpers';
import { Ul } from 'Shared/Generic';
import * as style from 'Shared/Style';
export default function Level({ data }) {
    const items = isRoot(data) ? data : data.items || [];
    if (!isRoot(data) && isLinkItem(data) && data.imageUrl) {
        return React.createElement(LinkItem, { item: data });
    }
    else {
        return (React.createElement(Base, null,
            React.createElement(Ul, { column: true },
                !isRoot(data) && React.createElement(Parent, { url: data.url }, data.name),
                items.map(item => (React.createElement(Li, { key: itemKey(item) },
                    React.createElement(Item, Object.assign({}, item))))))));
    }
}
export function isRoot(data) {
    return Array.isArray(data);
}
export function isLinkItem(item) {
    return !item.showAllLink && !item.items.length;
}
const Base = styled.li(Object.assign({ gridColumn: {
        start: 'span 2',
    }, breakInside: 'avoid', listStyleType: 'none', textAlign: 'start' }, style.batonTurboCaps(12)));
const Parent = styled(Item, {
    marginBottom: 15,
    minHeight: 20,
    lineHeight: 1.67,
    color: style.black,
});
const Li = styled.li({
    marginBottom: 10,
    lineHeight: 1.67,
    color: style.textMainMenuItem,
    ':hover': {
        color: style.black,
        textDecoration: 'none',
    },
});
