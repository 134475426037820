import { useEffect, useReducer } from 'react';
import { onDataUpdate, resolveDataSync, resolveData, resolveComponentAndChildComponents, allComponentsAreResolved, } from '@avensia/nitro5-scope';
import { useState } from 'react';
export function useDynamicData(property, shouldResolveData = true) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const data = property ? resolveDataSync(property) : null;
    const [componentsAreLoaded, setComponentsAreLoaded] = useState(data ? allComponentsAreResolved(data) : false);
    useEffect(() => {
        if (shouldResolveData && property && !data) {
            resolveData(property).then(resolvedData => {
                if (!componentsAreLoaded) {
                    resolveComponentAndChildComponents(resolvedData).then(() => setComponentsAreLoaded(true));
                }
            });
        }
    }, [shouldResolveData, property, data, componentsAreLoaded]);
    useEffect(() => {
        if (!property) {
            return;
        }
        const unbindOnDataUpate = onDataUpdate(property, () => {
            forceUpdate();
        });
        return unbindOnDataUpate;
    }, [property]);
    return componentsAreLoaded ? data : null;
}
