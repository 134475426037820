import React from 'react';
import { styled, applyClassName } from '@glitz/react';
import * as style from '../Style';
export const iconStyled = styled({
    display: 'inline-flex',
    width: '1em',
    height: '1em',
    fontSize: style.pixelsToUnit(style.defaultLineHeight, style.Unit.Em),
});
export default function factory(Glyph) {
    return iconStyled(applyClassName((props) => React.createElement(Glyph, Object.assign({ width: "1em", height: "1em" }, props))));
}
export function unstyledFactory(Glyph) {
    return applyClassName((props) => React.createElement(Glyph, Object.assign({}, props)));
}
