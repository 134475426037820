import { componentNames } from 'Shared/component-registry';
export default (currentPage) => {
    return componentIsMyPages(currentPage.componentName);
};
export function componentIsMyPages(component) {
    return [
        componentNames.Avensia_Common_Features_Account_MyPages_MyOverview_MyOverviewPageViewModel,
        componentNames.Avensia_Common_Features_Account_MyPages_MyDetails_MyDetailsPageViewModel,
    ].includes(component);
}
