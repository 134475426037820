import { styled } from '@glitz/react';
import { delta, lineHeight, calculateLineHeightInPixels } from './typography';
// Used to make sure some elements are styled the same, e.g. buttons and fields
export var General;
(function (General) {
    General[General["Default"] = 0] = "Default";
    General[General["Small"] = 1] = "Small";
    General[General["Medium"] = 2] = "Medium";
    General[General["Large"] = 3] = "Large";
})(General || (General = {}));
function createGeneral(fontSizeMobile, fontSizeDesktop, 
/** Padding left and right */
x, 
/** Padding top and bottom */
y, height) {
    return {
        height: height || calculateLineHeightInPixels(fontSizeDesktop) + y * 2,
        padding: { x, y },
        fontSize: theme => (theme.isCompact ? fontSizeMobile : fontSizeDesktop),
    };
}
const generals = {
    [General.Small]: createGeneral(14, 11, 15, 0, 30),
    [General.Default]: createGeneral(11, 13, 30, 15, 50),
    [General.Large]: createGeneral(delta, delta, 40, 18, 50),
    [General.Medium]: createGeneral(11, 13, 25, 15, 40),
};
const generalResetStyled = styled({ lineHeight });
export function general(type = General.Default) {
    return generals[type];
}
export function createGeneralDecorator(type = General.Default, singleLine = true) {
    const decorator = styled(generalResetStyled, general(type));
    if (singleLine) {
        return decorator;
    }
    else {
        // Remove `height` to enable multiple rows
        return styled(decorator, { height: undefined });
    }
}
