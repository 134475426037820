import { Breakpoint } from '@avensia/nitro5-scope';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import { mediaMinSmall } from '.';
export const pageNarrowBreakpoint = Breakpoint.Small;
export const pageNormalBreakpoint = Breakpoint.Huge;
export const pageWideBreakpoint = Breakpoint.Huge;
export const GRID_SPACE = 80;
export const breakoutBlock = styled(Object.assign({}, media(mediaMinSmall, {
    width: '100vw',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: ({ isTablet }) => (isTablet ? 566 : 800),
    padding: { x: 40 },
    boxSizing: 'content-box',
})));
export const fullViewportWidthBlock = {
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    maxWidth: 'unset',
};
