import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import * as style from 'Shared/Style';
import { UnderlinedField } from 'Shared/Fields/Text';
export const H1 = styled.h1(Object.assign(Object.assign({ margin: {
        x: 'auto',
        top: 40,
        bottom: 30,
    }, font: {
        family: style.fontFamilies.ptSerif,
        size: 26,
        weight: 'normal',
    }, lineHeight: 1.38, textAlign: 'center' }, media(style.mediaMinSmall, {
    margin: { bottom: 45 },
    fontSize: 40,
    lineHeight: 1.23,
})), media(style.mediaMinMedium, {
    margin: { y: 60 },
    fontSize: 40,
    lineHeight: 1.25,
})));
export const H2 = styled.h2(Object.assign(Object.assign({ margin: {
        x: 'auto',
        bottom: ({ isCompact }) => (isCompact ? 20 : style.padding.desktop),
    }, fontWeight: 'normal' }, style.batonTurboCaps(({ isCompact }) => (isCompact ? 11 : 13))), { textAlign: 'center' }));
export const H2LikeStyle = styled.div(Object.assign(Object.assign({ fontFamily: style.fontFamilies.ptSerif, fontSize: 26, lineHeight: 1.38, margin: {
        top: 0,
        bottom: 10,
    }, textAlign: 'center' }, media(style.mediaMinSmall, {
    lineHeight: 1.23,
})), media(style.mediaMinMedium, {
    fontSize: 30,
    lineHeight: 1.33,
    margin: {
        top: 0,
        bottom: 15,
    },
})));
export const Field = styled(UnderlinedField, {
    margin: { bottom: 20 },
    color: style.black,
});
export const Form = styled.form({
    padding: {
        x: ({ isCompact }) => (isCompact ? 20 : style.padding.desktop),
        y: ({ isCompact }) => (isCompact ? 30 : style.padding.desktop),
    },
    backgroundColor: style.offWhite,
    borderRadius: 10,
});
