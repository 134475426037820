// Border
export const thin = 1;
export const thick = 2;
// Margin & padding
export const tiny = 4;
export const small = 8;
export const medium = 15;
export const large = 20;
export const huge = 32;
export const gigantic = 40;
export const humongous = 48;
export const tera = 60;
export const moderate = large; // This is default spacing, e.g. margin between `<p>`
