import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@glitz/react';
import { Motion, spring } from 'react-motion';
import { ZIndex, thick, depth, black } from 'Shared/Style';
import { useSelector } from 'Shared/State';
const FAKE_PROGRESS_MAX = 0.25;
const FAKE_PROGRESS_FACTOR = 0.5;
const FAKE_PROGRESS_INTERVAL = 200;
export default function Loader() {
    const progress = useSelector(state => state.spinner.progress);
    const isVisible = useSelector(state => state.spinner.isVisible);
    const previousProgress = useRef(progress);
    const [showProgress, setShowProgress] = useState(0);
    if (progress !== previousProgress.current) {
        setShowProgress(progress);
    }
    previousProgress.current = progress;
    useEffect(() => {
        // Fake progress when a step takes longer than expected
        if (progress < 1) {
            let fakeProgress = 0;
            const timeout = setInterval(() => {
                fakeProgress = FAKE_PROGRESS_MAX - (FAKE_PROGRESS_MAX - fakeProgress) * FAKE_PROGRESS_FACTOR;
                setShowProgress(progress + fakeProgress);
            }, FAKE_PROGRESS_INTERVAL);
            return () => clearInterval(timeout);
        }
    }, [progress]);
    function getStyles() {
        return { opacity: spring(isVisible ? 1 : 0), progress: showProgress > 0 ? spring(showProgress) : 0 };
    }
    return (React.createElement(Motion, { style: getStyles() }, ({ opacity, progress }) => opacity > 0 ? React.createElement(Progress, { style: { opacity, transform: `scaleX(${progress})` } }) : null));
}
const Progress = styled.div(Object.assign({ position: 'fixed', zIndex: ZIndex.Spinner, backgroundColor: black, top: 0, left: 0, width: '100%', height: thick, pointerEvents: 'none', transformOrigin: 'left top', willChange: 'transform, opacity' }, depth()));
