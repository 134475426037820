import React from 'react';
import { styled } from '@glitz/react';
import { formatPrice, getFormatForCurrency } from 'Shared/format-price';
import { useSelector } from 'Shared/State';
import { negative, textDarkGray, textGray } from 'Shared/Style';
import { replaceStringWithElement } from 'Shared/replace-string-with-element';
export default styled(function Price(props) {
    var _a;
    const { showOriginal, horizontal, current, original, microData, explicitCurrency, currencyFontSize, hasRedPrice } = props;
    let { currency } = useSelector(state => state.appShellData);
    if (explicitCurrency) {
        currency = explicitCurrency;
    }
    const baseProps = microData
        ? {
            itemProp: 'offers',
            itemType: 'http://schema.org/Offer',
            itemScope: true,
        }
        : {};
    const priceProps = microData
        ? {
            itemProp: 'price',
            content: String(current),
        }
        : {};
    const isDiscounted = original && original > current;
    const priceStyle = {
        color: isDiscounted && hasRedPrice ? negative : 'inherit',
        fontWeight: 'normal',
        direction: 'ltr',
    };
    const formattedPrice = currencyFontSize
        ? replaceStringWithElement(formatPrice(current, currency), (_a = getFormatForCurrency(currency, current)) === null || _a === void 0 ? void 0 : _a.symbol, (match, i) => (React.createElement(styled.Span, { key: i, css: { fontSize: currencyFontSize } }, match)))
        : formatPrice(current, currency);
    return (React.createElement(styled.Span, Object.assign({}, baseProps, { css: horizontal ? horizontalStyles : verticalStyles }),
        microData && React.createElement("meta", { itemProp: "priceCurrency", content: currency }),
        React.createElement(styled.Span, Object.assign({ css: Object.assign(Object.assign({}, priceStyle), { whiteSpace: 'nowrap' }) }, priceProps), formattedPrice),
        showOriginal && !!original && current !== original && (React.createElement(React.Fragment, null,
            React.createElement(Original, { css: Object.assign({ whiteSpace: 'nowrap' }, (horizontal && {
                    fontSize: currencyFontSize,
                    marginLeft: '0.5em',
                    marginRight: 0,
                })) }, formatPrice(original, currency))))));
}, {
    display: 'inline-flex',
    color: textDarkGray,
});
const Original = styled.span({
    color: textGray,
    textDecoration: 'line-through',
    direction: 'ltr',
});
const horizontalStyles = { alignItems: 'baseline' };
const verticalStyles = {
    flexDirection: 'column',
    alignItems: 'flex-end',
};
