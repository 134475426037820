export const ROOT_KEY = '/';
export function itemKey(item) {
    return item.url;
}
export function findItem(items, key) {
    var _a;
    for (const item of items) {
        if (itemKey(item) === key) {
            return item;
        }
        if (((_a = item === null || item === void 0 ? void 0 : item.items) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            const foundItem = findItem(item.items, key);
            if (foundItem) {
                return foundItem;
            }
        }
    }
}
export function findParentKey(items, visibleKey, parentKey = ROOT_KEY) {
    var _a;
    for (const item of items) {
        const currentKey = itemKey(item);
        if (currentKey === visibleKey) {
            return parentKey;
        }
        if (((_a = item === null || item === void 0 ? void 0 : item.items) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            const key = findParentKey(item.items, visibleKey, currentKey);
            if (key) {
                return key;
            }
        }
    }
    return null;
}
