import { componentNames } from 'Shared/component-registry';
export default (currentPage) => {
    return componentIsStores(currentPage.componentName);
};
export function componentIsStores(component) {
    return [
        componentNames.Avensia_Common_Features_Stores_StoreListPageViewModel,
        componentNames.Avensia_Common_Features_Stores_StorePageViewModel,
    ].includes(component);
}
