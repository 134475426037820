import React from 'react';
import { styled } from '@glitz/react';
import { Breakpoint } from '@avensia/nitro5-scope';
import connect from '../connect';
import { pixelsToUnit, GRID_SPACE } from '../Style';
import { BoxLayoutProvider } from './BoxLayoutContext';
const SPACING_DEFAULT = GRID_SPACE;
const fractions = {
    '1:1': 1,
    '1:2': 1 / 2,
    '1:3': 1 / 3,
    '1:4': 1 / 4,
    '2:3': 2 / 3,
    '3:4': 3 / 4,
};
export function getFraction(layout) {
    return fractions[layout] || 1;
}
function spacingDefaulter(value) {
    return value === true ? pixelsToUnit(SPACING_DEFAULT) : value;
}
function isThin(fraction) {
    return fraction <= 1 / 3;
}
function convert(row, children, currentBreakpoint) {
    if (currentBreakpoint > Breakpoint.Small) {
        return row.map(entry => {
            if (typeof entry === 'string') {
                return {
                    fraction: fractions[entry],
                    layout: entry,
                    element: children.shift(),
                };
            }
            return {
                fraction: fractions[entry[0]],
                columns: entry[1].map(innerEntry => ({
                    fraction: fractions[innerEntry],
                    layout: null,
                    element: children.shift(),
                })),
            };
        });
    }
    // Flatten all blocks to full or half width
    if (currentBreakpoint > Breakpoint.Small) {
        const entities = row.reduce((current, item) => {
            if (typeof item === 'string') {
                return [
                    ...current,
                    {
                        fraction: isThin(fractions[item]) ? 0.5 : 1,
                        layout: item,
                        element: children.shift(),
                    },
                ];
            }
            const value = isThin(fractions[item[0]]) ? 0.5 : 1;
            return [
                ...current,
                ...item[1].map(() => ({
                    fraction: value,
                    layout: null,
                    element: children.shift(),
                })),
            ];
        }, []);
        return entities;
    }
    // Flatten all blocks to full width
    const entities = row.reduce((current, entry) => typeof entry === 'string'
        ? [
            ...current,
            {
                fraction: 1,
                layout: null,
                element: children.shift(),
            },
        ]
        : [
            ...current,
            ...entry[1].map(() => ({
                fraction: 1,
                layout: null,
                element: children.shift(),
            })),
        ], []);
    return entities;
}
function isColumn(entity) {
    return 'columns' in entity;
}
function wrap(spacingBetweenBlocks, verticalSpacing) {
    const spacing = spacingDefaulter(spacingBetweenBlocks);
    return (entity, index) => {
        return isColumn(entity) ? (React.createElement(styled.Div, { key: index, css: {
                display: 'flex',
                flexDirection: 'column',
                flexBasis: `${entity.fraction * 100}%`,
                flexGrow: 1,
                minWidth: 0,
            } }, entity.columns.map(wrap(spacingBetweenBlocks, verticalSpacing)))) : (React.createElement(BoxLayoutProvider, { key: index, layout: entity.layout, spacing: spacing ? parseInt(spacing) : 0 },
            React.createElement(styled.Div, { css: Object.assign(Object.assign({}, (spacing && {
                    marginLeft: spacing,
                })), { flexBasis: spacing ? `calc(${entity.fraction * 100}% - ${spacing})` : `${entity.fraction * 100}%`, flexGrow: 1, minWidth: 0, 
                    // Apply vertical spacing to all but the first row
                    marginTop: index !== 0 ? verticalSpacing : undefined }) }, entity.element)));
    };
}
class BoxLayout extends React.Component {
    render() {
        const children = React.Children.toArray(this.props.children);
        const entities = convert(this.props.layout, children, this.props.currentBreakpoint);
        const horizontalSpacing = spacingDefaulter(this.props.spacingBetweenBlocks);
        // Only apply desktop vertical spacing if layout is multi-row
        const layoutHasMultipleRows = Array.isArray(this.props.layout[0]);
        const verticalSpacingDesktop = layoutHasMultipleRows ? this.props.verticalSpacingDesktop : undefined;
        const verticalSpacing = this.props.currentBreakpoint <= Breakpoint.Small ? this.props.verticalSpacingMobile : verticalSpacingDesktop;
        return (React.createElement(styled.Div, { css: Object.assign(Object.assign({ display: 'flex', flexWrap: 'wrap' }, (horizontalSpacing && {
                marginLeft: `-${horizontalSpacing}`,
            })), (this.props.currentBreakpoint > Breakpoint.Small && {
                flexWrap: 'nowrap',
            })) }, entities.map(wrap(this.props.spacingBetweenBlocks, verticalSpacing))));
    }
}
export default styled(connect((state) => ({
    currentBreakpoint: state.currentBreakpoint,
}))(BoxLayout));
