import { TOGGLE_STORE_STOCK } from './action-creators';
import { PAGE_LOAD } from '@avensia/nitro5-scope';
export default function storeStockReducer(state = { isOpen: false }, action) {
    switch (action.type) {
        case TOGGLE_STORE_STOCK:
            const { product } = action;
            return Object.assign(Object.assign({}, state), { isOpen: !state.isOpen, product: product || state.product });
        case PAGE_LOAD: {
            if (state.isOpen) {
                return Object.assign(Object.assign({}, state), { isOpen: false });
            }
            else {
                return state;
            }
        }
        default:
            return state;
    }
}
