import { __rest } from "tslib";
import React, { useMemo } from 'react';
import { forwardStyle, styled, ThemeProvider } from '@glitz/react';
import { white, defaultTextColor, linkColor, black, offWhite, textDarkGray, textGray, greyNeutral, hoverGrey, textOffWhite, textBrown, selectShade, brownRed, } from './Style';
import { useDirectionRtl, useIsCompact, useIsMobile, useIsTablet } from './use-viewport';
export var Theme;
(function (Theme) {
    Theme[Theme["White"] = 0] = "White";
    Theme[Theme["Gray"] = 1] = "Gray";
    Theme[Theme["Beige"] = 2] = "Beige";
    Theme[Theme["Dark"] = 3] = "Dark";
    Theme[Theme["Error"] = 4] = "Error";
    Theme[Theme["FlowBox"] = 5] = "FlowBox";
    Theme[Theme["LightGray"] = 6] = "LightGray";
})(Theme || (Theme = {}));
function themeFactory(colorTheme) {
    return function Theme({ children }) {
        const isMobile = useIsMobile();
        const isCompact = useIsCompact();
        const isTablet = useIsTablet();
        const directionRtl = useDirectionRtl();
        const theme = useMemo(() => (Object.assign(Object.assign({}, colorTheme), { isMobile, isCompact, isTablet, directionRtl })), [isCompact, isTablet, isMobile, directionRtl]);
        return React.createElement(ThemeProvider, { theme: theme }, children);
    };
}
const whiteTheme = {
    type: Theme.White,
    mainBackgroundColor: white,
    secondaryBackgroundColor: offWhite,
    mainTextColor: black,
    linkColor: black,
    linkHoverColor: textGray,
    lineColor: textOffWhite,
    closeButtonColor: textGray,
    buttonTextHoverColor: black,
};
const grayTheme = {
    type: Theme.Gray,
    mainBackgroundColor: offWhite,
    secondaryBackgroundColor: textOffWhite,
    mainTextColor: textDarkGray,
    linkColor,
    linkHoverColor: black,
    lineColor: hoverGrey,
};
const beigeTheme = {
    type: Theme.Beige,
    mainBackgroundColor: greyNeutral,
    secondaryBackgroundColor: selectShade,
    mainTextColor: defaultTextColor,
    linkColor: textBrown,
    linkHoverColor: black,
    lineColor: hoverGrey,
    closeButtonColor: black,
    buttonTextHoverColor: textBrown,
};
const darkTheme = {
    type: Theme.Dark,
    mainBackgroundColor: black,
    secondaryBackgroundColor: textOffWhite,
    mainTextColor: white,
    linkColor: white,
    linkHoverColor: textGray,
    lineColor: hoverGrey,
};
const cmsTheme = {
    type: Theme.White,
    mainBackgroundColor: white,
    secondaryBackgroundColor: offWhite,
    mainTextColor: textDarkGray,
    linkColor: black,
    linkHoverColor: textGray,
    lineColor: textOffWhite,
};
const errorTheme = {
    type: Theme.Error,
    mainBackgroundColor: brownRed,
    secondaryBackgroundColor: offWhite,
    mainTextColor: white,
    linkColor: white,
    linkHoverColor: textGray,
    lineColor: textOffWhite,
};
const flowBoxTheme = {
    type: Theme.FlowBox,
    mainBackgroundColor: white,
    secondaryBackgroundColor: offWhite,
    mainTextColor: black,
    linkColor: textDarkGray,
    linkHoverColor: textGray,
    lineColor: textOffWhite,
};
export const WhiteTheme = themeFactory(whiteTheme);
export const GrayTheme = themeFactory(grayTheme);
export const BeigeTheme = themeFactory(beigeTheme);
export const DarkTheme = themeFactory(darkTheme);
export const CmsTheme = themeFactory(cmsTheme);
export const ErrorTheme = themeFactory(errorTheme);
export const FlowBoxTheme = themeFactory(flowBoxTheme);
const blockDecorator = styled({
    color: theme => theme.mainTextColor,
    backgroundColor: theme => theme.mainBackgroundColor,
});
function blockFactory(Provider) {
    return styled(forwardStyle((_a) => {
        var { compose, elementRef } = _a, restProps = __rest(_a, ["compose", "elementRef"]);
        return (React.createElement(Provider, null,
            React.createElement(styled.Div, Object.assign({}, restProps, { css: compose(), ref: elementRef }))));
    }), blockDecorator);
}
export const WhiteThemeBlock = blockFactory(WhiteTheme);
export const GrayThemeBlock = blockFactory(GrayTheme);
export const BeigeThemeBlock = blockFactory(BeigeTheme);
export const DarkThemeBlock = blockFactory(DarkTheme);
export const ErrorThemeBlock = blockFactory(ErrorTheme);
