import { __rest } from "tslib";
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { styled } from '@glitz/react';
import { selector } from '@glitz/core';
import { isIOS } from '@avensia/nitro5-scope';
import { Error, Check } from 'Shared/Icon';
import { pixelsToUnit, negative, positive, createGeneralDecorator, transition, outlineGrey, large, medium, greyNeutral, black, batonTurboCaps, textGray, fontFamilies, animation, } from '../Style';
import { useLayoutEffect } from 'Shared/useLayoutEffect';
const Text = styled((_a) => {
    var { onFocus, onBlur, onChange, placeholder, children, uncontrolled, shouldValidate = true, inputStyles, placeholderStyles, label, endElement } = _a, restProps = __rest(_a, ["onFocus", "onBlur", "onChange", "placeholder", "children", "uncontrolled", "shouldValidate", "inputStyles", "placeholderStyles", "label", "endElement"]);
    const elementRef = useRef();
    const value = (restProps.value = restProps.value || '');
    const [focused, setFocused] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [filled, setFilled] = useState(isFilled(value));
    const internalOnFocus = useCallback((e) => {
        setFocused(true);
        if (onFocus) {
            onFocus(e);
        }
    }, [setFocused, onFocus]);
    const internalOnBlur = useCallback((e) => {
        setFocused(false);
        setInvalid(isInvalid(e.currentTarget));
        if (onBlur) {
            onBlur(e);
        }
    }, [setFocused, onBlur]);
    const internalOnChange = useCallback((e) => {
        const el = e.currentTarget;
        setInvalid(isInvalid(el));
        setFilled(isFilled(el.value));
        if (onChange) {
            onChange(e);
        }
    }, [setInvalid, setFilled, onChange]);
    useEffect(() => {
        !invalid && setFilled(isFilled(restProps.value));
    }, [invalid, restProps.value]);
    const isAutoFilled = useRef(false);
    const onAutoFill = useCallback(() => {
        if (isAutoFilled.current) {
            return;
        }
        isAutoFilled.current = true;
        setFilled(true);
    }, []);
    if (uncontrolled) {
        restProps.defaultValue = value;
        delete restProps.value;
    }
    useLayoutEffect(() => {
        if (uncontrolled) {
            const currentPropIsInSync = value === elementRef.current.value;
            const nextValue = value || '';
            // We don't want to allow clearing if the current value is not in sync with the current
            // prop because we might end up clearing during the time the user is typing.
            const shouldClear = nextValue === '' && currentPropIsInSync;
            if (elementRef.current && (!focused || shouldClear)) {
                elementRef.current.value = nextValue;
            }
        }
    }, [elementRef, focused, uncontrolled, value]);
    const realPlaceholder = placeholder || children;
    const { disabled, readOnly } = restProps;
    const editable = !(disabled || readOnly);
    const formattedLabel = restProps.required ? `${label}*` : label;
    const validationIcon = shouldValidate ? (invalid && editable ? (React.createElement(Invalid, null)) : filled && editable ? (React.createElement(Valid, null)) : null) : null;
    const focusedOrFilled = focused || filled || value !== '';
    return (React.createElement(Label, { css: { backgroundColor: disabled ? greyNeutral : undefined } },
        React.createElement(Field, null,
            React.createElement(LabelContainer, null,
                label && React.createElement(LiftingLabel, { css: focusedOrFilled && liftedLabel }, formattedLabel),
                React.createElement(Placeholder, { css: Object.assign(Object.assign(Object.assign(Object.assign({}, placeholderStyles), { display: 'inline-block' }), transition({ property: ['opacity', 'transform'] })), { opacity: focusedOrFilled ? 0 : 1, pointerEvents: focusedOrFilled ? 'none' : 'auto' }) }, realPlaceholder)),
            React.createElement(BareInput, Object.assign({}, restProps, { onFocus: internalOnFocus, onBlur: internalOnBlur, onChange: internalOnChange, ref: elementRef, css: Object.assign(Object.assign(Object.assign(Object.assign({}, inputStyles), autoFillAnimation), { cursor: 'text' }), (!editable && { color: textGray })), onAnimationStart: onAutoFill, onAnimationIteration: onAutoFill }))),
        !isInvalid && validationIcon,
        endElement));
}, Object.assign({ display: 'inline-flex', marginBottom: 0, color: theme => theme.mainTextColor, border: {
        xy: {
            color: outlineGrey,
            style: 'solid',
            width: 'thin',
        },
    } }, transition({ property: 'background' })));
const DefaultText = createGeneralDecorator()(Text);
export default DefaultText;
function isInvalid(el) {
    return !el.validity.valid;
}
function isFilled(value) {
    return !!value && /[\S]/.test(value);
}
export const Label = styled.label({
    position: 'relative',
    overflowX: 'hidden',
    textAlign: 'start',
});
const LabelContainer = styled.div(Object.assign({ position: 'absolute', bottom: 0, width: '100%', pointerEvents: 'none', color: theme => theme.linkColor, whiteSpace: 'nowrap' }, batonTurboCaps(({ isCompact }) => (isCompact ? 11 : 13))));
export const liftedLabel = {
    transform: ({ isCompact }) => `translateY(${isIOS() ? '-120%' : '-100%'}) scale(${isCompact ? 9 / 11 : 10 / 13})`,
    color: theme => theme.linkColor,
};
export const LiftingLabel = styled.span(Object.assign(Object.assign({ display: 'inline-block', transformOrigin: 'top left' }, transition({ property: ['transform', 'color'], duration: 200 })), { marginInlineEnd: 5, margin: {
        bottom: 3,
    }, color: black }));
export const Field = styled.div({
    position: 'relative',
    flex: {
        grow: 1,
        shrink: 0,
    },
    maxWidth: '100%',
    // We set font size to 16px because if it's less, iOS will zoom the field
    fontSize: () => (isIOS() ? '16px' : undefined),
});
export const Placeholder = styled.span({});
export const resetInputStyled = styled({
    width: '100%',
    padding: { xy: 0 },
    border: { xy: { width: 0 } },
    color: 'inherit',
    backgroundColor: 'transparent',
    ':focus': {
        outlineWidth: 0,
    },
    outlineStyle: 'none',
    outlineWidth: 0,
    cursor: 'inherit',
});
export const BareInput = resetInputStyled(styled.Input, Object.assign(Object.assign({ 
    // We set font size to 16px because if it's less, iOS will zoom the field
    fontFamily: fontFamilies.batonTurbo, fontSize: ({ isCompact }) => (isCompact ? (isIOS() ? 16 : 14) : 16) }, transition({ property: 'color' })), selector(':-webkit-autofill', {
    transition: {
        property: 'background-color',
        duration: '86400s',
        timingFunction: 'ease-in-out',
        delay: '86400s',
    },
})));
const autoFillAnimation = Object.assign({}, selector(':-webkit-autofill', Object.assign({}, animation({
    name: {
        from: {},
        to: {},
    },
    duration: 50,
    iterationCount: 20,
}))));
const statusStyled = styled({ flexShrink: 0 });
const Invalid = statusStyled(Error, {
    color: negative,
});
const Valid = statusStyled(Check, {
    color: positive,
});
const FIELDS_GUTTER = medium;
const FIELDS_GUTTER_ROW = large;
export const Fieldset = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    width: '100%',
    margin: { top: -FIELDS_GUTTER_ROW, left: -FIELDS_GUTTER, bottom: FIELDS_GUTTER_ROW },
});
export const fieldStyled = styled({
    flexGrow: 1,
    margin: { top: FIELDS_GUTTER_ROW, left: FIELDS_GUTTER },
});
export const FullField = fieldStyled(DefaultText, {
    width: '100%',
});
export const MinHalfField = fieldStyled(DefaultText, {
    width: `calc(50% - ${pixelsToUnit(FIELDS_GUTTER)})`,
});
export const MinQuarterField = fieldStyled(DefaultText, {
    width: `calc(25% - ${pixelsToUnit(FIELDS_GUTTER)})`,
});
export const UnderlinedField = fieldStyled(DefaultText, {
    width: '100%',
    height: 'auto',
    margin: {
        xy: 0,
    },
    padding: {
        x: 0,
        top: 18,
        bottom: 7,
    },
    border: {
        x: { style: 'none' },
        top: { style: 'none' },
        bottom: {
            color: black,
            style: 'solid',
            width: 1,
        },
    },
    color: black,
});
