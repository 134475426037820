import constants from './constants';
const { BASE_FONT_SIZE } = constants;
export var Unit;
(function (Unit) {
    Unit[Unit["Px"] = 0] = "Px";
    Unit[Unit["Em"] = 1] = "Em";
    Unit[Unit["Rem"] = 2] = "Rem";
    Unit[Unit["Vw"] = 3] = "Vw";
})(Unit || (Unit = {}));
export function pixelsToUnit(value, unit = Unit.Px) {
    return unit === Unit.Px
        ? `${floatToCSSNumber(value)}px`
        : unit === Unit.Vw
            ? `${floatToCSSNumber((value / document.documentElement.clientWidth) * 100)}vw` //outerwidth / document.documentElement.clientWidth *100, not 100% working for tablet
            : `${floatToCSSNumber(value / BASE_FONT_SIZE)}${unit === Unit.Rem ? 'rem' : 'em'}`;
}
/** Fixes calculated floats to CSS relevant number */
export function floatToCSSNumber(value) {
    return Number(value.toFixed(4));
}
