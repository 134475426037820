import React from 'react';
import { translate } from '@avensia/nitro5-scope';
import { media } from '@glitz/core';
import { styled } from '@glitz/react';
import { H1 } from 'Account/MyPages/components';
import { black, mediaMinMedium, white, ZIndex } from 'Shared/Style';
const UnsupportedBrowserNotification = () => {
    return (React.createElement(Base, null,
        React.createElement(Title, null, translate('/UnsupportedBrowserNotification/Title')),
        React.createElement(Description, null, translate('/UnsupportedBrowserNotification/Description'))));
};
const Base = styled.div({
    padding: { xy: 40 },
    backgroundColor: black,
    color: white,
    zIndex: ZIndex.Overlay,
});
const Title = styled(H1, Object.assign({}, media(mediaMinMedium, {
    fontSize: 30,
    margin: {
        top: 0,
        bottom: 10,
    },
})));
const Description = styled.div({
    maxWidth: 808,
    fontSize: 18,
    lineHeight: 1.56,
    textAlign: 'center',
    margin: {
        x: 'auto',
    },
});
export default UnsupportedBrowserNotification;
