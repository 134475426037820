import { __awaiter } from "tslib";
import { currentUrl, postJson } from '@avensia/nitro5-scope';
import { QUERY_VOYADO_ECLUB, QUERY_ABANDONED_CART_CARTCUSTOMERID } from './utils';
import { voyadoUserTracking } from '../../TrackingInformation/index';
import { handleAbandonedCartLink } from '../../AbandonedCart/action-creators';
export function handleUrlFromVoyado(store) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = currentUrl();
        const encodedContact = url.searchParams.get(QUERY_VOYADO_ECLUB);
        const cartCustomerId = url.searchParams.get(QUERY_ABANDONED_CART_CARTCUSTOMERID);
        if (encodedContact) {
            yield postJson('/voyadosoftlogin/decrypt', { encodedContact: encodedContact })
                .then((result) => {
                if (result.success) {
                    voyadoUserTracking(result.contactId);
                    return Promise.resolve();
                }
                else {
                    return Promise.reject(result.message);
                }
            })
                .then(() => __awaiter(this, void 0, void 0, function* () {
                if (cartCustomerId)
                    yield handleAbandonedCartLink(store, cartCustomerId);
            }));
        }
    });
}
