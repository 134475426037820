import { createContext, useContext } from 'react';
import { widthFromBreakpoint } from '@avensia/nitro5-scope';
import { pageNormalBreakpoint } from 'Shared/Style';
export const PageLayoutContext = createContext({
    hasColumnGap: false,
    hasRowGap: false,
    hasAnyColumnGap: false,
    width: widthFromBreakpoint(pageNormalBreakpoint),
});
export function usePageLayoutContext() {
    return useContext(PageLayoutContext);
}
const PartFractionContext = createContext(1);
export { PartFractionContext as PageLayoutPartFactionContext };
export function usePageLayoutFraction() {
    const pageLayoutPartContext = useContext(PartFractionContext);
    return pageLayoutPartContext;
}
