// Will return `false` if `CSS.supports` is unsupported
const supports = typeof CSS !== 'undefined' && typeof CSS.supports === 'function' ? CSS.supports.bind(CSS) : () => false;
export function supportsObjectFit(browser) {
    const b = browser;
    return (!!b &&
        ((supports('object-fit', 'contain') && supports('object-position', 'center')) ||
            !(b.name === 'ie' ||
                (b.name === 'edge' && parseFloat(b.version) <= 15) ||
                (b.name === 'safari' && parseFloat(b.version) <= 9.2))));
}
export function isOldSafari(browser) {
    const b = browser;
    return !!b && (b.name === 'safari' || (b.name === 'ios' && parseFloat(b.version) <= 10.3));
}
export function isOldChrome(browser) {
    const b = browser;
    return !!b && (b.name === 'crios' || (b.name === 'chrome' && parseInt(b.version) <= 64));
}
export function isUnsupportedBrowser(browser) {
    const b = browser;
    return (!!b &&
        (b.name === 'ie' ||
            (b.name === 'safari' && parseFloat(b.version) <= 9.1) ||
            (b.name === 'chrome' && parseInt(b.version) <= 56) ||
            (b.name === 'firefox' && parseInt(b.version) <= 51) ||
            (b.name === 'edge' && parseFloat(b.version) < 16)));
}
