import React, { useRef, useEffect, useCallback } from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/nitro5-scope';
import { useSelector } from 'Shared/State';
import Link from 'Shared/Link';
import { itemKey } from 'SiteLayout/MainMenu/item-helpers';
import UserButton from './UserButton';
import CartButton from './CartButton';
import * as style from 'Shared/Style';
import { errorMessageStyled } from 'Shared/FeedbackMessage';
import MainMenu from 'SiteLayout/MainMenu';
import { WhiteThemeBlock } from 'Shared/Theme';
import { HeaderItem, SelectedHeaderItemStyle } from './HeaderItem';
import { Search } from 'Shared/Icon/indiska';
import Logo from 'Shared/Logotype';
import { batonTurboCaps } from 'Shared/Style';
import { isCategoryActive } from './menu-helper';
import hoverIntent from 'hoverintent';
import { HeaderButton } from './HeaderItem';
import { trackMainMenuClick } from 'TrackingInformation';
const INTERVAL = 100;
const options = { timeout: 200, sensitivity: 12, interval: INTERVAL };
function FullHeader({ mainMenu, openMainMenu, closeMainMenu, order, hideMenuItems, toggleMiniCart, miniCartIsDisabled, toggleQuickSearch, pageWithHeaderBorder, }) {
    var _a, _b;
    const { culture, availableLanguages } = useSelector(state => state.appShellData);
    const primaryNavigationRef = useRef();
    const megaMenuRef = useRef();
    const megaMenuHover = useRef(false);
    const menubarHover = useRef(false);
    const hoverStart = useCallback((e, item) => {
        openMainMenu(e, item);
    }, [openMainMenu]);
    const hoverEnd = useCallback((e) => {
        let timeout;
        if (menubarHover.current || megaMenuHover.current) {
            timeout = setTimeout(() => hoverEnd(e), INTERVAL);
            return;
        }
        clearTimeout(timeout);
        menubarHover.current = false;
        megaMenuHover.current = false;
        closeMainMenu();
    }, [closeMainMenu]);
    useEffect(() => {
        // Do nothing if the menu items are hidden like on the checkout page
        if (!primaryNavigationRef.current) {
            return;
        }
        const unbindEvents = [];
        const menuNodes = primaryNavigationRef.current.childNodes;
        for (let i = 0; i < menuNodes.length; i++) {
            const unBindHoverEvent = hoverIntent(menuNodes[i], (e) => hoverStart(e, mainMenu.mainMenuItems[i]), hoverEnd).options(options);
            unbindEvents.push(unBindHoverEvent);
        }
        return () => {
            unbindEvents.forEach(unbindEvent => {
                unbindEvent.remove();
            });
        };
    }, [hoverEnd, hoverStart, mainMenu.mainMenuItems, hideMenuItems]);
    // Preserve last open menu items during animation
    const lastOpenMenuItemRef = useRef();
    if (mainMenu.openMenuItem) {
        lastOpenMenuItemRef.current = mainMenu.openMenuItem;
    }
    // It's known that this causes a "Warning: Prop `href` did not match" using Dev Server.
    // Is it an actual problem that I should investigate and ask questions about? No.
    // Okey, so I should just ignore and move on? Yes.
    // https://www.youtube.com/watch?v=0dTQlSdiXrE
    const homeUrl = availableLanguages.find(lang => lang.culture === culture).url;
    return (React.createElement(Container, { css: Object.assign({ order: order }, (pageWithHeaderBorder && { '::after': { borderBottomColor: style.textOffWhite } })) },
        React.createElement(NoScript, null, translate('/Errors/JavaScriptRequired')),
        React.createElement(WhiteThemeBlock, null,
            React.createElement(Base, null,
                hideMenuItems ? (React.createElement(PrimaryNavigationContainer, null,
                    React.createElement(NavigationItem, { to: homeUrl, css: { marginLeft: -15, color: style.textDarkGray, ':hover': { color: style.black } } }, translate('/Checkout/BackToShopping')))) : (React.createElement(PrimaryNavigationContainer, null,
                    React.createElement(PrimaryNavigation, { ref: primaryNavigationRef, onMouseEnter: e => (menubarHover.current = true), onMouseLeave: e => (menubarHover.current = false) }, mainMenu.mainMenuItems.map(item => (React.createElement(NavigationItem, { id: item.name, key: itemKey(item), to: item.url, onClick: () => trackMainMenuClick(item.url, `${homeUrl}${item.url}`), css: isCategoryActive(item, mainMenu) && mainMenu.mainMenuOpen && SelectedHeaderItemStyle }, item.name)))))),
                React.createElement(LogoLink, { to: homeUrl, css: hideMenuItems && { margin: { y: 0, x: 'auto' }, padding: { y: 19 } } },
                    React.createElement(StyledLogo, null)),
                React.createElement(IconGroup, null,
                    React.createElement(SecondaryNavigation, null,
                        React.createElement(UserButton, null),
                        !hideMenuItems && (React.createElement(React.Fragment, null,
                            React.createElement(HeaderButton, { onClick: toggleQuickSearch, "aria-label": translate('/MainMenu/Search'), title: translate('/MainMenu/Search') },
                                React.createElement(Search, null)),
                            React.createElement(CartButton, { toggleMiniCart: toggleMiniCart, miniCartIsDisabled: miniCartIsDisabled })))))),
            ((_b = (_a = lastOpenMenuItemRef.current) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement("div", { ref: megaMenuRef, onMouseEnter: e => (megaMenuHover.current = true), onMouseLeave: e => (megaMenuHover.current = false) },
                React.createElement(MegaMenu, { mainMenuOpen: mainMenu.mainMenuOpen, openMenuItem: lastOpenMenuItemRef.current, hoverEnd: hoverEnd }))))));
}
export default React.memo(FullHeader);
const Container = styled.header({
    position: 'relative',
    '::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 0,
        border: {
            bottom: {
                style: 'solid',
                color: 'transparent',
                width: 1,
            },
        },
    },
});
const NoScript = errorMessageStyled(styled.Noscript, { textAlign: 'center' });
const Base = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: {
        x: 30,
    },
});
const Group = styled.div({
    flexBasis: '40%',
});
const LogoLink = styled(Link, {
    flexBasis: 'auto',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    padding: {
        x: 10,
    },
});
const IconGroup = styled(Group, {
    display: 'flex',
    justifyContent: 'flex-end',
});
const PrimaryNavigationContainer = styled.div({
    flexBasis: '40%',
    display: 'flex',
    justifyContent: 'flex-start',
});
const PrimaryNavigation = styled.nav({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    // Position at the left edge
    marginLeft: -15,
});
const NavigationItem = styled(HeaderItem, Object.assign(Object.assign(Object.assign({ padding: { x: 15, y: style.large } }, batonTurboCaps(12)), { whiteSpace: 'nowrap' }), style.transition({ property: 'color' })));
const StyledLogo = styled(Logo, {
    height: 22,
    position: 'relative',
    // vertically offset the logo a bit because the logo pushes the "indiska" text down a bit
    top: -1,
});
const SecondaryNavigation = styled.nav({
    display: 'flex',
    alignItems: 'center',
    // Position at the right edge
    marginRight: -15,
});
const MegaMenu = styled(MainMenu, {});
