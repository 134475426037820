import { __awaiter } from "tslib";
export function loadIntersectionObserverPolyfillIfNeeded() {
    return __awaiter(this, void 0, void 0, function* () {
        if (__BROWSER__) {
            if (!('IntersectionObserver' in window) &&
                !('IntersectionObserverEntry' in window) &&
                !(window.IntersectionObserverEntry &&
                    'intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
                return import(
                /* webpackChunkName: "intersection-observer-polyfill" */ 'intersection-observer');
            }
        }
        return Promise.resolve();
    });
}
