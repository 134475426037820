import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { translate, DynamicData } from '@avensia/nitro5-scope';
import { getCart } from 'Shared/dynamic-data';
import { Cart } from 'Shared/Icon/indiska';
import { transition } from 'Shared/Style';
import { DarkThemeBlock } from 'Shared/Theme';
import { HeaderButton } from './HeaderItem';
import { testIdProps } from '../../test-automation';
export default function CartButton(_a) {
    var { toggleMiniCart, miniCartIsDisabled } = _a, restProps = __rest(_a, ["toggleMiniCart", "miniCartIsDisabled"]);
    return (React.createElement(DynamicData, { data: getCart(), fallbackData: { items: [], additional: undefined, viewData: undefined }, shouldResolve: !miniCartIsDisabled }, cart => {
        const cartCount = cart.items.reduce((a, i) => a + i.quantity, 0);
        // These has to be 3 separate calls to translate as the
        // translation-loader won't pick them up if you use a dynamic string.
        const title = cartCount === 0
            ? translate('/MainMenu/Cart/Empty')
            : cartCount === 1
                ? translate('/MainMenu/Cart/Single')
                : translate('/MainMenu/Cart/Multiple', cartCount);
        const showCartCount = cartCount > 0 && !miniCartIsDisabled;
        return (React.createElement(Button, Object.assign({ title: title, onClick: toggleMiniCart }, restProps, testIdProps.MiniCartButton),
            React.createElement(Cart, null),
            React.createElement(Indicator, { css: showCartCount && { transform: 'scale(1)' } }, cartCount)));
    }));
}
const Button = styled(HeaderButton, {
    position: 'relative',
});
const INDICATOR_SIZE = 20;
const Indicator = styled(DarkThemeBlock, Object.assign({ position: 'absolute', top: 'calc(50% - 17px)', right: 'calc(50% - 19px)', left: 'auto', height: INDICATOR_SIZE, minWidth: INDICATOR_SIZE, padding: { x: 6 }, fontSize: 10, lineHeight: '20px', textAlign: 'center', borderRadius: '100vw', transform: 'scale(0)' }, transition({ property: 'transform' })));
