let appShellData = null;
export function setAppShellData(data) {
    appShellData = data;
}
export function getCart() {
    return appShellData.cart;
}
export function getCurrentUser() {
    return appShellData.currentUser;
}
