import { __awaiter } from "tslib";
import { clearContentCaches, postJson, replaceState } from '@avensia/nitro5-scope';
import { toggleMiniCart } from '../SiteLayout/MiniCart/action-creators';
import { logout as gtmLogout } from '../TrackingInformation';
import { abandonedCartPageUrl } from 'Shared/known-urls';
export function handleAbandonedCartLink(store, cartCustomerId) {
    return __awaiter(this, void 0, void 0, function* () {
        postJson(abandonedCartPageUrl().url, { cartCustomerId: cartCustomerId }).then((result) => __awaiter(this, void 0, void 0, function* () {
            if (result.success && result.cartCustomerId === null) {
                yield replaceState('/', { includeAppShellData: true });
                store.dispatch(toggleMiniCart());
                return Promise.resolve();
            }
            else if (result.success && result.cartCustomerId !== null) {
                yield postJson('/logoutpage/logoutuser');
                yield clearContentCaches();
                gtmLogout();
                //wait for new customer id to be set in httpRequest first
                setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                    yield postJson('/AbandonedCart/MergeCart', { cartCustomerId: cartCustomerId }).then((res) => __awaiter(this, void 0, void 0, function* () {
                        if (res.success) {
                            yield replaceState('/', { includeAppShellData: true });
                            store.dispatch(toggleMiniCart());
                            Promise.resolve();
                        }
                        else {
                            yield replaceState('/', { includeAppShellData: true });
                            Promise.reject(res.message);
                        }
                    }));
                }), 2000);
            }
            else {
                yield replaceState('/', { includeAppShellData: true });
                Promise.reject(result.message);
            }
        }));
    });
}
