import { styled } from '@glitz/react';
import { Plus as FeatherPlus, ChevronDown as FeatherChevronDown, ShoppingBag as FeatherShoppingBag, Check as FeatherCheck, Square as FeatherSquare, CheckSquare as FeatherCheckSquare, X as FeatherX, AlertCircle as FeatherAlertCircle, Facebook as FeatherFacebook, Home as FeatherHome, Instagram as FeatherInstagram, Linkedin as FeatherLinkedin, User as FeatherUser, Menu as FeatherMenu, MoreHorizontal as FeatherMoreHorizontal, Edit2 as FeatherEdit2, Minus as FeatherMinus, Circle as FeatherCircle, CheckCircle as FeatherCheckCircle, Search as FeatherSearch, Twitter as FeatherTwitter, Youtube as FeatherYoutube, Flag as FeatherFlag, Filter as FeatherFilter, Heart as FeatherHeart, Trash2 as FeatherTrash, } from 'react-feather';
import factory from './factory';
export { default as Spinner } from './Spinner';
export { default as Sort } from './Sort';
export const Plus = factory(FeatherPlus);
export const ArrowDown = factory(FeatherChevronDown);
export const ArrowLeft = styled(ArrowDown, { transform: 'rotate(90deg)' });
export const ArrowUp = styled(ArrowDown, { transform: 'rotate(180deg)' });
export const ArrowRight = styled(ArrowDown, { transform: 'rotate(270deg)' });
export const Cart = factory(FeatherShoppingBag);
export const Check = factory(FeatherCheck);
export const UncheckedCheckbox = factory(FeatherSquare);
export const CheckedCheckbox = factory(FeatherCheckSquare);
export const Close = factory(FeatherX);
export const Error = factory(FeatherAlertCircle);
export const Facebook = factory(FeatherFacebook);
export const Home = factory(FeatherHome);
export const Instagram = factory(FeatherInstagram);
export const Linkedin = factory(FeatherLinkedin);
export const User = factory(FeatherUser);
export const Menu = factory(FeatherMenu);
export const More = factory(FeatherMoreHorizontal);
export const Edit = factory(FeatherEdit2);
export const Minus = factory(FeatherMinus);
export const UncheckedRadio = factory(FeatherCircle);
export const CheckedRadio = factory(FeatherCheckCircle);
export const Search = factory(FeatherSearch);
export const Twitter = factory(FeatherTwitter);
export const Youtube = factory(FeatherYoutube);
export const Flag = factory(FeatherFlag);
export const Filter = factory(FeatherFilter);
export const Heart = factory(FeatherHeart);
export const Trash = factory(FeatherTrash);
