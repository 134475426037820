import { Breakpoint, isBrowser } from '@avensia/nitro5-scope';
import { useCurrentBreakpoint } from 'Shared/use-viewport';
import { useSelector } from './State';
export function isMobile(currentBreakpoint) {
    return currentBreakpoint < Breakpoint.Small;
}
function isInit(currentBreakpoint) {
    return currentBreakpoint <= Breakpoint.Init;
}
function isMicro(currentBreakpoint) {
    return currentBreakpoint <= Breakpoint.Micro;
}
function isTiny(currentBreakpoint) {
    return currentBreakpoint <= Breakpoint.Tiny;
}
function isLargerMobile(currentBreakpoint) {
    return currentBreakpoint === Breakpoint.Tiny;
}
// Compact includes both mobile and standing tablet
export function isCompact(currentBreakpoint) {
    return currentBreakpoint <= Breakpoint.Small;
}
export function isTablet(currentBreakpoint) {
    return currentBreakpoint === Breakpoint.Small;
}
function isDesktop(currentBreakpoint) {
    return currentBreakpoint >= Breakpoint.Medium;
}
function isLarge(currentBreakpoint) {
    return currentBreakpoint >= Breakpoint.Large;
}
function isHuge(currentBreakpoint) {
    return currentBreakpoint >= Breakpoint.Huge;
}
export function useInit() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isInit(currentBreakpoint);
}
export function useMicro() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isMicro(currentBreakpoint);
}
export function useTiny() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isTiny(currentBreakpoint);
}
export function useLargerMobile() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isLargerMobile(currentBreakpoint);
}
export function useMobile() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isMobile(currentBreakpoint);
}
export function useCompact() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isCompact(currentBreakpoint);
}
export function useTablet() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isTablet(currentBreakpoint);
}
export function useDesktop() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isDesktop(currentBreakpoint);
}
export function useLarge() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isLarge(currentBreakpoint);
}
export function useHuge() {
    const currentBreakpoint = useCurrentBreakpoint();
    return isHuge(currentBreakpoint);
}
export function useDirectionRtl() {
    const culture = useSelector(state => state.appShellData.culture);
    return culture === 'ar-AE';
}
let scrollbarWidth;
export function getScrollbarWidth() {
    if (isBrowser()) {
        scrollbarWidth = scrollbarWidth !== undefined ? scrollbarWidth : window.innerWidth - document.body.offsetWidth;
        return scrollbarWidth;
    }
    return 0;
}
