import { __awaiter } from "tslib";
import { useCallback, useEffect } from 'react';
import { URLX, currentUrl, loadScript, postJson, pushState, resolveData } from '@avensia/nitro5-scope';
import { checkoutPageUrl } from './known-urls';
import { useCheckoutActions } from 'Checkout/Context';
import { getCart } from './dynamic-data';
import { useSelector } from 'Shared/State';
let _player;
const BAMBUSER_LISTNAME = 'Bambuser';
function getProductData(sku, locale) {
    return __awaiter(this, void 0, void 0, function* () {
        const query = {
            sku: sku,
            locale: locale,
        };
        const productData = yield postJson('/bambuser/product', query);
        return productData;
    });
}
export default function useBambuser(eventId, scriptUrl) {
    const { culture, currency } = useSelector(state => state.appShellData);
    const actions = useCheckoutActions();
    const loadBambuser = useCallback(() => {
        if (!!eventId) {
            const bamWindow = window;
            bamWindow.initBambuserLiveShopping && bamWindow.initBambuserLiveShopping({ showId: eventId, type: 'overlay' });
        }
    }, [eventId]);
    useEffect(() => {
        if (!!scriptUrl) {
            (() => __awaiter(this, void 0, void 0, function* () {
                const bamWindow = window;
                if (!bamWindow.initBambuserLiveShopping) {
                    bamWindow.initBambuserLiveShopping = Object.assign((show) => {
                        const localWindow = window;
                        localWindow.initBambuserLiveShopping.queue.push(show);
                    }, { queue: [] });
                    bamWindow.onBambuserLiveShoppingReady = (player) => {
                        if (_player) {
                            _player.removeAllListeners && _player.removeAllListeners();
                            _player.close && _player.close();
                        }
                        _player = player;
                        const config = { currency, locale: culture };
                        configure(config);
                        bindEvents(actions, config);
                    };
                    loadScript(scriptUrl, el => {
                        el.setAttribute('async', 'async');
                        return el;
                    });
                }
            }))();
        }
    }, [actions, culture, currency, eventId, scriptUrl]);
    return {
        loadBambuser,
    };
}
function configure(conf) {
    (_player === null || _player === void 0 ? void 0 : _player.configure) &&
        _player.configure({
            currency: conf.currency,
            locale: conf.locale,
            buttons: {
                product: _player.BUTTON.AUTO,
                checkout: _player.BUTTON.MINIMIZE,
                dismiss: _player.BUTTON.MINIMIZE,
            },
            floatingPlayer: {
                navigationMode: _player.FLOATING_PLAYER_NAVIGATION_MODE.MANUAL,
            },
        });
}
function bindEvents(actions, conf) {
    if (!(_player === null || _player === void 0 ? void 0 : _player.EVENT) || !_player.on) {
        return;
    }
    _player.EVENT.READY && _player.on(_player.EVENT.READY, () => { });
    _player.EVENT.CLOSE && _player.on(_player.EVENT.CLOSE, () => { });
    _player.EVENT.PROVIDE_PRODUCT_DATA &&
        _player.on(_player.EVENT.PROVIDE_PRODUCT_DATA, (event) => {
            if (!(_player === null || _player === void 0 ? void 0 : _player.updateProduct)) {
                return;
            }
            else if (!(event === null || event === void 0 ? void 0 : event.products)) {
                return;
            }
            const { currency, locale } = conf;
            event.products.forEach((product) => __awaiter(this, void 0, void 0, function* () {
                const indiskaProduct = yield getProductData(product.ref, locale);
                if (indiskaProduct) {
                    _player.updateProduct(product.id, factory => {
                        return factory
                            .currency(currency)
                            .locale(locale)
                            .product((p) => p
                            .brandName(indiskaProduct.brandName)
                            .defaultVariationIndex(indiskaProduct.defaultVariationIndex)
                            .description(indiskaProduct.description)
                            .name(indiskaProduct.productName)
                            .sku(indiskaProduct.sku)
                            .variations((v) => {
                            var _a;
                            return (_a = indiskaProduct.variants) === null || _a === void 0 ? void 0 : _a.map(variant => v()
                                .attributes((a) => a.colorName(variant.name))
                                .imageUrls(variant.imageUrls)
                                .name(variant.name)
                                .sku(variant.sku)
                                .sizes((s) => {
                                var _a;
                                return (_a = variant.sizes) === null || _a === void 0 ? void 0 : _a.map(size => {
                                    var _a;
                                    return s()
                                        .name((_a = size.name) !== null && _a !== void 0 ? _a : '')
                                        .inStock(size.inStock)
                                        .price((price) => price.original(variant.price.original).current(variant.price.current))
                                        .sku(size.sku);
                                });
                            }));
                        }));
                    });
                }
                else {
                    _player.updateProduct(product.id, factory => {
                        return factory.inheritFromPlaceholder().hidden(true);
                    });
                }
            }));
        });
    _player.EVENT.ADD_TO_CART &&
        _player.on(_player.EVENT.ADD_TO_CART, (addedItem, callback) => {
            actions
                .addToCart(addedItem.sku, 1, null, BAMBUSER_LISTNAME)
                .then(() => callback(true))
                .catch(() => callback(false));
        });
    _player.EVENT.UPDATE_ITEM_IN_CART &&
        _player.on(_player.EVENT.UPDATE_ITEM_IN_CART, (updatedItem, callback) => {
            actions
                .updateCartItemQuantity(updatedItem.sku, updatedItem.quantity, updatedItem.quantity, BAMBUSER_LISTNAME)
                .then(() => callback(true))
                .catch(() => callback(false));
        });
    _player.EVENT.SYNC_CART_STATE &&
        _player.on(_player.EVENT.SYNC_CART_STATE, () => __awaiter(this, void 0, void 0, function* () {
            const cart = yield resolveData(getCart());
            const { items } = cart;
            if (!items.length) {
                _player.updateCart({ items: [] });
            }
        }));
    _player.EVENT.CHECKOUT &&
        _player.on(_player.EVENT.CHECKOUT, () => {
            if (!!_player.showCheckout) {
                const checkoutUrl = new URLX(checkoutPageUrl());
                const url = checkoutUrl.host ? checkoutUrl.href : window.location.href;
                _player.showCheckout(url);
            }
        });
    _player.EVENT.NAVIGATE_BEHIND_TO &&
        _player.on(_player.EVENT.NAVIGATE_BEHIND_TO, (event) => {
            const url = !!(event === null || event === void 0 ? void 0 : event.url) ? new URLX(event === null || event === void 0 ? void 0 : event.url) : currentUrl();
            if (!url.host || url.host === window.location.host) {
                pushState(event.url);
                return;
            }
        });
}
