import transformers from '@glitz/transformers';
import { mediaMinMicro, mediaMinTiny, mediaMinSmall, mediaMinMedium, mediaMinLarge, mediaMinHuge, mediaMinGigantic, } from 'Shared/Style';
const mediaQueries = [
    mediaMinMicro,
    mediaMinTiny,
    mediaMinSmall,
    mediaMinMedium,
    mediaMinLarge,
    mediaMinHuge,
    mediaMinGigantic,
];
function mediaOrder(a, b) {
    const indexA = mediaQueries.indexOf(a);
    const indexB = mediaQueries.indexOf(b);
    return indexA - indexB;
}
export const glitzCoreOptions = {
    mediaOrder,
    transformer: transformers({
        numberToLengthOptions: {
            animationDelay: 'ms',
            animationDuration: 'ms',
            transitionDelay: 'ms',
            transitionDuration: 'ms',
        },
    }),
};
