import React, { useState, useRef } from 'react';
import { Motion, spring } from 'react-motion';
import SiteBanner from 'Shared/SiteBanner';
import { useLayoutEffect } from 'Shared/useLayoutEffect';
export default function SystemText(props) {
    const [height, setHeight] = useState(0);
    const childrenRef = useRef(props.children);
    if (props.children) {
        // Keep children in state so that it doesn't disappear when animating out
        childrenRef.current = props.children;
    }
    const elementRef = useRef();
    // Needs to check height on each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        const height = elementRef.current.offsetHeight;
        if (height !== height) {
            setHeight(height);
        }
    });
    const enabled = !!props.children;
    const motionStyle = {
        height: enabled ? spring(height) : spring(0),
    };
    return (React.createElement(Motion, { style: motionStyle }, ({ height: interpolatedHeight }) => (React.createElement(SiteBanner, { style: { height: interpolatedHeight }, elementRef: elementRef }, childrenRef.current))));
}
