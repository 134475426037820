import React from 'react';
import { Provider } from 'react-redux';
import SiteLayout from './';
import { CheckoutProvider } from 'Checkout/Context';
import { WhiteTheme } from 'Shared/Theme';
import ErrorBoundary from 'Shared/ErrorBoundary';
import { ActionbarProvider } from './Tray/Actionbar';
import { SnackbarProvider } from './Tray/Snackbar';
import { isBrowser } from '@avensia/nitro5-scope';
import { GlitzProvider } from '@glitz/react';
let store;
let glitz;
export default function Container(props) {
    store = props.store;
    glitz = props.glitz;
    return (React.createElement(ChildlessContainer, Object.assign({}, props),
        React.createElement(SiteLayout, null)));
}
export function ChildlessContainer(props) {
    return (React.createElement(Provider, { store: store },
        React.createElement(GlitzWrapper, null,
            React.createElement(CheckoutProvider, { store: store },
                React.createElement(WhiteTheme, null,
                    React.createElement(ErrorBoundary, null,
                        React.createElement(ActionbarProvider, null,
                            React.createElement(SnackbarProvider, null, props.children))))))));
}
function GlitzWrapper(props) {
    return isBrowser() ? React.createElement(GlitzProvider, { glitz: glitz }, props.children) : props.children;
}
