import React, { useState, createContext, useMemo, useContext, useCallback } from 'react';
import { styled } from '@glitz/react';
import { createGeneralDecorator, General, margin } from 'Shared/Style';
import { DarkTheme } from 'Shared/Theme';
import SlideIn from 'Shared/SlideIn';
const Context = createContext({ snackbars: [], setSnackbars: () => { } });
export function SnackbarProvider(props) {
    const [snackbars, setSnackbars] = useState([]);
    const value = useMemo(() => ({ snackbars, setSnackbars }), [snackbars]);
    return React.createElement(Context.Provider, { value: value }, React.Children.toArray(props.children));
}
export const SnackbarContainer = styled(() => {
    const { snackbars } = useContext(Context);
    return (React.createElement(DarkTheme, null, snackbars.length > 0 ? React.createElement(styled.Div, null, React.Children.toArray(snackbars)) : null));
}, {
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
});
export function useSnackbar() {
    const { setSnackbars } = useContext(Context);
    return useCallback(function push(node) {
        let realNode;
        function remove() {
            setSnackbars(snackbars => snackbars.filter(node => node !== realNode));
        }
        if (typeof node === 'function') {
            realNode = node(remove);
        }
        else {
            realNode = node;
        }
        setSnackbars(snackbars => [...snackbars, realNode]);
        return remove;
    }, [setSnackbars]);
}
export default createGeneralDecorator(General.Small, false)(function Snackbar({ children }) {
    return React.createElement(SlideIn, { open: true }, style => React.createElement(styled.Div, { style: style }, React.Children.toArray(children)));
}, {
    backgroundColor: theme => theme.mainBackgroundColor,
    color: theme => theme.mainTextColor,
    display: 'flex',
    alignItems: 'center',
    alignSelf: ({ isCompact }) => (isCompact ? 'strech' : 'flex-start'),
    margin: { x: margin.small, bottom: margin.small },
    padding: {
        x: 20,
        y: 10,
    },
    fontSize: ({ isMobile }) => (isMobile ? 12 : 14),
    borderRadius: 8,
    pointerEvents: 'auto',
});
