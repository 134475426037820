export function visuallyHidden() {
    return {
        position: 'absolute',
        width: '1px',
        height: '1px',
        margin: { x: '-1px', y: '-1px' },
        padding: { x: 0, y: 0 },
        border: { xy: { width: 0 } },
        clipPath: 'inset(100%)',
        clip: 'rect(0 0 0 0)',
        overflow: 'hidden',
    };
}
