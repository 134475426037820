import { __rest } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import { transition } from 'Shared/Style';
import { useContext } from 'react';
import { RenderContext, Rendering } from 'Shared/RenderContext';
import { SiteBannerLink } from 'Shared/SiteBanner/SiteBannerBlock';
export const createLinkDecorator = (defaultStyle, hoverStyle = {}) => styled(Object.assign(Object.assign({}, defaultStyle), { 
    // ':link': {},
    // ':visited': {},
    ':hover': Object.assign(Object.assign({}, defaultStyle), hoverStyle), ':active': Object.assign(Object.assign({}, defaultStyle), hoverStyle) }));
export const linkDecorator = createLinkDecorator(Object.assign({ color: theme => theme.linkColor, textDecoration: 'none' }, transition({ property: 'color' })), { color: theme => theme.linkHoverColor });
const DefaultLink = styled(Link, linkDecorator);
const ContextualLink = (_a) => {
    var props = __rest(_a, []);
    const renderContext = useContext(RenderContext);
    if (renderContext === Rendering.Sitebanner) {
        return React.createElement(SiteBannerLink, Object.assign({}, props));
    }
    return React.createElement(DefaultLink, Object.assign({}, props));
};
export const resetLinkDecorator = createLinkDecorator({
    color: 'inherit',
    textDecoration: 'inherit',
});
export default styled(ContextualLink);
