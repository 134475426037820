import React from 'react';
import { styled } from '@glitz/react';
import { useCompact } from 'Shared/Viewport';
import CompactHeader from './Compact';
import FullHeader from './Full';
import * as style from 'Shared/Style';
import { useSelector } from 'Shared/State';
import TopHeader, { HEIGHT as TOPHEADER_HEIGHT } from './TopHeader';
import QuickSearch from 'Search/QuickSearch';
import { ZIndex, textGray } from 'Shared/Style';
import { Cross } from 'Shared/Icon/indiska';
import currentPageHasHeaderBorder from './current-page-has-header-border';
export function useHeaderHeight(excludeTopHeader) {
    const fullHeaderHeight = useCompact() ? 90 : 100;
    return excludeTopHeader ? fullHeaderHeight - TOPHEADER_HEIGHT : fullHeaderHeight;
}
export default React.memo(function MainHeader(props) {
    const isCompact = useCompact();
    const currentPage = useSelector(state => state.currentPage);
    const pageHasHeaderBorder = currentPageHasHeaderBorder(currentPage);
    return (React.createElement(React.Fragment, null,
        React.createElement(QuickSearch, null),
        React.createElement(TopHeader, null),
        React.createElement(Base, { css: { order: props.order } }, isCompact ? (React.createElement(CompactHeader, { mainMenu: props.mainMenu, order: props.order, openMainMenu: props.openMainMenu, toggleMiniCart: props.toggleMiniCart, miniCartIsDisabled: props.miniCartIsDisabled, hideMenuItems: props.hideMenuItems, toggleQuickSearch: props.toggleQuickSearch, pageWithHeaderBorder: pageHasHeaderBorder })) : (React.createElement(FullHeader, { order: props.order, mainMenu: props.mainMenu, openMainMenu: props.openMainMenu, closeMainMenu: props.closeMainMenu, toggleMiniCart: props.toggleMiniCart, miniCartIsDisabled: props.miniCartIsDisabled, toggleCountrySelector: props.toggleCountrySelector, hideMenuItems: props.hideMenuItems, toggleQuickSearch: props.toggleQuickSearch, pageWithHeaderBorder: pageHasHeaderBorder })))));
});
const Base = styled.div({
    position: 'sticky',
    top: 0,
    backgroundColor: style.white,
    fontFamily: style.fontFamilies.batonTurbo,
    zIndex: ZIndex.Header,
});
export const CloseButton = styled(Cross, {
    position: theme => (theme.isCompact ? 'absolute' : 'fixed'),
    left: 'auto',
    right: 30,
    color: textGray,
    cursor: 'pointer',
    zIndex: ZIndex.Header,
    ':hover': {
        color: style.black,
    },
});
