import React from 'react';
import { styled } from '@glitz/react';
import { useCompact } from 'Shared/Viewport';
import * as style from 'Shared/Style';
import { batonTurboCaps } from 'Shared/Style';
import { default as CountrySelectorButton, Type as CountrySelectorType } from './CountrySelectorButton';
import { ContentArea } from '@avensia/nitro5-scope-episerver';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
import { useSelector } from 'Shared/State';
export const HEIGHT = 40;
function TopHeader() {
    var _a;
    const uspBlock = useSelector(state => state.appShellData.uspBlock);
    const isCompact = useCompact();
    const hasUsps = ((_a = uspBlock === null || uspBlock === void 0 ? void 0 : uspBlock.items) === null || _a === void 0 ? void 0 : _a.length) > 0;
    const currentPage = useSelector(state => state.currentPage);
    if (isCompact && (!hasUsps || currentPageIsCheckout(currentPage))) {
        return null;
    }
    return (React.createElement(Base, null, isCompact ? (React.createElement(ContentArea, Object.assign({}, uspBlock, { component: Compact }))) : (React.createElement(Full, null,
        React.createElement(LeftGroup, null,
            React.createElement(CountrySelectorButton, { mode: CountrySelectorType.Language })),
        React.createElement(ContentArea, Object.assign({}, uspBlock, { component: MainGroup })),
        React.createElement(RightGroup, null,
            React.createElement(CountrySelectorButton, { mode: CountrySelectorType.Country }))))));
}
export default React.memo(TopHeader);
const Base = styled.div({
    minHeight: HEIGHT,
    padding: {
        y: 12,
        x: ({ isCompact }) => (isCompact ? 20 : 30),
    },
    backgroundColor: style.offWhite,
    color: style.textDarkGray,
});
const Compact = styled.div(Object.assign({ textAlign: 'center' }, batonTurboCaps(10)));
const Full = styled.div(Object.assign({ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }, batonTurboCaps(11)));
const MainGroup = styled.div({
    flex: {
        basis: 'auto',
        grow: 0,
        shrink: 0,
    },
});
const LeftGroup = styled.div({
    flex: {
        basis: 0,
        grow: 1,
        shrink: 0,
    },
    display: 'flex',
    justifyContent: 'flex-start',
});
const RightGroup = styled.div({
    flex: {
        basis: 0,
        grow: 1,
        shrink: 0,
    },
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
});
