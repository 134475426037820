import React, { useState, useEffect } from 'react';
import FlyoutNotification from './FlyoutNotification';
import { useCompact } from 'Shared/Viewport';
export default React.memo(function CartNotification(props) {
    const isCompact = useCompact();
    const [visible, setVisible] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const hasItem = !!props.displayName && !!props.imageUrl && !!props.price;
    useEffect(() => {
        setIsFirstRender(false);
    }, []);
    useEffect(() => {
        if (isFirstRender) {
            return;
        }
        else {
            setVisible(true);
        }
    }, [props.displayName, props.imageUrl, props.price, props.size, props.color, props.scent, props.flavor, props.hasRedPrice, isFirstRender, isCompact]);
    const onClose = () => {
        setVisible(false);
    };
    return (React.createElement(FlyoutNotification, { displayName: props.displayName, size: props.size, color: props.color, scent: props.scent, flavor: props.flavor, isOpen: visible && hasItem, recommendations: props.recommendations, onClose: onClose }));
});
