import { addUserLog } from '@avensia/nitro5-scope';
export const OPEN_MAIN_MENU = 'TOGGLE_MAIN_MENU';
export const CLOSE_MAIN_MENU = 'CLOSE_MAIN_MENU';
export function openMainMenu(menuItem) {
    addUserLog('Opening main menu');
    return {
        type: OPEN_MAIN_MENU,
        menuItem,
    };
}
export function closeMainMenu() {
    addUserLog('Closing main menu');
    return {
        type: CLOSE_MAIN_MENU,
    };
}
