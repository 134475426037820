import React from 'react';
import { styled } from '@glitz/react';
import * as style from 'Shared/Style';
import { Info, Warning, Check } from './Icon/indiska';
const iconStyled = styled({
    marginInlineEnd: 10,
    verticalAlign: -3,
});
const InfoStyled = iconStyled(Info, {
    color: style.textGray,
});
const ErrorStyled = iconStyled(Warning, {
    color: style.brownRed,
});
const AlertStyled = iconStyled(Warning, {
    color: style.textDarkGray,
});
const SuccessStyled = iconStyled(Check);
const WarningStyled = iconStyled(Warning);
export const neutralMessageStyled = styled({
    textAlign: 'start',
    margin: {
        y: 20,
    },
    padding: {
        xy: 20,
    },
    fontSize: ({ isCompact }) => (isCompact ? 12 : 13),
    color: style.black,
    border: {
        xy: {
            color: style.textGray,
            width: 1,
            style: 'solid',
        },
    },
    borderRadius: 8,
});
export const NeutralMessage = neutralMessageStyled(styled.Div);
const InfoDiv = (props) => (React.createElement(styled.Div, null,
    React.createElement(InfoStyled, null),
    props.children));
export const infoMessageStyled = neutralMessageStyled({
    color: style.textGray,
});
export const InfoMessage = infoMessageStyled(InfoDiv);
const ErrorDiv = (props) => (React.createElement(styled.Div, null,
    React.createElement(ErrorStyled, null),
    props.children));
export const errorMessageStyled = neutralMessageStyled({
    backgroundColor: theme => theme.secondaryBackgroundColor,
    border: {
        xy: {
            color: style.brownRed,
        },
    },
});
export const ErrorMessage = errorMessageStyled(ErrorDiv);
const SuccessDiv = (props) => (React.createElement(styled.Div, null,
    React.createElement(SuccessStyled, null),
    props.children));
export const successMessageStyled = neutralMessageStyled({
    color: style.green,
    border: {
        xy: {
            color: style.green,
        },
    },
});
export const SuccessMessage = successMessageStyled(SuccessDiv);
const InfoAlertDiv = (props) => (React.createElement(styled.Div, null,
    React.createElement(AlertStyled, null),
    props.children));
export const infoAlertMessageStyled = neutralMessageStyled({
    color: style.black,
    backgroundColor: style.textOffWhite,
});
export const InfoAlertMessage = infoAlertMessageStyled(InfoAlertDiv);
const CartMessageDiv = (props) => (React.createElement(styled.Div, null,
    props.success ? React.createElement(SuccessStyled, { css: { color: style.green } }) : React.createElement(WarningStyled, null),
    props.children));
export const cartMessageStyled = neutralMessageStyled({
    color: style.black,
    backgroundColor: style.selectShade,
    border: {
        xy: { color: style.selectShade },
    },
});
export const CartMessage = cartMessageStyled(CartMessageDiv);
