import { __rest } from "tslib";
import React from 'react';
import { forwardStyle, styled } from '@glitz/react';
import { usePageLayoutContext, usePageLayoutFraction } from 'Shared/PageLayout/PageLayoutContext';
import { useBoxLayout, useBoxLayoutFraction } from 'Shared/BoxLayout/BoxLayoutContext';
import { ImageComponent, ImageProvider } from '@avensia/nitro5-ui-image';
import { useSelector } from 'Shared/State';
import { isCompact } from 'Shared/Viewport';
import { transition } from 'Shared/Style';
import { getColumnGap } from 'Shared/PageLayout';
export { Ratio } from 'Shared/AspectRatio';
/**
 * isGif was used to opt-out of image resizing before.
 * Now the Cloudflare resizer handle resize to webp by us passing format=auto which we do by default.
 * So for now we're not doing any different for gifs. If everything works out we should be able to remove the isGif prop
 */
const Image = (_a) => {
    var _b;
    var { compose, ratio, isGif } = _a, restProps = __rest(_a, ["compose", "ratio", "isGif"]);
    const pageLayoutContext = usePageLayoutContext();
    const pageLayoutFraction = usePageLayoutFraction();
    const boxLayoutFraction = useBoxLayoutFraction();
    const boxLayout = useBoxLayout();
    const imageDomain = useSelector(state => state.appShellData.imageDomain);
    const breakpoint = useSelector(state => state.currentBreakpoint);
    const aspectRatioDecorator = ratio ? styled({ aspectRatio: (1 / ratio).toString() }) : styled({});
    const css = styled(aspectRatioDecorator, { objectFit: 'cover', objectPosition: 'center center' });
    return (React.createElement(ImageProvider, { breakpoint: breakpoint, isCompact: isCompact(breakpoint), imageDomain: imageDomain, pageLayoutWidth: pageLayoutContext.width, pageLayoutGap: 0, viewportMargin: getColumnGap(breakpoint), pageLayoutFraction: pageLayoutFraction, boxLayoutFraction: boxLayoutFraction, boxLayoutFractionGap: (_b = boxLayout === null || boxLayout === void 0 ? void 0 : boxLayout.spacing) !== null && _b !== void 0 ? _b : 0, hasAnyPageLayoutGap: pageLayoutContext.hasAnyColumnGap, imageSizesDesktop: IMAGE_SIZES_DESKTOP, quality1x: 80, quality2x: 70 },
        React.createElement(ImageComponent, Object.assign({ css: compose(css) }, restProps))));
};
export default styled(forwardStyle(Image));
/** include opacity since we will otherwise override */
export const imageHoverTransition = styled(transition({
    property: ['transform', 'opacity'],
    duration: [200, 300],
    willChange: false,
}));
export const imageHoverScale = { transform: 'scale(1.05)' };
// prettier-ignore
export const IMAGE_SIZES_DESKTOP = [
    100 /** Thumbnails etc */,
    260 /** Product card */,
    314 /** Product card */,
    360 /** Simple topic block 1/4 */,
    450,
    586 /** 33% of container with out space */,
    632 /** 50% of container with space subracted */,
    768 /** PDP full container width - with thumbs */,
    848 /** 66% of container with space subracted */,
    928 /** PDP full container width - no thumbs */,
    1013 /** Inspiration block full width */,
    1067 /** Rich topic block full width */,
    1600 /** Full container with images */,
    2560 /** Breakout hero images etc */,
];
