import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { selector } from '@glitz/core';
import { forwardStyle, styled } from '@glitz/react';
import Link from 'Shared/Link';
import { linkDecorator, resetLinkDecorator } from 'Shared/Generic/A';
import { createGeneralDecorator, General, general, positive, white, greyNeutral, black, animation, batonTurboCaps, textDarkGray, offWhite, brownRed, textOffWhite, textGray, transition, } from 'Shared/Style';
export var Theme;
(function (Theme) {
    Theme[Theme["None"] = 0] = "None";
    Theme[Theme["Link"] = 1] = "Link";
    Theme[Theme["Primary"] = 2] = "Primary";
    Theme[Theme["TransparentWhite"] = 3] = "TransparentWhite";
    Theme[Theme["TransparentBlack"] = 4] = "TransparentBlack";
    Theme[Theme["LightGray"] = 5] = "LightGray";
    Theme[Theme["LightGrayBorder"] = 6] = "LightGrayBorder";
    Theme[Theme["Negative"] = 7] = "Negative";
    Theme[Theme["Positive"] = 8] = "Positive";
    Theme[Theme["Gradient"] = 9] = "Gradient";
})(Theme || (Theme = {}));
export var Casing;
(function (Casing) {
    Casing[Casing["None"] = 0] = "None";
    Casing[Casing["Lowercase"] = 1] = "Lowercase";
    Casing[Casing["Uppercase"] = 2] = "Uppercase";
})(Casing || (Casing = {}));
const ButtonLink = styled(forwardStyle((_a) => {
    var { compose, disabled, children } = _a, restProps = __rest(_a, ["compose", "disabled", "children"]);
    return (React.createElement(Link, Object.assign({}, restProps, { css: compose(), onClick: e => {
            if (disabled) {
                e.preventDefault();
            }
            else if (restProps.onClick) {
                restProps.onClick(e);
            }
        } }), children));
}), resetLinkDecorator, {
    ':hover': {
        textDecoration: 'none',
    },
});
export const resetButtonStyled = styled(Object.assign(Object.assign({ font: {
        family: 'inherit',
        size: 'inherit',
        style: 'inherit',
        variant: 'inherit',
        weight: 'normal',
    }, border: { xy: { width: 0 } }, padding: { xy: 0 }, userSelect: 'none', color: 'inherit', textDecoration: 'none', textAlign: 'inherit', backgroundColor: ['transparent', 'initial'] }, selector(':not([disabled])', {
    cursor: 'pointer',
})), { ':focus': {
        outlineWidth: 0,
    }, ':disabled': {
        opacity: 0.4,
        cursor: 'default',
    } }));
const ButtonNotLink = styled(forwardRef((_a, ref) => {
    var { type = 'button', children } = _a, restProps = __rest(_a, ["type", "children"]);
    return (React.createElement(styled.Button, Object.assign({}, restProps, { type: type, ref: ref, onClick: e => {
            if (restProps.onClick) {
                restProps.onClick(e);
            }
        } }), children));
}), resetButtonStyled);
const themeDecorators = {
    [Theme.None]: styled(),
    [Theme.Gradient]: styled({
        backgroundColor: offWhite,
        ':first-child': {
            backgroundImage: 'linear-gradient(90deg, #FBFBFB, #F1F1F1)',
        },
        ':last-child': {
            backgroundImage: 'linear-gradient(-90deg, #FBFBFB, #F1F1F1)',
        },
    }),
    [Theme.Link]: styled(Object.assign({ color: theme => theme.linkColor }, selector(':not([disabled]):hover', {
        color: theme => theme.linkHoverColor,
    }))),
    [Theme.Primary]: styled(Object.assign({ color: white, backgroundColor: black }, selector(':not([disabled]):hover', {
        color: white,
        backgroundColor: '#252525',
    }))),
    [Theme.TransparentWhite]: styled(Object.assign({ color: white, backgroundColor: 'transparent', border: {
            xy: {
                color: white,
                style: 'solid',
                width: 1,
            },
        } }, selector(':not([disabled]):hover', {
        color: black,
        backgroundColor: white,
    }))),
    [Theme.TransparentBlack]: styled(Object.assign({ color: black, backgroundColor: 'transparent', border: {
            xy: {
                color: black,
                style: 'solid',
                width: 1,
            },
        } }, selector(':not([disabled]):hover', {
        color: white,
        backgroundColor: black,
    }))),
    [Theme.LightGray]: styled(Object.assign({ color: textDarkGray, backgroundColor: offWhite }, selector(':not([disabled]):hover', {
        color: black,
    }))),
    [Theme.LightGrayBorder]: styled(Object.assign({ color: textGray, backgroundColor: 'transparent', border: {
            xy: {
                color: textOffWhite,
                style: 'solid',
                width: 1,
            },
        } }, selector(':not([disabled]):hover', {
        color: black,
        border: {
            xy: {
                color: black,
                style: 'solid',
                width: 1,
            },
        },
    }))),
    [Theme.Positive]: styled(Object.assign({ color: white, backgroundColor: positive }, selector(':not([disabled]):hover', {
        color: white,
        backgroundColor: positive,
    }))),
    [Theme.Negative]: styled(Object.assign({ color: white, backgroundColor: brownRed }, selector(':not([disabled]):hover', {
        color: white,
        backgroundColor: brownRed,
    }))),
};
const caseDecorators = {
    [Casing.None]: styled({
        textTransform: 'inherit',
    }),
    [Casing.Lowercase]: styled({
        textTransform: 'initial',
    }),
    [Casing.Uppercase]: styled({
        textTransform: 'uppercase',
    }),
};
export const PlainButton = styled(forwardStyle((_a) => {
    var { compose, casing } = _a, restProps = __rest(_a, ["compose", "casing"]);
    return isLink(restProps) ? (React.createElement(ButtonLink, Object.assign({}, restProps, { css: compose(styled(caseDecorators[casing])) }))) : (React.createElement(ButtonNotLink, Object.assign({}, restProps, { css: compose(styled(caseDecorators[casing])) })));
}));
export const ThemeButton = styled(forwardRef((_a) => {
    var { as = Theme.Primary, casing = Casing.None } = _a, restProps = __rest(_a, ["as", "casing"]);
    const theme = as;
    return React.createElement(PlainButton, Object.assign({}, restProps, { css: styled(themeDecorators[theme], caseDecorators[casing]) }));
}), transition({
    property: ['color', 'background-color', 'border-color'],
}), {
    display: 'inline-flex',
    alignItems: 'baseline',
    justifyContent: 'center',
});
function isLink(props) {
    return 'to' in props;
}
const createButtonDecorator = (type = General.Default) => styled(createGeneralDecorator(type), Object.assign({ borderRadius: general(type).height, padding: { x: general(type).padding.x, y: 0 }, lineHeight: general(type).height + 'px' }, batonTurboCaps(general(type).fontSize)));
export const createSquareButtonDecorator = (type = General.Default) => styled(createGeneralDecorator(type), {
    minWidth: general(type).height,
    padding: { x: 0 },
});
export const createRoundButtonDecorator = (type = General.Default) => styled(createSquareButtonDecorator(type), { borderRadius: '100%' });
export const LinkButton = styled(PlainButton, linkDecorator, {
    display: 'inline',
});
export const SmallButton = styled(ThemeButton, createButtonDecorator(General.Small));
export const MediumButton = styled(ThemeButton, createButtonDecorator(General.Medium));
export const LargeButton = styled(ThemeButton, createButtonDecorator(General.Large));
const Button = styled(ThemeButton, createButtonDecorator());
export default Button;
export const SquareButton = styled(ThemeButton, createSquareButtonDecorator());
export const SmallSquareButton = styled(ThemeButton, createSquareButtonDecorator(General.Small));
export const RoundButton = styled(ThemeButton, createRoundButtonDecorator());
export const SmallRoundButton = styled(ThemeButton, createRoundButtonDecorator(General.Small));
export const fullButtonStyled = styled({ width: '100%', padding: { x: 0 } });
export const UnderLineButtonWhite = styled(PlainButton, Object.assign(Object.assign({}, batonTurboCaps(general().fontSize)), { color: white, position: 'relative', padding: {
        y: 4,
    }, ':after': {
        content: '""',
        height: 1,
        backgroundColor: white,
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        transformOrigin: 'bottom left',
    } }));
export const UnderLineButtonBlack = styled(UnderLineButtonWhite, {
    color: black,
    ':after': {
        backgroundColor: black,
    },
});
export const UnderLineButtonGray = styled(UnderLineButtonWhite, {
    color: greyNeutral,
    ':after': {
        backgroundColor: greyNeutral,
    },
});
const underLineButtonHoverStyles = {
    ':after': Object.assign({}, animation({
        name: {
            '0%': {
                transform: 'scaleX(0)',
            },
            '100%': {
                transform: 'scaleX(1)',
            },
        },
        timingFunction: 'ease-in-out',
        duration: 400,
        fillMode: 'forwards',
    })),
};
const addUnderLineButtonHoverStyles = styled(underLineButtonHoverStyles);
export const UnderLineButtonBlackWithHover = styled(UnderLineButtonBlack, {
    ':hover': underLineButtonHoverStyles,
});
export const UnderLineButtonWhiteWithHover = styled(UnderLineButtonWhite, {
    ':hover': underLineButtonHoverStyles,
});
export const UnderLineButtonGrayWithHover = styled(UnderLineButtonWhite, {
    ':hover': underLineButtonHoverStyles,
});
export const UnderLineButtonWhiteHover = addUnderLineButtonHoverStyles(UnderLineButtonWhite);
export const UnderLineButtonBlackHover = addUnderLineButtonHoverStyles(UnderLineButtonBlack);
export const UnderLineButtonGrayHover = addUnderLineButtonHoverStyles(UnderLineButtonGray);
