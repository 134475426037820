// These ids are meant to be used for test automation tools such as end to end testing (Selenium, Cypress, etc) but
// also for load testing tools that can record and replay a user session.
// It's important not to change or remove these unless you're absolutely sure that no such tool uses the id.
// It's highly encouraged to add new ids and then add the prop to an element (see usages of the TestIdProps object).
export const TestIds = {
    CheckoutPage: 'checkout-page',
    MyPagesLink: 'mypages-link',
    ProductLink: 'product-link',
    OpenLoginButton: 'open-login-button',
    ForgotPasswordButton: 'forgot-password-button',
    LoginButton: 'login-button',
    LoginFailedElement: 'login-failed-element',
    LoginEmailInput: 'login-email-input',
    LoginPasswordInput: 'login-password-input',
    RegisterButton: 'register-button',
    RegisterFailedElement: 'register-failed-element',
    RegisterEmailInput: 'register-email-input',
    RegisterPasswordInput: 'register-password-input',
    RegisterAcceptTermsInput: 'register-accept-terms-input',
    MiniCartButton: 'minicart-button',
    IncreaseQuantityButton: 'increase-quantity-button',
    DecreaseQuantityButton: 'decrease-quantity-button',
    QuantityInput: 'quantity-input',
    MiniCartElement: 'minicart-element',
    MiniCartItemElement: 'minicart-item-element',
    GoToCheckoutButton: 'go-to-checkout-button',
    SelectShippingMethodInput: 'select-shipping-method-input',
    SelectPaymentMethodInput: 'select-payment-method-input',
    UspTextElement: 'usp-text-element',
    VariantSelectorElement: 'variant-selector-element',
    AddToCartButton: 'add-to-cart-button',
    OpenMonitorBackInStockButton: 'open-monitor-back-in-stock-button',
    MonitorBackInStockEmailInput: 'monitor-back-in-email-input',
    MonitorBackInStockButton: 'monitor-back-in-stock-button',
    ToggleWishlistItemButton: 'toggle-wishlist-item-button',
    DiscountButton: 'discount-button',
    RemoveFreeItem: 'remove-item-button',
};
export const testIdAttribute = 'data-test-id';
export const testIdProps = {};
let key;
for (key in TestIds) {
    testIdProps[key] = testId(key);
}
export function testId(id) {
    return { [testIdAttribute]: TestIds[id] };
}
