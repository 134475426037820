import React, { useState, useCallback, useEffect } from 'react';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import Overlay from 'Shared/Overlay';
import * as style from 'Shared/Style';
import ErrorBoundary from 'Shared/ErrorBoundary';
import { animated, useSpring } from 'react-spring';
import { ScrollWithStickyHeader } from './ScrollWithStickyHeader';
export var Position;
(function (Position) {
    Position[Position["Left"] = 0] = "Left";
    Position[Position["Right"] = 1] = "Right";
})(Position || (Position = {}));
const TRANSITION_DURATION = 300;
export const FLYOUT_TRANSITION_DONE_DURATION = TRANSITION_DURATION + 200;
function Flyout(props) {
    const position = props.position;
    const getClosedTransform = useCallback(() => {
        return position === Position.Left ? -100 : 100;
    }, [position]);
    const [translateXState, setTranslateX] = useState(getClosedTransform());
    function onClose() {
        setTranslateX(getClosedTransform());
        props.onClose();
    }
    useEffect(() => {
        const translateX = props.isOpen ? 0 : getClosedTransform();
        setTranslateX(translateX);
    }, [getClosedTransform, props.isOpen, position]);
    const translateX = useSpring({
        to: { translateX: translateXState + '%' },
        config: { duration: TRANSITION_DURATION },
    });
    const Base = position === Position.Left
        ? props.isFullscreen
            ? FullscreenLeft
            : Left
        : props.isFullscreen
            ? FullscreenRight
            : Right;
    return (React.createElement(Overlay, { onClose: onClose, enabled: props.isOpen },
        React.createElement(Base, { onClick: e => e.stopPropagation(), style: translateX },
            React.createElement(ErrorBoundary, { reloadCurrentPage: true }, props.headerText || props.bottomButtonContent ? (React.createElement(ScrollWithStickyHeader, { onClose: onClose, headerText: props.headerText, bottomButtonContent: props.bottomButtonContent, hideBackButton: props.hideBackButton },
                React.createElement(React.Fragment, null, props.children))) : (React.createElement(React.Fragment, null, props.children))))));
}
export default Flyout;
const flyoutStyled = styled(Object.assign(Object.assign(Object.assign(Object.assign({ position: 'fixed', top: 0, width: 570, maxWidth: `calc(100% - ${style.tera}px)` }, media(style.mediaMinMedium, {
    maxWidth: `calc(100% - ${style.gigantic}px)`,
})), { height: '100%', backgroundColor: theme => theme.mainBackgroundColor, willChange: 'transform' }), style.depth()), { overflow: 'hidden' }));
const Left = animated(flyoutStyled(styled.Div, { left: 0 }));
const Right = animated(flyoutStyled(styled.Div, { right: 0 }));
const FullscreenLeft = animated(flyoutStyled(styled.Div, { left: 0, width: '100%', maxWidth: 'initial' }));
const FullscreenRight = animated(flyoutStyled(styled.Div, { right: 0, width: '100%', maxWidth: 'initial' }));
export const Body = styled.div(Object.assign({ width: '100%', padding: { xy: style.padding.mobile }, backgroundColor: theme => theme.mainBackgroundColor }, media(style.mediaMinMedium, {
    padding: { xy: style.padding.desktop },
})));
