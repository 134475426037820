import { styled } from '@glitz/react';
import constants from './constants';
import { floatToCSSNumber } from './unit';
import { textGray } from './colors';
import { media } from '@glitz/core';
import { mediaMinMedium } from './media';
const { BASE_FONT_SIZE, BASE_TEXT_COLOR, BASE_LINE_HEIGHT } = constants;
export const defaultFontSize = BASE_FONT_SIZE;
export const defaultTextColor = BASE_TEXT_COLOR;
export const defaultLineHeight = BASE_LINE_HEIGHT * BASE_FONT_SIZE;
export const linkColor = textGray;
export const alternativeTextColor = '#b5b5b5';
// Large font sizes
export const giga = 72;
export const mega = 54;
export const kilo = 36;
// Heading font sizes
export const alpha = 32;
export const beta = 24;
export const gamma = 20;
export const delta = 16;
export const epsilon = 14;
export const zeta = 12;
export const lambda = 11;
export const lineHeight = floatToCSSNumber(BASE_LINE_HEIGHT);
export const fontFamilies = {
    ptSerif: ['PT Serif', 'serif', 'sans-serif'].join(','),
    batonTurbo: ['Baton Turbo', 'serif', 'sans-serif'].join(','),
};
export var FontWeight;
(function (FontWeight) {
    FontWeight[FontWeight["Normal"] = 400] = "Normal";
    FontWeight[FontWeight["Book"] = 500] = "Book";
    FontWeight[FontWeight["Medium"] = 600] = "Medium";
    FontWeight[FontWeight["Bold"] = 700] = "Bold";
    FontWeight[FontWeight["Heavy"] = 800] = "Heavy";
})(FontWeight || (FontWeight = {}));
export function calculateLineHeightInPixels(fontSize) {
    return floatToCSSNumber(fontSize * lineHeight);
}
export function truncate(overflow = 'ellipsis') {
    return {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: overflow,
    };
}
export const preserveEmptyStyled = styled({
    // If breadcrumbs is an empty list we want to preserve the height because an
    // empty list means that breadcrumbs will be loaded and displayed eventually
    ':empty': {
        ':before': {
            content: '" "',
            whiteSpace: 'pre',
        },
    },
});
export function batonTurboCaps(fontSize = 12) {
    return {
        fontSize,
        fontFamily: fontFamilies.batonTurbo,
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
    };
}
export const gammaHeading = styled(Object.assign({ margin: {
        xy: 0,
    }, font: {
        size: 26,
        family: fontFamilies.ptSerif,
        weight: FontWeight.Normal,
    }, lineHeight: 1.38 }, media(mediaMinMedium, {
    font: {
        size: 40,
    },
    lineHeight: 1.33,
})));
