import { TOGGLE_NEWSLETTER_POPUP } from './action-creators';
export default function (state = { isOpen: false }, action) {
    switch (action.type) {
        case TOGGLE_NEWSLETTER_POPUP:
            return Object.assign({}, state, {
                isOpen: !state.isOpen,
            });
        default:
            return state;
    }
}
