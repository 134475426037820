import React from 'react';
import { forwardStyle, styled } from '@glitz/react';
import { itemKey } from './item-helpers';
import Level from './Level';
import * as style from 'Shared/Style';
import { WhiteTheme } from 'Shared/Theme';
import HeightTransition from 'Shared/HeightTransition';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
const springConfig = { tension: 300, friction: 33, clamp: true };
const maxNumberOfItemsInMenuItem = 11;
function ensureMenuItemNotTooLong(items) {
    const itemsToReturn = [];
    if (!items.length) {
        return itemsToReturn;
    }
    for (let i = 0; i < items.length; i++) {
        if (items[i].items.length > maxNumberOfItemsInMenuItem) {
            let startIndex = maxNumberOfItemsInMenuItem;
            itemsToReturn.push({
                name: items[i].name,
                url: items[i].url,
                showAllLink: items[i].showAllLink,
                items: items[i].items.slice(0, maxNumberOfItemsInMenuItem),
                imageUrl: items[i].imageUrl,
                isMainMenuLinkItemBlock: items[i].isMainMenuLinkItemBlock,
            });
            while (items[i].items.length > startIndex) {
                const itemsForNextColumn = items[i].items.slice(startIndex, startIndex + maxNumberOfItemsInMenuItem);
                itemsToReturn.push({
                    name: '',
                    url: items[i].url + startIndex,
                    showAllLink: items[i].showAllLink,
                    items: itemsForNextColumn,
                    imageUrl: items[i].imageUrl,
                    isMainMenuLinkItemBlock: items[i].isMainMenuLinkItemBlock,
                });
                startIndex += maxNumberOfItemsInMenuItem;
            }
        }
        else {
            itemsToReturn.push(items[i]);
        }
    }
    return itemsToReturn;
}
export default styled(forwardStyle(function MainMenu(props) {
    const { mainMenuOpen } = props;
    return (React.createElement(WhiteTheme, null,
        React.createElement(Base, { css: props.compose(mainMenuOpen && baseOpenStyles), onMouseLeave: props.hoverEnd },
            React.createElement(HeightTransition, { springConfig: springConfig, isOpen: mainMenuOpen, updateOnChildMutation: true },
                React.createElement(VisibleContainer, { appearance: [PageAppearance.Normal, PageAppearance.Gap] },
                    React.createElement(Categories, null, props.openMenuItem &&
                        ensureMenuItemNotTooLong(props.openMenuItem.items || [])
                            .slice(0, 6)
                            .map((menuItem, index) => React.createElement(Level, { key: itemKey(menuItem) + index, data: menuItem }))))))));
}));
const Base = styled.nav(Object.assign({ position: 'absolute', top: 'calc(100% - 1px)', left: 0, right: 0, backgroundColor: theme => theme.mainBackgroundColor, border: {
        top: {
            color: 'transparent',
            width: 1,
            style: 'solid',
        },
    } }, style.transition({ property: 'border-color', duration: 500 })));
const baseOpenStyles = {
    border: {
        top: {
            color: style.textOffWhite,
        },
    },
    ':after': {
        content: '""',
        bottom: 0,
        left: 0,
        right: 0,
        height: 50,
        position: 'absolute',
        zIndex: -1,
        boxShadow: '0 20px 50px rgba(0, 0, 0, 0.05)',
    },
};
const VisibleContainer = styled(Basic, {
    padding: { y: 40 },
});
const Categories = styled.ul({
    display: 'grid',
    gridTemplateColumns: `repeat(12, 1fr)`,
    gridGap: 30,
    padding: { xy: 0 },
    listStyleType: 'none',
});
