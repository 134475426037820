import React, { useRef, useEffect } from 'react';
import { applyClassName, styled } from '@glitz/react';
import { useTransition, animated, config } from 'react-spring';
import useMeasure from 'Shared/use-measure';
const HeightTransition = ({ isOpen, children, updateOnChildMutation, onDestroyed, springConfig, isDescription, className, }) => {
    const { bounds, measureElementRef, hasMeasured } = useMeasure(updateOnChildMutation);
    const isMountOnOpen = useRef(isOpen);
    useEffect(() => {
        isMountOnOpen.current = false;
    }, [isOpen]);
    const openStyles = {
        opacity: 1,
        height: (isMountOnOpen.current && !hasMeasured) || isDescription ? 'auto' : bounds.height,
        transform: 'translateY(0px)',
    };
    const closedStyles = {
        opacity: 0,
        height: 0,
        transform: 'translateY(-10px)',
    };
    const transition = useTransition(isOpen, {
        from: isMountOnOpen.current ? openStyles : closedStyles,
        enter: openStyles,
        update: openStyles,
        leave: closedStyles,
        config: springConfig || config.default,
        onDestroyed: (Item) => onDestroyed && !isOpen && onDestroyed(),
    });
    return (React.createElement(React.Fragment, null, transition((style, item) => item && (React.createElement(animated.div, { style: style, className: className },
        React.createElement("div", { ref: measureElementRef }, children))))));
};
export default styled(applyClassName(HeightTransition), { overflow: 'hidden' });
