import { __awaiter } from "tslib";
import React, { useRef } from 'react';
import { resolveDataSync, invalidateData, updateData, post, postJson, getJson, updateCurrentPage, invalidateAndResolveData, resolveComponentAndChildComponents, } from '@avensia/nitro5-scope';
import { CheckoutProvider as StandardCheckoutProvider, CheckoutConsumer, CheckoutState, Context as BaseContext, useCheckoutActions as useCheckoutActionsBase, } from '@avensia/nitro5-checkout';
import { getCart } from 'Shared/dynamic-data';
import { addToCart as gtmAddToCart, removeFromCart as gtmRemoveFromCart } from '../TrackingInformation';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
import { checkoutPageUrl } from 'Shared/known-urls';
import { pushState } from '@avensia/nitro5-scope';
export { useCheckoutState } from '@avensia/nitro5-checkout';
export function useCheckoutActions() {
    return useCheckoutActionsBase();
}
export const Context = BaseContext;
function augument(defaultActions) {
    const augmentedActions = defaultActions;
    const originalAddToCart = defaultActions.addToCart;
    augmentedActions.addToCart = (code, quantity, linkIdentifier, listName, additional) => {
        if ((linkIdentifier === null || linkIdentifier === void 0 ? void 0 : linkIdentifier.length) > 0) {
            post('/contentindexnotification/addtocart?linkIdentifier=' + linkIdentifier);
        }
        gtmAddToCart([{ code, quantity }], listName);
        return originalAddToCart(code, { quantity, additional });
    };
    const originalUpdateCartItemQuantity = defaultActions.updateCartItemQuantity;
    augmentedActions.updateCartItemQuantity = (code, previousQuantity, newQuantity, listName) => {
        if (newQuantity > previousQuantity) {
            gtmAddToCart([{ code, quantity: newQuantity - previousQuantity }], listName);
        }
        else if (newQuantity < previousQuantity) {
            gtmRemoveFromCart([{ code, quantity: previousQuantity - newQuantity }], listName);
        }
        return originalUpdateCartItemQuantity(code, { quantity: newQuantity, additional: null });
    };
    const originalRemoveFromCart = defaultActions.removeFromCart;
    augmentedActions.removeFromCart = (code, previousQuantity, listName) => {
        gtmRemoveFromCart([{ code, quantity: previousQuantity }], listName);
        return originalRemoveFromCart(code);
    };
    return defaultActions;
}
let checkoutParameters = null;
function createCheckoutParameters(store) {
    if (!checkoutParameters) {
        checkoutParameters = {
            getCart: () => {
                return resolveDataSync(getCart());
            },
            loadCart: () => __awaiter(this, void 0, void 0, function* () {
                return yield invalidateAndResolveData(getCart());
            }),
            setCart: cart => {
                if (cart) {
                    updateData(getCart(), cart);
                }
                else {
                    invalidateData(getCart());
                }
            },
            getCheckoutData: () => {
                const currentPage = store.getState().currentPage;
                if (currentPageIsCheckout(currentPage)) {
                    return currentPage.checkoutData;
                }
                return null;
            },
            loadCheckoutData: () => __awaiter(this, void 0, void 0, function* () {
                return yield getJson(checkoutPageUrl() + '/checkoutdata');
            }),
            setCheckoutData: (checkoutData) => __awaiter(this, void 0, void 0, function* () {
                const currentPage = store.getState().currentPage;
                if (currentPageIsCheckout(currentPage)) {
                    yield resolveComponentAndChildComponents(checkoutData);
                    store.dispatch(updateCurrentPage((existingCheckoutPage) => (Object.assign(Object.assign({}, existingCheckoutPage), { checkoutData }))));
                }
            }),
            postUpdate: body => postJson(checkoutPageUrl() + '/update', body),
            postCompletePurchase: body => postJson(checkoutPageUrl() + '/completepurchase', body),
            pushState,
        };
    }
    return checkoutParameters;
}
export const CheckoutProvider = (props) => {
    const standardProviderRef = useRef();
    // This is a good place if you need to perform side effects on the initial page load,
    // such as adding something to the cart
    //
    // standardProviderRef.current.actions.addToCart(...);
    return (React.createElement(StandardCheckoutProvider, { ref: standardProviderRef, parameters: createCheckoutParameters(props.store), augumentActions: augument }, props.children));
};
const augumentedConsumer = CheckoutConsumer;
export { augumentedConsumer as CheckoutConsumer };
