import { styled } from '@glitz/react';
import { moderate, fontFamilies, mediaMinMedium } from 'Shared/Style';
import { media } from '@glitz/core';
export const headingStyled = styled({
    font: {
        family: fontFamilies.ptSerif,
        weight: 'normal',
    },
    letterSpacing: 'normal',
    lineHeight: 1.08,
});
export const h1Styled = headingStyled(Object.assign({ fontSize: 30, lineHeight: 1.11 }, media(mediaMinMedium, {
    fontSize: 60,
    margin: { y: moderate },
})));
export default h1Styled(styled.H1);
export const h1Cms = headingStyled(styled.H1, Object.assign({ fontSize: 32, margin: { y: 10 }, lineHeight: 1.23, color: 'inherit' }, media(mediaMinMedium, { fontSize: 40, lineHeight: 1.25, margin: { y: 15 } })));
