import { URLX, get, pathCombine, addUserLog } from '@avensia/nitro5-scope';
import { searchPageUrl } from 'Shared/known-urls';
import * as searchHistory from './search-history';
export const TOGGLE_QUICKSEARCH = 'TOGGLE_QUICKSEARCH';
export const SHOW_QUICKSEARCH = 'SHOW_QUICKSEARCH';
export const HIDE_QUICKSEARCH = 'HIDE_QUICKSEARCH';
export const UPDATE_QUICKSEARCH_TEXT = 'UPDATE_QUICKSEARCH_TEXT';
export const UPDATE_QUICKSEARCH_SUGGESTIONS = 'UPDATE_QUICKSEARCH_SUGGESTIONS';
export const UPDATE_LAST_PAGE_VISITED = 'UPDATE_LAST_PAGE_VISITED';
export var Suggestion;
(function (Suggestion) {
    Suggestion["Related"] = "related";
    Suggestion["History"] = "history";
    Suggestion["Popular"] = "popular";
})(Suggestion || (Suggestion = {}));
export const SEARCH_PAGE_LOAD_QUERY_NAME = 'search';
export const SEARCH_QUERY_NAME = 'q';
export const SEARCH_COUNT_NAME = 'count';
export const SEARCH_SORT_NAME = 'sortBy';
export const SEARCH_TAG_NAME = 'tagBy';
// This hidden query helps us identify page loads that's not
// triggered by these requests so we're able to hide quick
// search on a regular navigation on the page
export function searchQuery(url) {
    const clone = new URLX(url);
    clone.hiddenParams.set(SEARCH_PAGE_LOAD_QUERY_NAME, String(true));
    return clone;
}
const SUGGESTION_COUNT = 5;
const SEARCH_HISTORY_COUNT = 3;
let lastRequestId = 0;
export function hideQuickSearch() {
    addUserLog('Hiding quick search');
    return { type: HIDE_QUICKSEARCH };
}
export function showQuickSearch() {
    addUserLog('Showing quick search');
    return { type: SHOW_QUICKSEARCH };
}
export function toggleQuickSearch() {
    addUserLog('Toggling quick search');
    return { type: TOGGLE_QUICKSEARCH };
}
export function loadQuickSearch(searchText) {
    addUserLog('Loading quick search for: ' + searchText);
    return (dispatch) => {
        const filteredSearchHistory = searchHistory
            .getAll(searchTextToWords(searchText))
            .filter(phrase => phrase.toLowerCase() !== searchText.toLowerCase())
            .slice(0, SEARCH_HISTORY_COUNT)
            .map(phrase => ({ text: phrase }));
        dispatch({
            type: UPDATE_QUICKSEARCH_SUGGESTIONS,
            id: Suggestion.History,
            items: filteredSearchHistory,
        });
        dispatch({
            type: UPDATE_QUICKSEARCH_TEXT,
            searchText,
        });
        const requestId = (lastRequestId = Math.random());
        if (searchText.length > 0) {
            const url = new URLX(pathCombine(searchPageUrl(), 'autocomplete'));
            url.searchParams.set(SEARCH_QUERY_NAME, searchText);
            url.searchParams.set(SEARCH_COUNT_NAME, String(SUGGESTION_COUNT));
            return get(url)
                .then(r => r.json())
                .then((json) => {
                if (requestId === lastRequestId) {
                    dispatch({
                        type: UPDATE_QUICKSEARCH_SUGGESTIONS,
                        id: Suggestion.Related,
                        items: json,
                    });
                }
            });
        }
        else {
            const url = new URLX(pathCombine(searchPageUrl(), 'searchsuggestions'));
            url.searchParams.set(SEARCH_QUERY_NAME, searchText);
            url.searchParams.set(SEARCH_COUNT_NAME, String(SUGGESTION_COUNT));
            return get(url)
                .then(r => r.json())
                .then((json) => {
                if (requestId === lastRequestId) {
                    dispatch({
                        type: UPDATE_QUICKSEARCH_SUGGESTIONS,
                        id: Suggestion.Popular,
                        items: json,
                    });
                }
            });
        }
    };
}
export function updateLastVisitedPage(lastVisitedPageIndex) {
    addUserLog('Setting last visited page index');
    return { type: UPDATE_LAST_PAGE_VISITED, lastVisitedPageIndex };
}
export function searchTextToWords(searchText) {
    return searchText.replace(/\s+/, ' ').split(' ');
}
