import React from 'react';
import { DynamicData, translate } from '@avensia/nitro5-scope';
import connect from 'Shared/connect';
import { getCurrentUser } from 'Shared/dynamic-data';
import { User } from 'Shared/Icon/indiska';
import { openAccountPanel, AccountPanelMode } from 'Account/action-creators';
import { myOverviewPageUrl } from 'Shared/known-urls';
import { styled } from '@glitz/react';
import { HeaderButton } from './HeaderItem';
import { testIdProps } from '../../test-automation';
function UserButton(props) {
    function onLoginClick() {
        props.openAccountPanel(AccountPanelMode.Login);
    }
    return (React.createElement(DynamicData, { data: getCurrentUser(), fallbackData: {
            isLoggedIn: false,
            id: undefined,
            email: undefined,
            fullName: undefined,
            firstName: undefined,
            lastName: undefined,
        } }, currentUser => (React.createElement(React.Fragment, null, currentUser.isLoggedIn ? (React.createElement(HeaderButton, Object.assign({ to: myOverviewPageUrl() }, testIdProps.MyPagesLink, { "aria-label": translate('/MainMenu/MyPages'), title: translate('/MainMenu/MyPages') }),
        React.createElement(UserLoggedIn, null))) : (React.createElement(HeaderButton, Object.assign({ onClick: onLoginClick }, testIdProps.OpenLoginButton, { "aria-label": translate('/MainMenu/LoginSignup'), title: translate('/MainMenu/LoginSignup') }),
        React.createElement(User, null)))))));
}
export default connect(null, (dispatch) => ({
    openAccountPanel(mode) {
        return dispatch(openAccountPanel(mode));
    },
}))(UserButton);
const UserLoggedIn = styled(User, {
    fill: 'currentColor',
});
