import { styled } from '@glitz/react';
import { default as factory } from './factory';
import indiskaSvg from './glyphs/indiska.svg';
import elephantSvg from './glyphs/elephant.svg';
import chevronUpSvg from './glyphs/chevron-up.svg';
import userSvg from './glyphs/user.svg';
import cartSvg from './glyphs/cart.svg';
import searchSvg from './glyphs/search.svg';
import crossSvg from './glyphs/cross.svg';
import minusSvg from './glyphs/minus.svg';
import plusSvg from './glyphs/plus.svg';
import listSvg from './glyphs/list.svg';
import mapSvg from './glyphs/map.svg';
import infoSvg from './glyphs/info.svg';
import warningSvg from './glyphs/warning.svg';
import checkSvg from './glyphs/check.svg';
import strokeCheckSvg from './glyphs/stroke-check.svg';
import eyeSvg from './glyphs/eye.svg';
import klarnaSvg from './glyphs/klarna.svg';
import airmeeSvg from './glyphs/airmee.svg';
import budbee from './glyphs/budbee.svg';
import playSvg from './glyphs/play.svg';
import speechBubbleSvg from './glyphs/speechBubble.svg';
import rulerSvg from './glyphs/ruler.svg';
import storeSvg from './glyphs/store.svg';
import amex from './glyphs/amex.svg';
import visa from './glyphs/visa.svg';
import masterCard from './glyphs/mastercard.svg';
import postNord from './glyphs/postnord.svg';
import greenPayU from './glyphs/greenpayu.svg';
import paypal from './glyphs/paypal.svg';
import paypalFooter from './glyphs/paypalFooter.svg';
import money from './glyphs/money.svg';
import gift from './glyphs/gift.svg';
import envelope from './glyphs/envelope.svg';
import dhl from './glyphs/dhl.svg';
import dhlCheckout from './glyphs/dhlCheckout.svg';
import swish from './glyphs/swish.svg';
export const Indiska = factory(indiskaSvg);
export const Elephant = styled(factory(elephantSvg), {
    width: 50,
    height: 35,
});
export const Money = styled(factory(money), {
    width: 100,
    height: 100,
});
export const Gift = styled(factory(gift), {
    width: 100,
    height: 100,
});
export const Envelope = styled(factory(envelope), {
    width: 100,
    height: 100,
});
export const PDPEnvelope = styled(factory(envelope), {
    height: 35,
    width: 35,
});
export const ChevronUp = styled(factory(chevronUpSvg), {
    width: 12,
});
export const ChevronDown = styled(ChevronUp, {
    transform: 'rotate(180deg)',
});
export const ChevronRight = styled(ChevronUp, {
    transform: 'rotate(90deg)',
});
export const ChevronLeft = styled(ChevronUp, {
    transform: 'rotate(270deg)',
});
export const User = styled(factory(userSvg), {
    width: 16,
    height: 16,
});
export const Cart = styled(factory(cartSvg), {
    width: 16,
    height: 16,
});
export const Search = styled(factory(searchSvg), {
    width: 16,
    height: 16,
});
export const Cross = styled(factory(crossSvg), {
    width: 18,
    height: 18,
});
export const Minus = styled(factory(minusSvg), {
    width: 16,
    height: 14,
});
export const Plus = styled(factory(plusSvg), {
    width: 14,
    height: 14,
});
export const List = styled(factory(listSvg), {
    width: 18,
    height: 18,
});
export const Map = styled(factory(mapSvg), {
    width: 18,
    height: 18,
});
export const Info = styled(factory(infoSvg), {
    width: 16,
    height: 16,
});
export const Warning = styled(factory(warningSvg), {
    width: 16,
    height: 16,
});
export const Check = styled(factory(checkSvg), {
    width: 16,
    height: 16,
});
export const StrokeCheck = styled(factory(strokeCheckSvg), {
    width: 24,
    height: 24,
});
export const Eye = styled(factory(eyeSvg), {
    width: 16,
    height: 16,
});
export const Play = styled(factory(playSvg), {
    width: 16,
    height: 16,
});
export const SpeechBubble = styled(factory(speechBubbleSvg), {
    width: 20,
    height: 20,
});
export const Ruler = styled(factory(rulerSvg), {
    width: 16,
    height: 16,
});
export const Store = styled(factory(storeSvg), {
    width: 16,
    height: 16,
});
export const Klarna = factory(klarnaSvg);
export const Airmee = factory(airmeeSvg);
export const DHL = factory(dhl);
export const Budbee = factory(budbee);
export const Amex = factory(amex);
export const Visa = factory(visa);
export const MasterCard = factory(masterCard);
export const PostNord = factory(postNord);
export const GreenPayU = factory(greenPayU);
export const Paypal = factory(paypal);
export const PaypalFooter = factory(paypalFooter);
export const DhlCheckout = factory(dhlCheckout);
export const Swish = factory(swish);
export const Menu = styled.div({
    position: 'relative',
    width: 20,
    height: 20,
    ':before': {
        content: '""',
        position: 'absolute',
        top: 5,
        display: 'block',
        width: 20,
        height: 1,
        backgroundColor: 'black',
    },
    ':after': {
        content: '""',
        position: 'absolute',
        bottom: 5,
        display: 'block',
        width: 20,
        height: 1,
        backgroundColor: 'black',
    },
});
