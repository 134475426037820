import React, { useEffect, useState } from 'react';
import { translate } from '@avensia/nitro5-scope';
import Snackbar, { useSnackbar } from 'SiteLayout/Tray/Snackbar';
import { SmallButton, Theme } from 'Shared/Button';
import { Close } from 'Shared/Icon';
import { styled } from '@glitz/react';
import { selector } from '@glitz/core';
import { ErrorTheme } from 'Shared/Theme';
export default function NetworkError({ loadFailure, reload }) {
    const pushSnackbar = useSnackbar();
    const [showOffline, setShowOffline] = useState(navigator.onLine === false ? true : false);
    const [dismissed, setDismissed] = useState();
    useEffect(() => {
        function isOnline() {
            setShowOffline(false);
        }
        function isOffline() {
            setShowOffline(true);
        }
        window.addEventListener('online', isOnline);
        window.addEventListener('offline', isOffline);
        return () => {
            window.removeEventListener('online', isOnline);
            window.removeEventListener('offline', isOffline);
        };
    }, []);
    useEffect(() => {
        if (showOffline) {
            return pushSnackbar(React.createElement(ErrorTheme, null,
                React.createElement(Snackbar, null,
                    translate('/Errors/Offline'),
                    React.createElement(ButtonStyled, { as: Theme.TransparentWhite, onClick: () => setShowOffline(false) },
                        React.createElement(CloseIcon, null)))));
        }
        if (loadFailure &&
            // Message has been dismissed
            dismissed !== loadFailure &&
            // AccountButton handles failed requests that requires auth
            loadFailure.status !== 401 &&
            // Don't bother the user if we're showing a full version of the page
            (!loadFailure.isShowingFullCachedPage || loadFailure.status <= 500)) {
            return pushSnackbar(React.createElement(ErrorTheme, null,
                React.createElement(Snackbar, null,
                    translate('/Errors/PageLoad'),
                    React.createElement(ButtonStyled, { as: Theme.TransparentWhite, onClick: reload }, translate('/Errors/Retry')),
                    React.createElement(ButtonStyled, { as: Theme.TransparentWhite, css: __$hoisted_o0, onClick: () => setDismissed(loadFailure) },
                        React.createElement(CloseIcon, null)))));
        }
    }, [dismissed, loadFailure, showOffline, pushSnackbar, reload]);
    return null;
}
const ButtonStyled = styled(SmallButton, Object.assign({ marginInlineStart: ({ isMobile }) => (isMobile ? 15 : 20) }, selector(':not(:last-child)', {
    marginInlineEnd: ({ isMobile }) => (isMobile ? 15 : 20),
})));
const CloseIcon = styled(Close, {
    alignSelf: 'center',
});
const __$hoisted_o0 = { marginInlineStart: 'auto' };
