import { PlainButton } from 'Shared/Button';
import { medium, small, black, transition } from 'Shared/Style';
import * as style from 'Shared/Style';
import { styled } from '@glitz/react';
import { createLinkDecorator } from 'Shared/Generic/A';
export const HeaderItem = styled(PlainButton, createLinkDecorator({ color: 'inherit', textDecoration: 'inherit' }), transition({ property: 'border-color', duration: '200ms' }), {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: { x: medium, y: small },
    position: 'relative',
    '::after': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: -1,
        width: 'calc(100% - 30px)',
        margin: { x: 15 },
        borderBottom: {
            width: 1,
            style: 'solid',
            color: 'transparent',
        },
    },
});
export const SelectedHeaderItemStyle = {
    color: black,
    '::after': {
        borderBottomColor: black,
    },
};
export const HeaderButton = styled(PlainButton, Object.assign({ padding: {
        x: ({ isCompact }) => (isCompact ? 10 : 15),
        y: ({ isCompact }) => (isCompact ? 15 : 20),
    }, ':hover': {
        color: style.textGray,
    } }, style.transition({ property: 'color' })));
